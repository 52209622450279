import React, { Component } from "react";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { authContext, checkPermission } from "@lucify/auth";
import { lucifyVorgangLink } from "@lucify/internals";
import { VermittlungStatus } from "@lucify/services/src/generated/macher-service.types";
import { ActionBox, ActionBoxGroup } from "../../../../components/ActionBox/ActionBox";
import Button from "../../../../components/Button/Button";
import { ButtonLink } from "../../../../components/Button/ButtonLink";
import Feature, { isFlagActive } from "../../../../components/Feature/Feature";
import FormMessage from "../../../../components/FormElements/FormMessage";
import Checkbox from "../../../../components/FormInput/Checkbox";
import { InlineLink, Paragraph } from "../../../../components/Typo/Typo";
import { resolveAction, resolveVorgangAction } from "../../../../mappings/ProjectActionMapping";
import { lucifyOIDCProvider } from "../../../../services/lucifyOIDCProvider";
import { getEnvironment } from "../../../../utils/helpers/dev";
import EditProjectTypeForm from "../Formulare/EditProjectTypeForm";
import { BaustellenStartTerminBestaetigung } from "./BaustellenstartTerminBestaetigung";
import { BaustellenStartTerminFestlegung } from "./BaustellenstartTerminFestlegung";

export default class ProjektStatusAktionen extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
        macherProjekt: PropTypes.object.isRequired,
        transitions: PropTypes.array,
        isDetail: PropTypes.bool,
        viewProject: PropTypes.func,
        setProjectOperator: PropTypes.func,
        setProjectCompleted: PropTypes.func,
        setProjectReopen: PropTypes.func,
        startMatching: PropTypes.func,
        startCancelation: PropTypes.func,
        startRejection: PropTypes.func
    };

    static contextType = authContext;

    state = {
        projectDataVerified: false
    };

    renderAction(actionName, render) {
        const action = resolveAction(actionName, this.props.profile.status);
        return (action && !action.needs) || (action && checkPermission(lucifyOIDCProvider, action.needs))
            ? render(action)
            : null;
    }

    renderTransitionAction(actionName, render) {
        // TODO: Sind Transitions für die keine Berechtigungen vorhanden sind in der Liste enthalten?
        return checkPermission(lucifyOIDCProvider, "projekt:bearbeiten") && this.props.hasVorgangTransition(actionName)
            ? render()
            : null;
    }

    renderSubstateAction(actionName, render) {
        const action = resolveVorgangAction(
            actionName,
            this.props.profile.substate && this.props.profile.substate.state,
            this.props.profile.state && this.props.profile.state
        );
        return action ? render(action) : null;
    }

    @autobind
    viewProjectButton() {
        return this.renderAction("viewProject", options => (
            <Button variant={"secondary"} onClick={this.props.viewProject} disabled={options.disabled}>
                Zur Detailansicht
            </Button>
        ));
    }

    @autobind
    setProjectReopenButton() {
        return this.renderAction("setProjectReopen", options => (
            <Button variant={"secondary"} onClick={this.props.setProjectReopen} disabled={options.disabled}>
                Wieder in Vermittelt
            </Button>
        ));
    }

    @autobind
    startProjectCompletionButton() {
        const { profile } = this.props;

        return this.renderTransitionAction("umsetzen", () =>
            profile.status === VermittlungStatus.InExecution ? (
                <Button onClick={() => this.props.setProjectCompleted({ feedbackType: "completeVorgang" })}>
                    Umgesetzt
                </Button>
            ) : null
        );
    }

    @autobind
    setVermittlungsType() {
        return this.renderAction("editVermittlungsType", () => (
            <React.Fragment>
                <Feature flags={"vermittlungsType"}>
                    <EditProjectTypeForm profile={this.props.profile} onSubmit={this.props.updateVorgangProfile} />
                </Feature>
            </React.Fragment>
        ));
    }

    @autobind
    verifyProjectCheckbox() {
        return this.renderAction("verifyProject", options => (
            <Feature off flags={"vermittlungsType"}>
                <Checkbox
                    disabled={options.disabled}
                    label={"Projektinformationen geprüft"}
                    onChange={checked => {
                        this.setState({
                            ...this.state,
                            projectDataVerified: checked
                        });
                    }}
                />
            </Feature>
        ));
    }

    @autobind
    setProjectOperatorButton() {
        return this.renderAction("setProjectOperator", options => (
            <Button
                disabled={
                    !(this.state.projectDataVerified || (isFlagActive("vermittlungsType") && this.props.profile.type))
                }
                onClick={this.props.setProjectOperator}
            >
                Vermittlung übernehmen
            </Button>
        ));
    }

    @autobind
    startCancelationButton() {
        return this.renderAction("startCancelation", options => (
            <Button onClick={this.props.startCancelation} disabled={options.disabled} variant={"secondary"}>
                Vermittlung abbrechen
            </Button>
        ));
    }

    @autobind
    startRejectionButton() {
        return this.renderAction("startRejection", options => (
            <Button
                onClick={this.props.startRejection}
                disabled={
                    !(
                        this.state.projectDataVerified ||
                        (isFlagActive("vermittlungsType") &&
                            this.props.profile.type &&
                            this.props.profile.type !== "KOORDINATION")
                    )
                }
                variant={"secondary"}
            >
                Vermittlung ablehnen
            </Button>
        ));
    }

    @autobind
    startMatchingButton() {
        return this.renderAction("startMatching", options => (
            <Button
                onClick={this.props.startMatching}
                disabled={this.props.isMatching || !this.props.profile.type || options.disabled}
            >
                Matching
            </Button>
        ));
    }

    @autobind
    createSuccessorButton() {
        return this.renderAction("createSuccessor", options => {
            const { id: vermittlungId, vorgaenge, createdAt } = this.props.profile;
            const vermittlungCreatedAt = new Date(createdAt);

            const isNewerOneExisting = vorgaenge.some(
                vorgang => vorgang.id !== vermittlungId && new Date(vorgang.createdAt) > vermittlungCreatedAt
            );

            return (
                <Button variant={"secondary"} onClick={this.props.createSuccessor} disabled={isNewerOneExisting}>
                    Neue Vermittlung
                </Button>
            );
        });
    }

    @autobind
    viewRelatedVorgaengeLink() {
        const { projectId, vorgaenge } = this.props.profile;

        return vorgaenge.length > 1 ? (
            <Paragraph align={"center"} size={"mini"}>
                Alle Vermittlungen zu diesem Projekt{" "}
                <InlineLink
                    to={{
                        pathname: "/projekte",
                        state: { search: { projectId } }
                    }}
                >
                    anzeigen
                </InlineLink>
                .
            </Paragraph>
        ) : null;
    }

    @autobind
    baustellenStartTerminFestlegung() {
        return this.renderTransitionAction(
            "baustellenStartTerminFestlegen",
            () => this.props.profile.type === "BETREUUNG" && <BaustellenStartTerminFestlegung {...this.props} />
        );
    }

    @autobind
    baustellenStartTerminBestaetigung() {
        return this.renderTransitionAction(
            "baustellenStartBestaetigen",
            () => this.props.profile.type === "BETREUUNG" && <BaustellenStartTerminBestaetigung {...this.props} />
        );
    }

    render() {
        return (
            <ActionBox>
                {this.props.formMessage && this.props.formMessageRelation === "project" ? (
                    <FormMessage relatesTo={"bottom"} {...this.props.formMessage} />
                ) : null}

                <FormMessage
                    // iconName={"IconInfo"}
                    relatesTo={"bottom"}
                    type={"warning"}
                    message={"Statusversetzende Aktionen sind nur noch im neuen Lucify verfügbar"}
                    action={{
                        component: ButtonLink,
                        variant: "blank",
                        label: "Im neuen Lucify öffnen",
                        href: lucifyVorgangLink(
                            getEnvironment(),
                            this.context.user.country.toUpperCase(),
                            this.props.macherProjekt.id,
                            this.props.profile.id
                        ),
                        target: "_blank"
                    }}
                />

                {/*<ActionBoxGroup layout={"first"}>*/}
                {/*    {this.setVermittlungsType()}*/}
                {/*    {this.verifyProjectCheckbox()}*/}
                {/*    {this.setProjectOperatorButton()}*/}
                {/*    {this.startRejectionButton()}*/}
                {/*</ActionBoxGroup>*/}

                {/*{this.baustellenStartTerminFestlegung()}*/}
                {/*{this.baustellenStartTerminBestaetigung()}*/}

                <ActionBoxGroup>
                    {/*    {this.startMatchingButton()}*/}
                    {/*    {this.startProjectCompletionButton()}*/}
                    {!this.props.isDetail ? this.viewProjectButton() : null}
                    {/*    {this.setProjectReopenButton()}*/}
                    {/*    {this.startCancelationButton()}*/}
                    {/*    {this.createSuccessorButton()}*/}
                    {this.viewRelatedVorgaengeLink()}
                </ActionBoxGroup>

                <ActionBoxGroup layout={this.props.isDetail ? "separated" : "slightlySeparated"}>
                    {this.props.children}
                </ActionBoxGroup>
            </ActionBox>
        );
    }
}
