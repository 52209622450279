import React from "react";
import HandwerkerBewertungenListe from "../../../components/Bewertungen/HandwerkerBewertungenListe";
import Cell from "../../../components/Grid/Cell";
import Grid from "../../../components/Grid/Grid";
import GridContainer from "../../../components/Grid/GridContainer";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import { Pagination } from "../../../components/Pagination/Pagination";
import { ICraftsmanActions, ICraftsmanPromiseState } from "../../../hoc/withCraftsman";
import { ICraftsmenBewertungenState } from "../../../interfaces/ApplicationState";
import { withFormMessage } from "../../../interfaces/Globals";

export function initialBewertungenParams() {
    return {
        pageSize: 3,
        page: 1,
        sortBy: "createdAt",
        sortOrder: "desc"
    };
}

interface CraftsmanBewertungenProps {
    bewertungen: ICraftsmenBewertungenState;
    meta: ICraftsmanPromiseState;
    actions: ICraftsmanActions;
}

const CraftsmanBewertungen: React.FC<CraftsmanBewertungenProps & Omit<withFormMessage, "resetFormMessage">> = props => {
    let params = initialBewertungenParams();
    const { bewertungen, meta, actions } = props;
    const { data, pagination } = bewertungen || {};

    async function deleteBewertung(bewertungId: string) {
        try {
            await actions.deleteBewertung(bewertungId);
            await actions.getBewertungen({ ...params, page: pagination.pageNumber });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Pagination
            onChange={paginationParams =>
                actions.getBewertungen({
                    ...params,
                    ...paginationParams
                })
            }
            pagination={pagination || params}
            pageSize={3}
            pageSizePosition={"none"}
            pageSizeOptions={[
                { label: "3", value: 3 },
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "25", value: 25 }
            ]}
        >
            {meta.fetching && !data?.length ? (
                <LoadingIndicator />
            ) : (
                <GridContainer>
                    <Grid marginX paddingX>
                        <Cell small={12}>
                            <HandwerkerBewertungenListe
                                deleteBewertung={deleteBewertung}
                                formMessage={props.formMessage}
                                formMessageRelation={props.formMessageRelation}
                                bewertungen={data || []}
                            />
                        </Cell>
                    </Grid>
                </GridContainer>
            )}
        </Pagination>
    );
};

export default CraftsmanBewertungen;
