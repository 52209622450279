import React from "react";
import classNames from "classnames";
import { ICraftsmanActions } from "../../hoc/withCraftsman";
import Comment, { CommentProps } from "./Comment";
import styles from "./Comment.css";

interface CommentListProps {
    comments: Omit<CommentProps, "deleteComment">[];
    actions: ICraftsmanActions;
}

const CommentList = ({ comments = [], actions }: CommentListProps) => {
    return (
        <div
            className={classNames(styles.list, {
                [styles.listEmpty]: !comments.length
            })}
        >
            {comments.length
                ? comments.map(comment => <Comment key={comment.id} {...comment} {...actions} />)
                : "Keine Kommentare"}
        </div>
    );
};

export default CommentList;
