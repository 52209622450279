import React from "react";
import { classNames } from "@lucify/utils";
import styles from "../Radiobutton.css";

interface RadioButtonProps extends React.HTMLProps<HTMLInputElement> {
    disabled?: boolean;
    label?: string;
    labelTop?: boolean;
    small?: boolean;
    className?: string;
    labelClassName?: string;
    field?: { onChange: Function };
    variant?: string;
}

const Radiobutton: React.FC<RadioButtonProps> = props => {
    const {
        field,
        value,
        disabled,
        labelTop,
        small,
        className,
        labelClassName,
        checked,
        label,
        variant,
        onBlur,
        onChange,
        name
    } = props;

    const inputAttributes = {
        type: "radio",
        className: styles.radio,
        disabled,
        ...(field
            ? // backwards-compability for old usage of RadioButton without FormCheckable/FormikLayer component
              field
            : {
                  onBlur,
                  onChange,
                  name
              }),
        checked,
        value
    };

    function handleChange(e) {
        const { field, onChange } = props;
        const onChangeFunc = onChange ? onChange : field!.onChange;

        onChangeFunc(e);
    }

    return (
        <label
            className={classNames(styles.wrapper, className, {
                [styles[variant!]]: !!variant,
                [styles.disabled]: disabled,
                [styles.labelTop]: labelTop,
                [styles.small]: small
            })}
        >
            <input {...inputAttributes} onChange={handleChange} />
            <div className={styles.fakeRadio} />
            {label ? <div className={classNames(styles.labelText, labelClassName)}>{label}</div> : null}
        </label>
    );
};

Radiobutton.defaultProps = {
    disabled: false,
    label: "",
    labelTop: false
};

export default Radiobutton;
