import React, { Component } from "react";
import { autobind } from "core-decorators";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { isRestErrorResponse } from "@lucify/utils";
import { AutoSave, AutoSaveProps } from "./AutoSave";

interface AutoSaveFormProps {
    onChange: (values) => void;
    initialValues?: object;
    children: any;
    validateOnChange: boolean;
    validateOnBlur: boolean;
    enableReinitialize: boolean;
}

export default class AutoSaveForm extends Component<AutoSaveFormProps & AutoSaveProps> {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        debounce: PropTypes.number
    };

    static defaultProps = {
        onChange: () => Promise.resolve(),
        debounce: 300,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        initialValues: {}
    };

    @autobind
    async submitForm(values, { setSubmitting, setFieldError }) {
        setSubmitting(true);
        try {
            await this.props.onChange(values);
        } catch (err) {
            if (isRestErrorResponse(err) && err.response.data.errors) {
                err.response.data.errors.forEach(({ field, defaultMessage: message }) => {
                    setFieldError(field, message);
                });
            } else {
                console.error(err);
            }
        } finally {
            setSubmitting(false);
        }
    }

    render() {
        return (
            <Formik
                onSubmit={this.submitForm}
                validateOnChange={this.props.validateOnChange}
                validateOnBlur={this.props.validateOnBlur}
                enableReinitialize={this.props.enableReinitialize}
                initialValues={{
                    ...this.props.initialValues
                }}
            >
                {formikBag => (
                    <Form>
                        <AutoSave debounce={this.props.debounce} debug={this.props.debug} />
                        {typeof this.props.children === "function"
                            ? this.props.children(formikBag)
                            : this.props.children}
                    </Form>
                )}
            </Formik>
        );
    }
}
