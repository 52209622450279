import { DokumentenTag } from "./dokumente";

export const documentTagsHandwerker: DokumentenTag[] = [
    {
        name: "Rahmenvertrag",
        short: "RV",
        countries: ["DE"],
        userSelectable: false
    },
    {
        name: "Leistungsverzeichnis",
        short: "LV",
        countries: ["DE"],
        userSelectable: false
    },
    {
        name: "Teilnahmebedingungen",
        short: "TB",
        countries: ["DE"],
        userSelectable: false
    },
    {
        name: "Anlage Geschäftspartnerkonto",
        short: "KT",
        countries: ["DE"],
        userSelectable: false
    },
    {
        name: "Unterschriebenes Leistungsverzeichnis",
        short: "LVU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Unterschriebene Teilnahmebedingungen",
        short: "TBU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Unterschriebener Rahmenvertrag",
        short: "RVU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Handwerkskarte",
        short: "HWK",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Gewerbeanmeldung",
        short: "GWA",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Betriebshaftpflicht",
        short: "BHV",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Freistellungsbescheinigung",
        short: "FSB",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Zeugnisse und Zertifikate",
        short: "ZZ",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Meetingnotizen",
        short: "MN",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Handelsregisterauszug",
        short: "HR",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Bonitätsauskunft",
        short: "BA",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "OBI Präsentationen",
        short: "PO",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Partner-Präsentationen",
        short: "PP",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Partner-Vertrag",
        short: "PV",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Unterschriebener Partner-Vertrag",
        short: "PVU",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Preisübersicht",
        short: "PU",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Sonstiges",
        short: "SN",
        countries: ["DE", "AT"],
        userSelectable: true
    }
];
