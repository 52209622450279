import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { $TSFixMe, BaustellenStartTerminTypes } from "@lucify/types";
import { ActionBoxGroup } from "../../../../components/ActionBox/ActionBox";
import Button from "../../../../components/Button/Button";
import FormMessage from "../../../../components/FormElements/FormMessage";
import Checkbox from "../../../../components/FormInput/Formik/Checkbox";
import Datepicker from "../../../../components/FormInput/Formik/Datepicker";
import { withFormMessage } from "../../../../interfaces/Globals";
import { required } from "../../../../utils/validation/validators";

export const BaustellenStartTerminFestlegung: React.FC<$TSFixMe & withFormMessage> = props => {
    const { baustellenStartTerminFestlegen, formMessage, formMessageRelation } = props;
    const { t } = useTranslation();
    const [isSubmittingToService, setSubmittingToService] = useState(false);

    async function submit(data, { setSubmitting }) {
        setSubmittingToService(true);

        try {
            await baustellenStartTerminFestlegen(data.baustellenStartTermin);
        } catch (e) {
            console.log(e);
            setSubmitting(false);
            setSubmittingToService(false);
        } finally {
            await props.refreshProject(true);
        }
    }

    return (
        <Formik
            onSubmit={submit}
            initialValues={{
                internal__ohneTermin: false,
                baustellenStartTermin: {
                    datum: null,
                    typ: "ERFORDERLICH"
                }
            }}
        >
            {({ values, setValues, setFieldValue, initialValues, isValid, isSubmitting, submitForm }) => (
                <Form>
                    {formMessage && formMessageRelation === "baustellenStartTermin" ? (
                        <FormMessage relatesTo={"bottom"} {...formMessage} />
                    ) : null}

                    <ActionBoxGroup>
                        <Field
                            name={"baustellenStartTermin.datum"}
                            component={Datepicker}
                            layout={"noPadding"}
                            label={t("internals:fields.vermittlung.baustellenStartTermin.datum")}
                            disabled={values.internal__ohneTermin}
                            validate={!values.internal__ohneTermin ? required : undefined}
                        />

                        <Field
                            name={"internal__ohneTermin"}
                            component={Checkbox}
                            onChange={event => {
                                if (event.currentTarget.checked) {
                                    setValues({
                                        ...values,
                                        baustellenStartTermin: {
                                            datum: null,
                                            typ: BaustellenStartTerminTypes.UNBEKANNT
                                        }
                                    });
                                } else {
                                    setFieldValue("baustellenStartTermin.typ", initialValues.baustellenStartTermin.typ);
                                }
                            }}
                            label={"Ohne Termin fortfahren"}
                        />

                        <Button
                            type={"submit"}
                            disabled={!isValid || isSubmitting || isSubmittingToService}
                            loading={isSubmitting}
                        >
                            Abgestimmt
                        </Button>
                    </ActionBoxGroup>
                </Form>
            )}
        </Formik>
    );
};
