import React, { PureComponent } from "react";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { createErrorObject } from "@lucify/utils";
import { ActionBox, ActionBoxGroup } from "../ActionBox/ActionBox";
import Button from "../Button/Button";
import ContainerRow, { ContainerCol } from "../Container/Grid";
import FormMessage from "../FormElements/FormMessage";
import ShortInfo from "../ShortInfo/ShortInfo";

export default class ContainerViewItem extends PureComponent {
    static propTypes = {
        fields: PropTypes.array,
        editForm: PropTypes.func,
        formRefName: PropTypes.string,
        editProfileForm: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
        editProfileFormMessageRelation: PropTypes.string,
        updateProfile: PropTypes.func.isRequired,
        fullWidth: PropTypes.bool,
        profile: PropTypes.object.isRequired,
        icon: PropTypes.element,
        editorView: PropTypes.bool,
        container: PropTypes.bool
    };

    @autobind
    async submitForm(data, { setSubmitting, setErrors }) {
        try {
            await this.props.updateProfile(data);
            this.props.toggleEditMode(this.props.formRefName);
            if (this.props.afterSubmit) {
                this.props.afterSubmit();
            }
        } catch (err) {
            if (err.response && err.response.data.hasOwnProperty("errors")) {
                setErrors(createErrorObject(err));
            }
        } finally {
            setSubmitting(false);
        }
    }

    @autobind
    resetForm() {
        this.props.toggleEditMode(this.props.formRefName);
        this.props.resetFormMessage();
    }

    render() {
        const {
            fields,
            form,
            editForm: EditForm,
            editFormProps,
            editProfileForm: EditProfileForm,
            profile,
            editorView,
            container = true
        } = this.props;
        const isCurrent = this.props.editingRef === this.props.formRefName;
        const editMode = this.props.editing && isCurrent && fields && fields.length;

        // Wenn container prop, müssen diverse andere props auch gesetzt werden um ein einheitliches Layout
        // zwischen editMode und nicht editMode in der ShortInfo componente zu gewährleisten
        const shortInfoSharedProps = {
            container,
            layoutClass: !container ? "profileNotes" : undefined
        };

        return editMode ? (
            <EditProfileForm
                id={this.props.id}
                fields={fields}
                onReset={this.resetForm}
                onSubmit={this.submitForm}
                headline={this.props.headline}
                formMessage={this.props.formMessage}
                formMessageRelation={this.props.formMessageRelation}
                profile={profile}
                {...shortInfoSharedProps}
            >
                <EditForm dispatch={this.props.dispatch} fields={fields} form={form} {...editFormProps} />
                {this.props.formMessageRelation === this.props.editProfileFormMessageRelation ? (
                    <FormMessage {...this.props.formMessage} />
                ) : null}
            </EditProfileForm>
        ) : (
            <ContainerRow layout={"profileShort"}>
                <ContainerCol layout={"profileContent"}>
                    <ShortInfo
                        headline={this.props.headline}
                        custom={this.props.icon ? { icon: this.props.icon } : null}
                        {...shortInfoSharedProps}
                    >
                        {this.props.displayAs ? this.props.displayAs : this.props.text}
                    </ShortInfo>
                </ContainerCol>
                {editorView ? (
                    <ContainerCol layout={"profileActions"}>
                        {!editMode ? (
                            <ActionBox>
                                <ActionBoxGroup>
                                    <Button
                                        onClick={() => this.props.toggleEditMode(this.props.formRefName)}
                                        variant={"secondary"}
                                    >
                                        Bearbeiten
                                    </Button>
                                </ActionBoxGroup>
                            </ActionBox>
                        ) : null}
                    </ContainerCol>
                ) : null}
            </ContainerRow>
        );
    }
}
