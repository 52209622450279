import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { autobind } from "core-decorators";
import { bindActionCreators } from "redux";
import { checkPermission } from "@lucify/auth";
import { getGenericMessage } from "@lucify/utils";
import { lucifyOIDCProvider } from "../services/lucifyOIDCProvider";
import { macherService } from "../services/macher/macher";
import { realizationService } from "../services/realization";
import { response } from "../store/actions/craftsmen.actions";
import {
    getExtendedVorgangProfile,
    getMacherProjektProfile,
    updateMacherProjektProfile,
    updateVorgangProfile
} from "../store/actions/macherProjekte.actions";
import {
    addComment,
    createCustomerOffer,
    createCustomerOfferDocument,
    createCustomerOfferNachtrag,
    createRequestCoordinationDocument,
    createRequestDocument,
    createSuccessor,
    deleteComment,
    deleteCustomerOffer,
    getComments,
    getHistory,
    getProjectCustomerOffers,
    getProjectSDForms,
    setProjectCancelled,
    setProjectCompleted,
    setProjectOperator,
    setProjectRejected,
    setProjectReopen,
    setProjectRevoked,
    updateCustomerOffer
} from "../store/actions/projects.actions";
import { hasVorgangTransition } from "../utils/helpers/transitions";
import { wait } from "../utils/helpers/wait";
import { withNotifications } from "./withNotifications";
import { tickAfter } from "./withTick";

const mapStateToProps = ({ projects, macherProjekte }) => ({ projects, macherProjekte });
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getMacherProjektProfile,
            getExtendedVorgangProfile,
            setProjectOperator,
            setProjectCompleted,
            setProjectRejected,
            setProjectRevoked,
            setProjectCancelled,
            setProjectReopen,
            createSuccessor,
            updateMacherProjektProfile,
            updateVorgangProfile,
            response,
            getComments,
            addComment,
            deleteComment,
            getHistory,
            createRequestDocument,
            createRequestCoordinationDocument,
            getProjectCustomerOffers,
            createCustomerOffer,
            updateCustomerOffer,
            deleteCustomerOffer,
            createCustomerOfferDocument,
            getProjectSDForms,
            createCustomerOfferNachtrag
        },
        dispatch
    );

const ACTIONS_NAMESPACE = "actions";
const PROFILE_NAMESPACE = "profile";
const COMMENTS_NAMESPACE = "comments";
const HISTORY_NAMESPACE = "history";
const OFFER_NAMESPACE = "offers";
const BILLING_NAMESPACE = "billing";
const TRANSITIONS_NAMESPACE = "transitions";

export const withProject = (...namespaces) => {
    return WrappedComponent => {
        @connect(mapStateToProps, mapDispatchToProps)
        @withTranslation(["internals", "messages"])
        @withNotifications
        class WithProject extends Component {
            state = {
                formMessage: null,
                formMessageRelation: null,
                transitions: null
            };

            componentDidMount() {
                this.fetchData();
                this._isMounted = true;
            }

            componentDidUpdate(prevProps) {
                if (prevProps.id !== this.props.id) {
                    this.fetchData();
                }
            }

            componentWillUnmount() {
                this._isMounted = false;
            }

            setErrorMessage(error, actionName, formMessageRelation) {
                const { response } = error;

                if (error.name === "AbortError" || !response) {
                    return null;
                }

                const messages = this.props.t("messages:actions." + actionName, { returnObjects: true });
                this.setState({
                    formMessage: getGenericMessage(response, typeof messages === "string" ? {} : messages),
                    formMessageRelation
                });
            }

            @autobind
            resetFormMessage() {
                this.setState({
                    formMessage: null,
                    formMessageRelation: null
                });
            }

            @autobind
            async fetchData(leaveFormMessages) {
                const betreuungsprozessEnabled = checkPermission(lucifyOIDCProvider, "projekt:betreuungsprozess");

                if (!leaveFormMessages) {
                    this.resetFormMessage();
                }

                // Use separate try..catch statement for transitions. So if they can't be fetched
                //  the project is fechted anyway
                try {
                    if (namespaces.includes(TRANSITIONS_NAMESPACE) && this.props.id && this.props.macherProjektId) {
                        const transitions = await this.getTransitions();
                        if (this._isMounted) {
                            this.setState({
                                transitions
                            });
                        }
                    }
                } catch (err) {
                    this.setErrorMessage(err, "fetchData", "project");
                }

                try {
                    const pendingFetches = [];

                    // Also load profile for history namespace to include feedbacks
                    // WARNING: Never (ever!) load something before the profile

                    if (
                        (namespaces.includes(PROFILE_NAMESPACE) || namespaces.includes(HISTORY_NAMESPACE)) &&
                        this.props.macherProjektId
                    ) {
                        pendingFetches.push(
                            this.props.getMacherProjektProfile(this.props.macherProjektId, this.props.id)
                        );
                        pendingFetches.push(
                            this.props.getExtendedVorgangProfile(this.props.macherProjektId, this.props.id)
                        );
                        // pendingFetches.push(this.props.getVorgangProfile(this.props.macherProjektId, this.props.id));
                    }

                    if (namespaces.includes(COMMENTS_NAMESPACE) && this.props.id) {
                        pendingFetches.push(this.props.getComments(this.props.id));
                    }

                    if (namespaces.includes(HISTORY_NAMESPACE) && this.props.id) {
                        pendingFetches.push(this.props.getHistory(this.props.id));
                    }

                    if (namespaces.includes(OFFER_NAMESPACE) && this.props.id && betreuungsprozessEnabled) {
                        pendingFetches.push(this.props.getProjectCustomerOffers({ vermittlungId: this.props.id }));
                    }

                    const results = await Promise.all(pendingFetches);

                    if (
                        namespaces.includes(PROFILE_NAMESPACE) &&
                        namespaces.includes(BILLING_NAMESPACE) &&
                        this.props.id &&
                        this.props.macherProjektId &&
                        betreuungsprozessEnabled
                    ) {
                        const {
                            value: {
                                data: { projectId }
                            }
                        } = results[0];

                        if (projectId) {
                            await this.props.getProjectSDForms(projectId);
                        }
                    }
                } catch (err) {
                    this.setErrorMessage(err, "fetchData", "project");
                }
            }

            createNotificationLink() {
                return {
                    to: {
                        pathname: `/projekt/${this.props.macherProjektId}/vorgang/${this.props.id}`
                    },
                    text: "Zur Vermittlung"
                };
            }

            // ACTIONS
            @autobind
            @tickAfter
            async createSuccessor() {
                this.resetFormMessage();

                try {
                    await this.props.createSuccessor(this.props.id);
                    this.props.createNotification({
                        type: "success",
                        message: this.props.t("messages:success.transition", {
                            newState: this.props.t("internals:states.created")
                        }),
                        link: this.createNotificationLink()
                    });
                    this.props.history.push("/projekte/created");
                } catch (err) {
                    this.setErrorMessage(err, "createSuccessor", "project");
                }
            }

            // ACTIONS
            @autobind
            @tickAfter
            async setProjectReopen() {
                this.resetFormMessage();

                try {
                    await this.props.setProjectReopen(this.props.id);
                    this.props.createNotification({
                        type: "success",
                        message: this.props.t("messages:success.transition", {
                            newState: this.props.t("internals:states.in_execution")
                        }),
                        link: this.createNotificationLink()
                    });
                    this.props.history.push("/projekte/in_execution");
                } catch (err) {
                    this.setErrorMessage(err, "setProjectReopen", "project");
                }
            }

            @autobind
            @tickAfter
            async setProjectOperator() {
                this.resetFormMessage();

                try {
                    await this.props.setProjectOperator(this.props.id);
                    this.fetchData();
                } catch (err) {
                    this.setErrorMessage(err, "setProjectOperator", "project");
                }
            }

            @autobind
            async setProjectCompleted(data) {
                this.resetFormMessage();

                try {
                    await this.props.setProjectCompleted(this.props.id, data);
                    this.props.createNotification({
                        type: "success",
                        message: this.props.t("messages:success.transition", {
                            newState: this.props.t("internals:states.completed")
                        }),
                        link: this.createNotificationLink()
                    });
                    this.fetchData();
                } catch (err) {
                    this.setErrorMessage(err, "setProjectCompleted", "project");
                }
            }

            @autobind
            async setProjectRejected(data) {
                this.resetFormMessage();

                try {
                    await this.props.setProjectRejected(this.props.id, data);
                    this.props.createNotification({
                        type: "success",
                        message: this.props.t("messages:success.transition", {
                            newState: this.props.t("internals:states.rejected")
                        }),
                        link: this.createNotificationLink()
                    });
                    this.props.history.replace("/projekte/created");
                } catch (err) {
                    this.setErrorMessage(err, "setProjectRejected");
                }
            }

            @autobind
            async setProjectRevoked(data) {
                this.resetFormMessage();

                try {
                    await this.props.setProjectRevoked(this.props.id, data);
                    this.props.createNotification({
                        type: "success",
                        message: this.props.t("messages:success.transition", {
                            newState: this.props.t("internals:states.operator_assigned")
                        }),
                        link: this.createNotificationLink()
                    });
                    this.props.history.replace("/projekte/created");
                } catch (err) {
                    this.setErrorMessage(err, "setProjectRevoked");
                }
            }

            @autobind
            async setProjectCancelled(data) {
                this.resetFormMessage();

                try {
                    await this.props.setProjectCancelled(this.props.id, data);
                    this.props.createNotification({
                        type: "success",
                        message: this.props.t("messages:success.transition", {
                            newState: this.props.t("internals:states.cancelled")
                        }),
                        link: this.createNotificationLink()
                    });
                    this.props.history.replace("/projekte/created");
                } catch (err) {
                    this.setErrorMessage(err, "setProjectCancelled");
                }
            }

            // TODO check if needed ??
            @autobind
            async updateProjectProfileFormik(data, formMessageRelation) {
                this.resetFormMessage();

                try {
                    await this.props.updateVorgangProfile(this.props.macherProjektId, this.props.id, data);
                } catch (err) {
                    this.resetFormMessage();
                    this.setErrorMessage(err, "updateProjectProfile", formMessageRelation);

                    return Promise.reject(err);
                }
            }

            @autobind
            async updateMacherProjektProfile(data, formMessageRelation) {
                this.resetFormMessage();

                try {
                    return await this.props.updateMacherProjektProfile(this.props.macherProjektId, data);
                } catch (err) {
                    if (!(err.response && err.response.data.hasOwnProperty("errors"))) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "updateProjectProfile", formMessageRelation);
                    }

                    return Promise.reject(err);
                }
            }

            @autobind
            async updateVorgangProfile(data, formMessageRelation) {
                this.resetFormMessage();

                try {
                    return await this.props.updateVorgangProfile(this.props.macherProjektId, this.props.id, data);
                } catch (err) {
                    if (!(err.response && err.response.data.hasOwnProperty("errors"))) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "updateProjectProfile", formMessageRelation);
                    }

                    return Promise.reject(err);
                }
            }

            @autobind
            startProjectCompletion() {
                this.resetFormMessage();
                this.props.history.push(`/projekt/${this.props.macherProjektId}/vorgang/${this.props.id}/umsetzen`);
            }

            @autobind
            startMatching() {
                this.resetFormMessage();
                this.props.history.push(`/matching/${this.props.macherProjektId}/vorgang/${this.props.id}`);
            }

            @autobind
            startCancelation() {
                this.resetFormMessage();
                this.props.history.push(`/projekt/${this.props.macherProjektId}/vorgang/${this.props.id}/abbrechen`);
            }

            @autobind
            startBilling() {
                this.resetFormMessage();
                this.props.history.push(
                    `/projekt/${this.props.macherProjektId}/vorgang/${this.props.id}/abrechnen/pauschale`
                );
            }

            @autobind
            startRejection() {
                this.resetFormMessage();
                this.props.history.push(`/projekt/${this.props.macherProjektId}/vorgang/${this.props.id}/ablehnen`);
            }

            @autobind
            startProjectRevocation() {
                this.resetFormMessage();
                this.props.history.push(`/projekt/${this.props.macherProjektId}/vorgang/${this.props.id}/entziehen`, {
                    from: this.props.history.location.pathname
                });
            }

            @autobind
            viewProject() {
                this.resetFormMessage();
                this.props.history.push(`/projekt/${this.props.macherProjektId}/vorgang/${this.props.id}`);
            }

            @autobind
            viewCraftsman(craftsmanId) {
                this.resetFormMessage();
                if (craftsmanId) {
                    this.props.history.push(`/handwerker/${craftsmanId}`);
                }
            }

            @autobind
            viewMailTemplates(craftsmanId) {
                this.resetFormMessage();
                if (craftsmanId) {
                    this.props.history.push(`/projekt/${this.props.macherProjektId}/vorgang/${this.props.id}/vorlagen`);
                }
            }

            // currentCraftsman related actions
            @autobind
            async setRequestDeclined(requestId, vermittlungId, data) {
                this.resetFormMessage();

                try {
                    await this.props.response(requestId, vermittlungId, "decline", data);
                    this.props.createNotification({
                        type: "success",
                        message: this.props.t("messages:success.transition", {
                            newState: this.props.t("internals:states.operator_assigned")
                        }),
                        link: this.createNotificationLink()
                    });
                    this.props.history.push("/projekte/created");
                } catch (err) {
                    this.setErrorMessage(err, "setRequestDeclined");
                }
            }

            @autobind
            @tickAfter
            async setRequestAccepted(requestId, vermittlungId) {
                this.resetFormMessage();

                try {
                    await this.props.response(requestId, vermittlungId, "accept");
                    this.props.createNotification({
                        type: "success",
                        message: this.props.t("messages:success.transition", {
                            newState: this.props.t("internals:states.in_execution")
                        }),
                        link: this.createNotificationLink()
                    });
                    this.props.history.push("/projekte/craftsman_requested");
                } catch (err) {
                    this.setErrorMessage(err, "setRequestAccepted", "craftsman");
                }
            }

            @autobind
            startRequestDeclinement(requestId) {
                this.props.history.push(
                    `/handwerker/absage/${this.props.macherProjektId}/${this.props.id}/${requestId}`,
                    {
                        from: this.props.history.location.pathname
                    }
                );
            }

            @autobind
            async vorOrtTerminAbstimmen(data) {
                this.resetFormMessage();

                try {
                    await macherService.vorOrtTerminAbstimmen(this.props.id, data);
                } catch (err) {
                    if (!(err.response && err.response.data.hasOwnProperty("errors"))) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "vorOrtTerminAbstimmen", "vorOrtTermin");
                    }

                    return Promise.reject(err);
                } finally {
                    await this.fetchData();
                }
            }

            @autobind
            async vorOrtTerminBestaetigen() {
                this.resetFormMessage();

                try {
                    await macherService.vorOrtTerminBestaetigen(this.props.id);
                } catch (err) {
                    if (!(err.response && err.response.data.hasOwnProperty("errors"))) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "vorOrtTerminBestaetigen", "vorOrtTermin");
                    }
                } finally {
                    await this.fetchData(true);
                }
            }

            @autobind
            async vorOrtTerminLoeschen() {
                this.resetFormMessage();

                try {
                    await macherService.vorOrtTerminLoeschen(this.props.id);
                } catch (err) {
                    if (!(err.response && err.response.data.hasOwnProperty("errors"))) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "vorOrtTerminLoeschen", "vorOrtTermin");
                    }
                } finally {
                    await this.fetchData(true);
                }
            }

            @autobind
            async getExtendedVorgang() {
                this.resetFormMessage();

                try {
                    return await realizationService.getExtendedVorgang(this.props.macherProjektId, this.props.id);
                } catch (err) {
                    if (!(err.response && err.response.data.hasOwnProperty("errors"))) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "getExtendedVorgang", "extendedVorgang");
                    }

                    return Promise.reject(err);
                }
            }

            @autobind
            async baustellenStartTerminFestlegen(data) {
                this.resetFormMessage();

                try {
                    return await realizationService.baustellenStartTerminFestlegenTrigger(
                        this.props.macherProjektId,
                        this.props.id,
                        data
                    );
                } catch (err) {
                    if (!(err.response && err.response.data.hasOwnProperty("errors"))) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "baustellenStartTerminFestlegen", "baustellenStartTermin");
                    }

                    return Promise.reject(err);
                }
            }

            @autobind
            async baustellenStartBestaetigen() {
                this.resetFormMessage();

                try {
                    return await realizationService.baustellenStartBestaetigenTrigger(
                        this.props.macherProjektId,
                        this.props.id
                    );
                } catch (err) {
                    if (!(err.response && err.response.data.hasOwnProperty("errors"))) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "baustellenStartBestaetigen", "baustellenStartTermin");
                    }

                    return Promise.reject(err);
                }
            }

            @autobind
            async baustellenStartTerminLoeschen() {
                this.resetFormMessage();

                try {
                    return await realizationService.baustellenStartTerminLoeschenTrigger(
                        this.props.macherProjektId,
                        this.props.id
                    );
                } catch (err) {
                    if (!(err.response && err.response.data.hasOwnProperty("errors"))) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "baustellenStartTerminLoeschen", "baustellenStartTermin");
                    }

                    return Promise.reject(err);
                }
            }

            @autobind
            async getComments() {
                await this.props.getComments(this.props.id);
            }

            @autobind
            async addComment(comment) {
                this.resetFormMessage();

                try {
                    await this.props.addComment(this.props.id, comment);
                } catch (err) {
                    this.setErrorMessage(err, "addComment", "addComment");
                    return Promise.reject(err);
                }
            }

            @autobind
            async deleteComment(commentId) {
                return await this.props.deleteComment(this.props.id, commentId);
            }

            @autobind
            async getHistory() {
                await this.props.getHistory(this.props.id);
            }

            @autobind
            async getCustomerOffers() {
                // this.resetFormMessage();

                try {
                    // Load and await transitions before fetching CustomerOffers
                    //  to make sure they are as fresh as they can be
                    await this.getTransitions();
                    await this.props.getProjectCustomerOffers({ vermittlungId: this.props.id });
                } catch (err) {
                    this.setErrorMessage(err, "getCustomerOffers", "customerOffers");
                    return Promise.reject(err);
                }
            }

            @autobind
            async createCustomerOffer(projectId, offer) {
                this.resetFormMessage();

                try {
                    const { value } = await this.props.createCustomerOffer({
                        ...offer,
                        vermittlungId: this.props.id,
                        projectId
                    });

                    return value;
                } catch (err) {
                    this.setErrorMessage(err, "createCustomerOffer", "customerOffers");
                    return Promise.reject(err);
                } finally {
                    await this.fetchData(true);
                }
            }

            @autobind
            async createCustomerOfferNachtrag(projectId, offer) {
                this.resetFormMessage();

                try {
                    const { value } = await this.props.createCustomerOfferNachtrag(projectId, this.props.id, offer);

                    return value;
                } catch (err) {
                    this.setErrorMessage(err, "createCustomerOfferNachtrag", "customerOffers");
                    return Promise.reject(err);
                } finally {
                    await this.fetchData(true);
                }
            }

            @autobind
            async createCustomerOfferFromWT() {
                this.resetFormMessage();

                const macherProjektProfile = this.props.macherProjekte.macherProjektProfile;

                const profile =
                    (namespaces.includes(PROFILE_NAMESPACE) || namespaces.includes(HISTORY_NAMESPACE)) &&
                    this.props.id &&
                    this.props.macherProjektId &&
                    macherProjektProfile[this.props.macherProjektId]
                        ? {
                              ...macherProjektProfile[this.props.macherProjektId],
                              ...macherProjektProfile[this.props.macherProjektId].vorgaenge.find(
                                  vorgang => vorgang.id === this.props.id
                              )
                          }
                        : null;

                const offerWorkTasks = profile
                    ? profile.workTasks.filter(({ workTask }) => workTask.articleNumber)
                    : [];

                try {
                    const { value } = await this.props.createCustomerOffer({
                        title: "Neues Angebot aus Leistungen",
                        offerWorkTasks,
                        vermittlungId: this.props.id,
                        projectId: profile.projectId
                    });
                    await this.getCustomerOffers();
                    return value;
                } catch (err) {
                    this.setErrorMessage(err, "createCustomerOfferFromWT", "createCustomerOfferFromWT");
                    return Promise.reject(err);
                }
            }

            @autobind
            async deleteCustomerOffer(customerOfferId) {
                this.resetFormMessage();

                try {
                    await this.props.deleteCustomerOffer(customerOfferId, this.props.id);
                    await this.fetchData();
                } catch (err) {
                    this.setErrorMessage(err, "deleteCustomerOffer", "offerWorkTasks");
                    return Promise.reject(err);
                }
            }

            @autobind
            async updateCustomerOffer(customerOfferId, data) {
                try {
                    await this.props.updateCustomerOffer(customerOfferId, data, this.props.id);
                } catch (err) {
                    this.setErrorMessage(err, "updateCustomerOffer", "offerWorkTasks");
                    return Promise.reject(err);
                }
            }

            @autobind
            openCraftsmanRequestDraft() {
                const win = window.open(macherService.getCraftsmanRequestDocumentDraftUri(this.props.id), "_blank");

                if (win) {
                    win.focus();
                }
            }

            @autobind
            openCraftsmanRequestCoordinationDraft(craftsmanFacilityId) {
                const win = window.open(
                    macherService.getCraftsmanRequestCoordinationDocumentDraftUri(this.props.id, craftsmanFacilityId),
                    "_blank"
                );

                if (win) {
                    win.focus();
                }
            }

            @autobind
            openCustomerOfferDraft(customerOfferId) {
                const win = window.open(realizationService.getCustomerOfferDocumentDraftUri(customerOfferId), "_blank");

                if (win) {
                    win.focus();
                }
            }

            @autobind
            openCraftsmanAssignmentDraft(customerOfferId, craftsmanAssignmentId) {
                const win = window.open(
                    realizationService.getCraftsmanAssignmentDocumentDraftUri(customerOfferId, craftsmanAssignmentId),
                    "_blank"
                );

                if (win) {
                    win.focus();
                }
            }

            @autobind
            openCraftsmanRequestDocument(documentId, gaeb) {
                const win = window.open(
                    macherService.getCraftsmanRequestDocumentUri(this.props.id, documentId, false, gaeb),
                    "_blank"
                );

                if (win) {
                    win.focus();
                }
            }

            @autobind
            openCustomerOfferDocument(customerOfferId, documentId) {
                const win = window.open(
                    realizationService.getCustomerOfferDocumentUri(customerOfferId, documentId, false),
                    "_blank"
                );

                if (win) {
                    win.focus();
                }
            }

            @autobind
            openCraftsmanAssignmentDocument(customerOfferId, craftsmanAssignmentId, gaeb) {
                const win = window.open(
                    realizationService.getCraftsmanAssignmentDocumentUri(
                        customerOfferId,
                        craftsmanAssignmentId,
                        false,
                        gaeb
                    ),
                    "_blank"
                );

                if (win) {
                    win.focus();
                }
            }

            @autobind
            async createCraftsmanRequestDocument() {
                try {
                    const { value } = await this.props.createRequestDocument(this.props.id);
                    this.openCraftsmanRequestDocument(value.data.documentId);
                } catch (err) {
                    this.setErrorMessage(err, "createCraftsmanRequestDocument", "createRequestDocument");
                    return Promise.reject(err);
                }
            }

            @autobind
            async createCraftsmanRequestCoordinationDocument(craftsmanFacilityId) {
                try {
                    const { value } = await this.props.createRequestCoordinationDocument(
                        this.props.id,
                        craftsmanFacilityId
                    );
                    this.openCraftsmanRequestDocument(value.data.documentId);
                } catch (err) {
                    this.setErrorMessage(err, "createCraftsmanRequestDocument", "createRequestDocument");
                    return Promise.reject(err);
                }
            }

            @autobind
            async createCustomerOfferDocument(customerOfferId) {
                try {
                    const { value } = await this.props.createCustomerOfferDocument(customerOfferId);
                    const documentLink = value.data.customerServiceContractDocumentLink
                        ? value.data.customerServiceContractDocumentLink.documentId
                        : value.data.customerOfferDocumentLink.documentId;
                    this.openCustomerOfferDocument(customerOfferId, documentLink);
                    return response;
                } catch (err) {
                    this.setErrorMessage(err, "createCustomerOfferDocument", "customerOffers");
                    return Promise.reject(err);
                }
            }

            @autobind
            async createCraftsmanAssignmentDocument(customerOfferId, craftsmanAssignmentId) {
                try {
                    await realizationService.createCraftsmanAssignmentDocument(customerOfferId, craftsmanAssignmentId);
                    this.openCraftsmanAssignmentDocument(customerOfferId, craftsmanAssignmentId);
                    return response;
                } catch (err) {
                    this.setErrorMessage(err, "createCraftsmanAssignmentDocument", "craftsmanAssignments");
                    return Promise.reject(err);
                }
            }

            @autobind
            async createCraftsmanAssignment(customerOfferId, craftsmanFacilityId) {
                try {
                    return await realizationService.createCraftsmanAssignment(customerOfferId, craftsmanFacilityId);
                } catch (err) {
                    this.setErrorMessage(err, "createCraftsmanAssignment", "customerOffers");
                    return Promise.reject(err);
                } finally {
                    await this.fetchData(true);
                }
            }

            @autobind
            async deleteCraftsmanAssignment(customerOfferId) {
                try {
                    await realizationService.deleteCraftsmanAssignment(customerOfferId);
                } catch (err) {
                    this.setErrorMessage(err, "deleteCraftsmanAssignment", "craftsmanAssignments");
                    return Promise.reject(err);
                }
            }

            @autobind
            async cancelCraftsmanAssignments(customerOfferId) {
                try {
                    await realizationService.cancelCraftsmanAssignments(customerOfferId);
                } catch (err) {
                    this.setErrorMessage(err, "cancelCraftsmanAssignments", "craftsmanAssignments");
                    return Promise.reject(err);
                }
            }

            @autobind
            async updatePerformanceRecord(customerOfferId, craftsmanAssignmentId, data) {
                try {
                    return await realizationService.updatePerformanceRecord(
                        customerOfferId,
                        craftsmanAssignmentId,
                        data
                    );
                } catch (err) {
                    this.setErrorMessage(err, "updatePerformanceRecord", "performanceRecords");
                    return Promise.reject(err);
                }
            }

            @autobind
            async createSDForm(customerOfferId) {
                try {
                    return await realizationService.createSDForm(customerOfferId);
                } catch (err) {
                    this.setErrorMessage(err, "createSDForm", "craftsmanAssignments");
                    return Promise.reject(err);
                }
            }

            // @autobind
            // async cancelSDForm(customerOfferId, sdFormId) {
            //     try {
            //         return await customerOffersService.cancelSDForm(customerOfferId, sdFormId);
            //     } catch (err) {
            //         this.setErrorMessage(err, "cancelSDForm", "performanceRecords");
            //         return Promise.reject(err);
            //     }
            // }

            @autobind
            async dismissCustomerOffer(customerOfferId) {
                try {
                    return await realizationService.dismissCustomerOffer(customerOfferId);
                } catch (err) {
                    this.setErrorMessage(err, "dismissCustomerOffer", "customerOffers");
                    return Promise.reject(err);
                } finally {
                    await this.fetchData(true);
                }
            }

            @autobind
            async reactivateCustomerOffer(customerOfferId) {
                try {
                    return await realizationService.reactivateCustomerOffer(customerOfferId);
                } catch (err) {
                    this.setErrorMessage(err, "reactivateCustomerOffer", "customerOffers");
                    // return Promise.reject(err);
                } finally {
                    await this.fetchData(true);
                }
            }

            @autobind
            async getSDForms(projectId) {
                try {
                    const { value } = await this.props.getProjectSDForms(projectId);
                    return value;
                } catch (err) {
                    this.setErrorMessage(err, "getSDForms", "customerOffers");
                    return Promise.reject(err);
                }
            }

            @autobind
            async cancelSDForm(projectId, sdFormId) {
                try {
                    return await realizationService.cancelSDForm(projectId, sdFormId);
                } catch (err) {
                    this.setErrorMessage(err, "cancelSDForm", "sdForms");
                    return Promise.reject(err);
                }
            }

            @autobind
            async getPauschalen(projectId) {
                try {
                    return await realizationService.getPauschalen(projectId);
                } catch (err) {
                    this.setErrorMessage(err, "getPauschalen", "customerOffers");
                    return Promise.reject(err);
                }
            }

            @autobind
            async createPauschaleSDForm(projectId, vermittlungId, data) {
                try {
                    return await realizationService.createPauschaleSDForm(projectId, vermittlungId, data);
                } catch (err) {
                    this.setErrorMessage(err, "createPauschaleSDForm", "customerOffers");
                    return Promise.reject(err);
                }
            }

            @autobind
            async getTransitions() {
                const MAX_RETRIES = 25;
                const { macherProjektId, id } = this.props;

                try {
                    // TODO: Die Phase "Abgebrochen" hat noch keinen Owner
                    for (let retryCount = 1; retryCount <= MAX_RETRIES; retryCount += 1) {
                        // Der MacherService muss zuerst angefragt werden, solange er der Owner der ersten Phase ist.
                        //  Andere Services mit 404 antworten, da ihnen das Projekt noch unbekannt ist.
                        const macherServiceTrigger = await macherService.getTrigger(macherProjektId, id);
                        if (macherServiceTrigger.data.aktiv) {
                            return macherServiceTrigger.data.transitions;
                        }

                        const realizationServiceTrigger = await realizationService.getTrigger(
                            macherProjektId,
                            id,
                            true
                        );
                        if (realizationServiceTrigger.data.aktiv) {
                            return realizationServiceTrigger.data.transitions;
                        }

                        await wait(100);
                    }

                    // TODO: Errorhandling
                    throw Error("Can not resolve leading service.");
                } catch (err) {
                    this.setErrorMessage(err, "getTrigger", "trigger");
                    return Promise.reject(err);
                }
            }

            @autobind
            async uploadProjektDokument(data) {
                try {
                    return await macherService.uploadProjektDokument(this.props.macherProjektId, data);
                } catch (err) {
                    console.log(err);
                    this.setErrorMessage(err, "uploadProjektDokument", "dokumenteUploadLayer");
                    return Promise.reject(err);
                }
            }

            @autobind
            async openProjektDokument(documentId) {
                const win = window.open(
                    macherService.getProjektDokumentUri(this.props.macherProjektId, documentId, false),
                    "_blank"
                );

                if (win) {
                    win.focus();
                }
            }

            @autobind
            async updateProjektDokument(documentId, data) {
                try {
                    return await macherService.updateProjektDokument(this.props.macherProjektId, documentId, data);
                } catch (err) {
                    this.setErrorMessage(err, "updateProjektDokument", "dokumente");
                    return Promise.reject(err);
                }
            }

            @autobind
            async deleteProjektDokument(documentId) {
                try {
                    return await macherService.updateProjektDokument(this.props.macherProjektId, documentId, {
                        deleted: true
                    });
                } catch (err) {
                    this.setErrorMessage(err, "deleteProjektDokument", "dokumente");
                    return Promise.reject(err);
                }
            }

            @autobind
            async deleteMacherProjektTaetigkeit(macherProjektTaetigkeitId) {
                try {
                    const response = await macherService.deleteTaetigkeit(
                        this.props.macherProjektId,
                        macherProjektTaetigkeitId
                    );
                    await this.fetchData(true);
                    return response;
                } catch (err) {
                    this.setErrorMessage(err, "deleteTaetigkeit", "profileTaetigkeiten");
                    return Promise.reject(err);
                }
            }

            @autobind
            hasVorgangTransition(transition) {
                return hasVorgangTransition(this.state.transitions, transition);
            }

            static extendHistoryWithProjectFeedback(history, profile) {
                const extendedHistory = history ? [...history] : [];

                if (history && profile && Array.isArray(profile.feedbacks)) {
                    const projectCreation = {
                        feedbacks: [],
                        id: 0,
                        createdAt: profile.projectCreatedAt,
                        stateChangedAt: profile.projectCreatedAt,
                        status: {
                            status: "created"
                        }
                    };

                    const normalizedProjectFeedback = profile.feedbacks.map(feedback => {
                        const [feedbackType] = feedback.type.split(".");

                        return {
                            feedbacks: [feedback],
                            id: feedback.id,
                            createdAt: feedback.createdAt,
                            stateChangedAt: feedback.createdAt,
                            status: {
                                status: feedbackType
                            }
                        };
                    });

                    extendedHistory.push(projectCreation, ...normalizedProjectFeedback);
                }

                return extendedHistory;
            }

            render() {
                const { projects, macherProjekte, ...rest } = this.props;

                const macherProjektProfiles = macherProjekte.macherProjektProfile;
                const macherProjektExtendedVorgangProfiles = macherProjekte.extendedVorgangProfile;
                const projectsComments = projects.projectsComments;
                const projectsHistory = projects.projectsHistory;
                const projectsCustomerOffers = projects.projectsCustomerOffers;
                const projectsSDForms = projects.projectsSDForms;

                let vermittlungDTOProfile = null;

                if (
                    (namespaces.includes(PROFILE_NAMESPACE) || namespaces.includes(HISTORY_NAMESPACE)) &&
                    this.props.macherProjektId &&
                    this.props.id &&
                    macherProjektProfiles[this.props.macherProjektId]
                ) {
                    const { documentLinks: macherProjektDokumente = [], ...projektData } =
                        macherProjektProfiles[this.props.macherProjektId];

                    const vorgangData = projektData.vorgaenge.find(vorgang => vorgang.id === this.props.id);

                    const extendedVorgangData = macherProjektExtendedVorgangProfiles[this.props.id];

                    vermittlungDTOProfile = {
                        // TODO diskussion id aus macherProjekt ummappen zu macherProjektId und in vermittlungDTOProfile an componenten übergeben???
                        // wird vom Vorgang im Projekt überschrieben: id, version, transitions, createdAt, state, ...
                        ...projektData,
                        ...extendedVorgangData,
                        ...vorgangData,
                        macherProjektDokumente
                    };
                }

                const profile = vermittlungDTOProfile ? vermittlungDTOProfile : null;
                const macherProjekt = macherProjektProfiles[this.props.macherProjektId];

                const comments =
                    namespaces.includes(COMMENTS_NAMESPACE) && this.props.id && projectsComments[this.props.id]
                        ? projectsComments[this.props.id]
                        : null;
                const history =
                    namespaces.includes(HISTORY_NAMESPACE) &&
                    this.props.id &&
                    this.props.macherProjektId &&
                    projectsHistory[this.props.id]
                        ? WithProject.extendHistoryWithProjectFeedback(projectsHistory[this.props.id], profile)
                        : null;
                const customerOffers =
                    namespaces.includes(OFFER_NAMESPACE) && this.props.id && projectsCustomerOffers[this.props.id]
                        ? projectsCustomerOffers[this.props.id]
                        : undefined;

                const sdForms =
                    namespaces.includes(BILLING_NAMESPACE) && profile && projectsSDForms[profile.projectId]
                        ? projectsSDForms[profile.projectId]
                        : undefined;

                const actions = namespaces.includes(ACTIONS_NAMESPACE)
                    ? {
                          createSuccessor: this.createSuccessor,
                          setProjectOperator: this.setProjectOperator,
                          startProjectCompletion: this.startProjectCompletion,
                          setProjectCompleted: this.setProjectCompleted,
                          setProjectRejected: this.setProjectRejected,
                          setProjectRevoked: this.setProjectRevoked,
                          setProjectCancelled: this.setProjectCancelled,
                          setProjectReopen: this.setProjectReopen,
                          updateProjectProfileFormik: this.updateProjectProfileFormik,
                          updateMacherProjektProfile: this.updateMacherProjektProfile,
                          updateVorgangProfile: this.updateVorgangProfile,
                          startMatching: this.startMatching,
                          startCancelation: this.startCancelation,
                          startBilling: this.startBilling,
                          startRejection: this.startRejection,
                          startProjectRevocation: this.startProjectRevocation,
                          viewProject: this.viewProject,
                          viewCraftsman: this.viewCraftsman,
                          viewMailTemplates: this.viewMailTemplates,
                          deleteMacherProjektTaetigkeit: this.deleteMacherProjektTaetigkeit,

                          // currentCraftsman related actions
                          setRequestAccepted: this.setRequestAccepted,
                          setRequestDeclined: this.setRequestDeclined,
                          startRequestDeclinement: this.startRequestDeclinement,

                          // vor ort termin
                          vorOrtTerminAbstimmen: this.vorOrtTerminAbstimmen,
                          vorOrtTerminBestaetigen: this.vorOrtTerminBestaetigen,
                          vorOrtTerminLoeschen: this.vorOrtTerminLoeschen,

                          // baustellenStart termin
                          getExtendedVorgang: this.getExtendedVorgang,
                          baustellenStartTerminFestlegen: this.baustellenStartTerminFestlegen,
                          baustellenStartBestaetigen: this.baustellenStartBestaetigen,
                          baustellenStartTerminLoeschen: this.baustellenStartTerminLoeschen,

                          // Utility functions
                          resetFormMessage: this.resetFormMessage,

                          // comment functions
                          getComments: this.getComments,
                          addComment: this.addComment,
                          deleteComment: this.deleteComment,

                          // history
                          getHistory: this.getHistory,

                          // offers
                          getCustomerOffers: this.getCustomerOffers,
                          createCustomerOffer: this.createCustomerOffer,
                          createCustomerOfferNachtrag: this.createCustomerOfferNachtrag,
                          updateCustomerOffer: this.updateCustomerOffer,
                          deleteCustomerOffer: this.deleteCustomerOffer,
                          createCustomerOfferFromWT: this.createCustomerOfferFromWT,
                          dismissCustomerOffer: this.dismissCustomerOffer,
                          reactivateCustomerOffer: this.reactivateCustomerOffer,

                          // craftsmanAssignments
                          createCraftsmanAssignment: this.createCraftsmanAssignment,
                          deleteCraftsmanAssignment: this.deleteCraftsmanAssignment,
                          cancelCraftsmanAssignments: this.cancelCraftsmanAssignments,

                          // performanceRecords
                          updatePerformanceRecord: this.updatePerformanceRecord,

                          // SD Form
                          createSDForm: this.createSDForm,
                          cancelSDForm: this.cancelSDForm,
                          getSDForms: this.getSDForms,

                          // Pauschalen
                          getPauschalen: this.getPauschalen,
                          createPauschaleSDForm: this.createPauschaleSDForm,

                          // documents
                          openCraftsmanRequestDraft: this.openCraftsmanRequestDraft,
                          openCraftsmanRequestCoordinationDraft: this.openCraftsmanRequestCoordinationDraft,
                          openCustomerOfferDraft: this.openCustomerOfferDraft,
                          openCraftsmanAssignmentDraft: this.openCraftsmanAssignmentDraft,

                          createCraftsmanRequestDocument: this.createCraftsmanRequestDocument,
                          createCraftsmanRequestCoordinationDocument: this.createCraftsmanRequestCoordinationDocument,
                          createCustomerOfferDocument: this.createCustomerOfferDocument,
                          createCraftsmanAssignmentDocument: this.createCraftsmanAssignmentDocument,

                          openCraftsmanRequestDocument: this.openCraftsmanRequestDocument,
                          openCustomerOfferDocument: this.openCustomerOfferDocument,
                          openCraftsmanAssignmentDocument: this.openCraftsmanAssignmentDocument,

                          // Dokumente
                          uploadProjektDokument: this.uploadProjektDokument,
                          openProjektDokument: this.openProjektDokument,
                          updateProjektDokument: this.updateProjektDokument,
                          deleteProjektDokument: this.deleteProjektDokument,

                          // Transitions
                          getTransitions: this.getTransitions,
                          hasVorgangTransition: this.hasVorgangTransition,

                          createNotification: this.createNotification
                      }
                    : null;

                return (
                    <WrappedComponent
                        {...rest}
                        currentProject={{
                            profile,
                            macherProjekt,
                            comments,
                            actions,
                            history,
                            customerOffers,
                            sdForms,
                            transitions: this.state.transitions
                        }}
                        refreshProject={this.fetchData}
                        formMessage={this.state.formMessage}
                        formMessageRelation={this.state.formMessageRelation}
                    />
                );
            }
        }

        WithProject.displayName = `WithProject(${WrappedComponent.displayName || WrappedComponent.name})`;

        return WithProject;
    };
};
