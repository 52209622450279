import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import styles from "./StatsOverview.css";

export const StatsOverviewList = props => {
    return React.Children.map(props.children, child => <div className={styles.statsOverviewItem}>{child}</div>);
};

export const StatsOverviewLoading = React.memo(() => <LoadingIndicator className={styles.loading} />);

const StatsOverview = props => (
    <div className={styles.wrapper}>
        <h2 className={styles.headline}>{props.headline}</h2>

        <div className={styles.statsOverview}>{props.children}</div>
    </div>
);

StatsOverview.propTypes = {
    headline: PropTypes.string.isRequired,
    children: PropTypes.any
};

export default StatsOverview;
