import React, { Component } from "react";
import { Field, connect, getIn } from "formik";
import PropTypes from "prop-types";

@connect
export default class TransientField extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        resetValue: PropTypes.any,
        renderCondition: PropTypes.func
    };

    componentWillUnmount() {
        this.props.formik.setFieldValue(this.props.name, this.props.resetValue);
    }

    componentDidUpdate(prevProps) {
        const { formik, resetValue, renderCondition } = this.props;

        if (
            renderCondition &&
            !renderCondition(formik.values) &&
            resetValue !== getIn(formik.values, this.props.name)
        ) {
            formik.setFieldValue(this.props.name, resetValue);
        }
    }

    render() {
        const { formik, resetValue, renderCondition, ...restProps } = this.props;
        return renderCondition && !renderCondition(formik.values) ? null : <Field {...restProps} />;
    }
}
