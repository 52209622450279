const craftsmanActionMapping = {
    default: [
        {
            name: "viewCraftsman",
            disabled: false
        },
        {
            name: "contactCraftsman",
            disabled: false
        }
    ],
    active: [
        {
            name: "viewCraftsman",
            disabled: false
        },
        {
            name: "contactCraftsman",
            disabled: false
        }
    ],
    inactive: [
        {
            name: "viewCraftsman",
            disabled: false
        },
        {
            name: "contactCraftsman",
            disabled: false
        }
    ]
};

export const resolveAction = (actionName, status) => {
    status = craftsmanActionMapping.hasOwnProperty(status) ? status : "default";
    return craftsmanActionMapping[status].find(object => object.name === actionName);
};
