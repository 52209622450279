import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "../FormElements/FormElements";
import Icon from "../Icon/Icon";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import styles from "./SingleInput.css";

export default class SingleInput extends PureComponent {
    static defaultProps = {
        type: "text",
        label: "",
        placeholder: "",
        required: false,
        layout: "",
        autoComplete: "off"
    };

    static propTypes = {
        type: PropTypes.oneOf(["text", "number", "email", "password", "date"]).isRequired,
        label: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func,
        onKeyDown: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        layout: PropTypes.string,
        forwardRef: PropTypes.any,
        autoComplete: PropTypes.string,
        loading: PropTypes.bool
    };

    componentDidUpdate() {
        if (this.props.clearField) {
            this.props.clearField();
        }
    }

    render() {
        const { label, value } = this.props;

        const sharedAttributes = {
            className: classNames(styles.input, this.props.className, {
                [styles[this.props.split]]: this.props.split,
                [styles.hasError]: false,
                [styles.hasIconIndicator]: this.props.icon
            }),
            type: this.props.type,
            required: this.props.required,
            placeholder: this.props.placeholder,
            onKeyDown: this.props.onKeyDown,
            onBlur: this.props.onBlur,
            onFocus: this.props.onFocus,
            disabled: this.props.disabled,
            ref: this.props.forwardRef
        };

        const autocorrect = {
            spellCheck: "false",
            autoCorrect: "off",
            autoCapitalize: "off",
            autoComplete: this.props.autoComplete
        };

        return (
            <FormGroup hasLabel={!!label} className={styles[this.props.layout] || ""}>
                {label ? <FormLabel labelText={label} /> : null}

                <div className={styles.wrapper}>
                    <input
                        {...sharedAttributes}
                        {...autocorrect}
                        name={this.props.name}
                        onChange={this.props.onChange}
                        value={this.props.onChange ? (value !== undefined && value !== null ? value : "") : undefined}
                    />

                    {this.props.loading ? (
                        <LoadingIndicator variant={"onLight"} className={classNames(styles.indicator)} />
                    ) : this.props.icon ? (
                        <Icon name={this.props.icon} className={styles.icon} />
                    ) : null}
                </div>
            </FormGroup>
        );
    }
}
