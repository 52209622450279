import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { checkPermission } from "@lucify/auth";
import BusinessCard from "../../components/BusinessCard/BusinessCard";
import BusinessCardActionBar from "../../components/BusinessCard/BusinessCardActionbar";
import Button from "../../components/Button/Button";
import CommentForm from "../../components/Comment/CommentForm";
import CommentList from "../../components/Comment/CommentList";
import ContainerRow, { ContainerCol } from "../../components/Container/Grid";
import Can from "../../components/Feature/Can";
import Feature from "../../components/Feature/Feature";
import FormMessage from "../../components/FormElements/FormMessage";
import AutoSaveForm from "../../components/FormInput/Formik/AutoSaveForm";
import PKOSelect from "../../components/FormInput/Formik/PKOSelect";
import Reminder from "../../components/FormInput/Formik/Reminder";
import Cell from "../../components/Grid/Cell";
import Grid from "../../components/Grid/Grid";
import GridContainer from "../../components/Grid/GridContainer";
import Icon from "../../components/Icon/Icon";
import InfoTable from "../../components/InfoTable/InfoTable";
import { History } from "../../components/Profile/History";
import ShortInfo, {
    ShortInfoBody,
    ShortInfoHeadline,
    ShortInfoLoading,
    ShortInfoWrapper
} from "../../components/ShortInfo/ShortInfo";
import { InlineLink } from "../../components/Typo/Typo";
import { withProject } from "../../hoc/withProject";
import { restructuredBody, restructuredCraftsmanData } from "../../mappings/InfoTable/ProjectProfilInfoMapping";
import { projectProfileTags } from "../../mappings/TagList/projectProfile";
import { lucifyOIDCProvider } from "../../services/lucifyOIDCProvider";
import { renderGravatarSrcURL } from "../../utils/helpers/gravatar";
import AktuellerHandwerkerAktionen from "./Profil/Aktionen/AktuellerHandwerker";
import ProjektStatusAktionen from "./Profil/Aktionen/ProjektStatus";
import Notizen from "./Profil/Notizen";

const mapStateToProps = ({ breakpoint, ui }) => ({ breakpoint, ui });

@connect(mapStateToProps)
@withProject("profile", "actions", "comments", "history", "offers", "transitions")
export default class KurzProfil extends Component {
    render() {
        const { currentProject, formMessage, formMessageRelation, refreshProject, id } = this.props;

        const profile = currentProject.profile;
        const macherProjekt = currentProject.macherProjekt;
        const comments = currentProject.comments;
        const actions = currentProject.actions;
        const history = currentProject.history;
        const customerOffers = currentProject.customerOffers;
        const transitions = currentProject.transitions;

        const formMessageRetry = {
            component: Button,
            onClick: refreshProject,
            variant: "blank",
            label: "Nochmal versuchen"
        };

        const doubletActionObj = {
            component: InlineLink,
            label: "Zur möglichen Dublette",
            icon: {
                name: "IconOpenInNew"
            }
        };

        const PKO = profile?.projektKoordinator || {};

        const PKOAttributes =
            PKO.vorname || PKO.nachname
                ? {
                      firstName: PKO.vorname,
                      lastName: PKO.nachname,
                      image: {
                          src: renderGravatarSrcURL(PKO.email),
                          alt: ""
                      },
                      description: this.props.t("internals:vertriebsRegionen." + PKO.vertriebsRegion)
                  }
                : {
                      name: "Noch nicht zugewiesen"
                  };

        return !profile ? (
            formMessage && formMessageRelation === "project" ? (
                <ContainerRow layout={"profileError"}>
                    <FormMessage {...formMessage} action={formMessageRetry} />
                </ContainerRow>
            ) : (
                <ShortInfoLoading />
            )
        ) : (
            <React.Fragment>
                <ContainerRow layout={"profileShort"}>
                    <ContainerCol layout={"profileContent"}>
                        <ShortInfoWrapper layoutClass={"projectQuickview"}>
                            {profile.possibleDoubletProjectId && profile.status === "created" ? (
                                <FormMessage
                                    type={"warning"}
                                    iconName={"IconWarningRound"}
                                    message={"Achtung! Dublettengefahr."}
                                    action={{
                                        ...doubletActionObj,
                                        // to: "/projekt/" + profile.possibleDoublet
                                        to: {
                                            pathname: "/projekte",
                                            state: { search: { projectId: profile.possibleDoubletProjectId } }
                                        }
                                    }}
                                />
                            ) : null}

                            <ShortInfoHeadline headline={"Projektinformationen"} />
                            <ShortInfoBody tags={projectProfileTags(profile, this.props.t)}>
                                <InfoTable
                                    body={restructuredBody(profile, this.props.t)}
                                    mobile={this.props.breakpoint.device === "mobile"}
                                />
                            </ShortInfoBody>
                        </ShortInfoWrapper>
                    </ContainerCol>
                    <ContainerCol layout={"profileActions"}>
                        <ProjektStatusAktionen
                            projectId={this.props.id}
                            macherProjekt={macherProjekt}
                            profile={profile}
                            refreshProject={this.props.refreshProject}
                            formMessage={this.props.formMessage}
                            formMessageRelation={this.props.formMessageRelation}
                            isMatching={this.props.isMatching}
                            transitions={transitions}
                            {...currentProject.actions}
                        >
                            <Can perform={"projekt:emailvorlagen"}>
                                <Button variant={"secondary"} onClick={actions.viewMailTemplates}>
                                    E-Mail Vorlagen
                                </Button>
                            </Can>

                            <AutoSaveForm
                                onChange={values => actions.updateVorgangProfile(values, "updateReminder")}
                                initialValues={{
                                    reminder: profile.reminder
                                }}
                            >
                                {formMessage && formMessageRelation === "updateReminder" ? (
                                    <FormMessage {...formMessage} />
                                ) : null}
                                <Field
                                    name={"reminder"}
                                    component={Reminder}
                                    label={"Wiedervorlage"}
                                    disabled={!checkPermission(lucifyOIDCProvider, "projekt:bearbeiten")}
                                />
                            </AutoSaveForm>

                            <Feature flags={"canAssignPKO"}>
                                <BusinessCard {...PKOAttributes} role={"Projektkoordinator:"}>
                                    <BusinessCardActionBar
                                        actions={[
                                            {
                                                href: `tel:${PKO.telefon}`,
                                                iconName: "IconPhone",
                                                disabled: !PKO.telefon
                                            },
                                            {
                                                href: `mailto:${PKO.email}`,
                                                iconName: "IconMailOutline",
                                                disabled: !PKO.email
                                            },
                                            ...(checkPermission(lucifyOIDCProvider, "projekt:bearbeiten")
                                                ? [
                                                      {
                                                          iconName: "IconRepeat",
                                                          content: (
                                                              <AutoSaveForm
                                                                  onChange={actions.updateMacherProjektProfile}
                                                                  initialValues={{
                                                                      projektKoordinatorId: PKO.id
                                                                  }}
                                                              >
                                                                  <Field
                                                                      name={"projektKoordinatorId"}
                                                                      component={PKOSelect}
                                                                      autoFocus={true}
                                                                      layout={"noPadding"}
                                                                      openMenuOnFocus={true}
                                                                  />
                                                              </AutoSaveForm>
                                                          )
                                                      }
                                                  ]
                                                : [])
                                        ]}
                                    />
                                </BusinessCard>
                            </Feature>
                        </ProjektStatusAktionen>
                    </ContainerCol>
                </ContainerRow>

                {profile.currentRequest ? (
                    <ContainerRow layout={"profileShort"}>
                        <ContainerCol layout={"profileContent"}>
                            <ShortInfo
                                layoutClass={"projectQuickview"}
                                headline={"Handwerker"}
                                icon={profile.status === "craftsman_requested" ? <Icon name={"IconTimer"} /> : null}
                            >
                                <InfoTable
                                    body={restructuredCraftsmanData(
                                        {
                                            ...profile.currentRequest.craftsmanFacility,
                                            vorOrtTermin: undefined
                                        },
                                        this.props.t
                                    )}
                                    mobile={this.props.breakpoint.device === "mobile"}
                                />
                            </ShortInfo>
                        </ContainerCol>
                        <ContainerCol layout={"profileActions"}>
                            <AktuellerHandwerkerAktionen
                                formMessage={this.props.formMessage}
                                formMessageRelation={this.props.formMessageRelation}
                                contact={profile.contact}
                                status={profile.status}
                                vorgangPhase={profile.state}
                                vorgangStatus={profile.substate && profile.substate.state}
                                vorOrtTermin={profile.vorOrtTermin}
                                requestId={profile.currentRequest.id}
                                craftsmanId={profile.currentRequest.craftsmanFacility.id}
                                currentRequestStateChangedAt={profile.currentRequest.stateChangedAt}
                                vermittlungId={id}
                                transitions={transitions}
                                {...currentProject.actions}
                            />
                        </ContainerCol>
                    </ContainerRow>
                ) : null}

                {history && history.length ? (
                    <GridContainer layout={"borderTop"}>
                        <Grid marginX paddingX>
                            <Cell small={12}>
                                <ShortInfoHeadline headline={"Projekthistorie"} />
                                <History
                                    data={history}
                                    customerOffers={customerOffers}
                                    maxEntries={5}
                                    columnCount={2}
                                    openCraftsmanRequestDocument={actions.openCraftsmanRequestDocument}
                                    openCustomerOfferDocument={actions.openCustomerOfferDocument}
                                />
                            </Cell>
                        </Grid>
                    </GridContainer>
                ) : null}

                <Grid layout={"borderTopNoPadding"}>
                    <Cell small={12}>
                        <Notizen
                            id={profile.id}
                            headline={"Interne Notiz"}
                            name={"interneNotiz"}
                            placeholder={"Keine interne Notizen"}
                            profile={profile}
                            updateProjectProfile={actions.updateVorgangProfile}
                            formMessage={this.props.formMessage}
                            formMessageRelation={this.props.formMessageRelation}
                            resetFormMessage={actions.resetFormMessage}
                            container={false}
                        />
                    </Cell>
                </Grid>

                <GridContainer layout={"borderTop"}>
                    <Grid marginX paddingX>
                        <Cell small={12}>
                            <ShortInfoHeadline headline={"Kommentare"} />
                        </Cell>
                        <Cell small={12} medium={6}>
                            <CommentList comments={comments || []} actions={actions} />
                        </Cell>
                        <Cell small={12} medium={6}>
                            <CommentForm
                                addComment={actions.addComment}
                                formMessage={this.props.formMessage}
                                formMessageRelation={this.props.formMessageRelation}
                            />
                        </Cell>
                    </Grid>
                </GridContainer>
            </React.Fragment>
        );
    }
}
