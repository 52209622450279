import React from "react";
import styles from "./FormGroup.css";
import FormInputHint from "./FormInputHint";
import FormLabel from "./FormLabel";

interface FormGroupProps extends React.HTMLProps<HTMLDivElement> {
    label?: string;
    labelHint?: string;
    inputHint?: string;
}

const FormGroup: React.FC<FormGroupProps> = React.memo(({ label, labelHint, inputHint, children }) => (
    <div className={styles.formGroup}>
        {label ? <FormLabel labelHint={labelHint}>{label}</FormLabel> : null}
        {children}
        {inputHint ? <FormInputHint inputHint={inputHint} /> : null}
    </div>
));

FormGroup.displayName = "Form.Group";

export default FormGroup;
