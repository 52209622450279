import { VorgangState } from "@lucify/services/src/generated/macher-service.types";
import { CountryCode } from "@lucify/types";

export type VorgangLabel =
    | "Rekla vorhanden"
    | "Rekla abgeschlossen"
    | "Kleinstmangel vorhanden"
    | "Kleinstmangel abgeschlossen"
    | "Mangel aus Ware vorhanden"
    | "Mangel aus Ware abgeschlossen"
    | "Bedenkenanzeige an Kunden verschickt"
    | "Bedenkenanzeige unterzeichnet"
    | "Abnahmedokumente vollständig"
    | "Abnahmedokumente formell fehlerhaft"
    | "Abnahmedokumente inhaltlich fehlerhaft"
    | "Zahlungsverzug Anzahlungsrechnung"
    | "Mahnsperre"
    | "Inkasso";

interface StaticVorgangLabel {
    label: VorgangLabel;
    value: VorgangLabel;
    countries: CountryCode[];
}

export const staticVorgangLabels: StaticVorgangLabel[] = [
    {
        label: "Rekla vorhanden",
        value: "Rekla vorhanden",
        countries: [CountryCode.at, CountryCode.de]
    },
    {
        label: "Rekla abgeschlossen",
        value: "Rekla abgeschlossen",
        countries: [CountryCode.at, CountryCode.de]
    },
    {
        label: "Kleinstmangel vorhanden",
        value: "Kleinstmangel vorhanden",
        countries: [CountryCode.at, CountryCode.de]
    },
    {
        label: "Kleinstmangel abgeschlossen",
        value: "Kleinstmangel abgeschlossen",
        countries: [CountryCode.at, CountryCode.de]
    },
    {
        label: "Mangel aus Ware vorhanden",
        value: "Mangel aus Ware vorhanden",
        countries: [CountryCode.at, CountryCode.de]
    },
    {
        label: "Mangel aus Ware abgeschlossen",
        value: "Mangel aus Ware abgeschlossen",
        countries: [CountryCode.at, CountryCode.de]
    },
    {
        label: "Bedenkenanzeige an Kunden verschickt",
        value: "Bedenkenanzeige an Kunden verschickt",
        countries: [CountryCode.de]
    },
    {
        label: "Bedenkenanzeige unterzeichnet",
        value: "Bedenkenanzeige unterzeichnet",
        countries: [CountryCode.de]
    },
    {
        label: "Abnahmedokumente vollständig",
        value: "Abnahmedokumente vollständig",
        countries: [CountryCode.de]
    },
    {
        label: "Abnahmedokumente formell fehlerhaft",
        value: "Abnahmedokumente formell fehlerhaft",
        countries: [CountryCode.de]
    },
    {
        label: "Abnahmedokumente inhaltlich fehlerhaft",
        value: "Abnahmedokumente inhaltlich fehlerhaft",
        countries: [CountryCode.de]
    },
    {
        label: "Zahlungsverzug Anzahlungsrechnung",
        value: "Zahlungsverzug Anzahlungsrechnung",
        countries: [CountryCode.de]
    },
    {
        label: "Mahnsperre",
        value: "Mahnsperre",
        countries: [CountryCode.de]
    },
    {
        label: "Inkasso",
        value: "Inkasso",
        countries: [CountryCode.de]
    }
];

export const vorgangStandardNotizReklamation =
    "(1)Bearbeiter:\n" +
    "(2)Mangel 1: Start: TT.MM.JJJJ, Ende: TT.MM.JJJJ\n" +
    "(3)Fristen: nein\n" +
    "(4)Verweis auf Listen:\n" +
    "(5)Sonstige Infos:\n\n";

export function isBetreuung(phase: string | null): boolean {
    return phase === VorgangState.VertragsManagement || phase === VorgangState.Umsetzung;
}

export function isKoordination(phase: string | null): boolean {
    return phase === VorgangState.Koordination;
}
