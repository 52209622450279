import React, { ReactNode } from "react";
import { $TSFixMe } from "@lucify/types";
import { classNames } from "@lucify/utils";
import styles from "./FormCheckable.css";

interface FormCheckableProps {
    children?: ReactNode;
    component: $TSFixMe;
    onClick?: Function;
    disabled?: boolean;
}

const FormCheckable: React.FC<FormCheckableProps> = ({ component: Component, disabled, children, ...restProps }) => (
    <Component
        className={classNames(styles.checkable, {
            [styles.disabled]: disabled
        })}
        {...restProps}
    >
        {children}
    </Component>
);

FormCheckable.displayName = "Form.Checkable";

export default FormCheckable;
