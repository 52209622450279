import React, { ReactNode } from "react";
import Button, { ButtonProps } from "../Button/Button";
import Headline, { HeadlineProps } from "../Typo/Headline";
import styles from "./ContainerFlow.css";

interface ContainerFlowProps {
    children?: ReactNode;
    headline?: string | ReactNode;
    custom?: Partial<HeadlineProps>;
    mainAction?: ButtonProps | false;
    formActions?: ReactNode | false;
    showFooter?: boolean;
    repeatMainActionWhen?: boolean;
}

export const ContainerFlow: React.FC<ContainerFlowProps> = ({
    headline,
    custom,
    mainAction,
    formActions,
    showFooter,
    repeatMainActionWhen,
    children
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {typeof headline !== "string" ? (
                    headline
                ) : headline ? (
                    <Headline className={styles.headline} headline={headline} {...(custom ? custom : {})} />
                ) : null}
                {formActions ? <div className={styles.formActions}>{formActions}</div> : null}
                {mainAction ? (
                    <div className={styles.actionbox}>
                        <Button fullWidth small {...mainAction} />
                    </div>
                ) : null}
            </div>

            <div className={styles.body}>{children}</div>

            {mainAction ? (
                <div className={styles.actionboxMobile}>
                    <Button fullWidth small {...mainAction} />
                </div>
            ) : null}

            {showFooter ? (
                <div className={styles.footer}>
                    {formActions ? <div className={styles.formActions}>{formActions}</div> : null}
                    {repeatMainActionWhen && mainAction ? (
                        <div className={styles.actionbox}>
                            <Button fullWidth small {...mainAction} />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export const ContainerFlowFormActions = ({ children }: { children: ReactNode }) => {
    return children ? (
        <>
            {React.Children.map(children, actionItem =>
                actionItem ? <div className={styles.actionItem}>{actionItem}</div> : null
            )}
        </>
    ) : null;
};
