import React, { Component, ReactNode } from "react";
import classNames from "classnames";
import styles from "./ButtonGroup.css";

interface ButtonGroupProps {
    children?: ReactNode;
    unstack?: boolean;
    reverseStack?: boolean;
}

export class ButtonGroup extends Component<ButtonGroupProps> {
    render() {
        return (
            <div
                className={classNames(styles.group, {
                    [styles.unstack]: this.props.unstack,
                    [styles.reverseStack]: this.props.reverseStack
                })}
            >
                {React.Children.map(this.props.children, child => {
                    return (
                        <div
                            className={classNames(styles.groupItem, {
                                [styles.unstack]: this.props.unstack
                            })}
                        >
                            {child}
                        </div>
                    );
                })}
            </div>
        );
    }
}
