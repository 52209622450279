import {
    EigentumsVerhaeltnis,
    ProjectCategory,
    VertriebsRegion,
    WarenAnlieferung
} from "@lucify/services/src/generated/macher-service.types";
import { MarketType } from "@lucify/services/src/generated/worktask-service.types";
import { IconName } from "@lucify/ui";

export const projektKategorien = Object.values(ProjectCategory as unknown as string);
export const categoryIconMap: Record<ProjectCategory, IconName> = {
    GARTEN: "IconGarden",
    BAD: "IconBath",
    RAUM: "IconHouse",
    SONSTIGES: "IconBuild2",
    BAUELEMENTE: "IconBuildingComponents"
};
export const vertriebsRegionen = Object.values(VertriebsRegion as unknown as string);
export const marketType = Object.values(MarketType as unknown as string);
export const eigentumsVerhaeltnisse = Object.values(EigentumsVerhaeltnis as unknown as string);
export const warenAnlieferungsArten = Object.values(WarenAnlieferung as unknown as string);
export const booleanValues = [true, false];
export enum IssueType {
    craftsperson_general = "craftsperson_general",
    craftsperson_specialized = "craftsperson_specialized",
    call = "call",
    market = "market",
    direct_order = "direct_order"
}
