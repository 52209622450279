import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { getDistance } from "../../../utils/helpers/date";
import ResetDatePlugin from "../../../utils/plugins/flatpickr/ResetDatePlugin";
import styles from "../Reminder.css";
import Datepicker from "./Datepicker";

export default class Reminder extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        loading: PropTypes.bool,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        showDistance: true,
        pluginReset: true
    };

    render() {
        const { value } = this.props.field;
        const distance = this.props.showDistance ? getDistance(value) : null;

        const now = new Date();
        now.setHours(0, 0, 0, 0);

        const selectedDate = new Date(value);
        selectedDate.setHours(0, 0, 0, 0);

        return (
            <div className={styles.reminder}>
                <Datepicker
                    field={this.props.field}
                    form={this.props.form}
                    label={this.props.label}
                    disabled={this.props.disabled}
                    placeholder={this.props.disabled ? "Keine Wiedervorlage" : "Datum auswählen"}
                    onChange={this.props.onChange}
                    loading={this.props.loading}
                    options={{
                        enable: [
                            date => {
                                date.setHours(0, 0, 0, 0);
                                return date.getTime() === selectedDate.getTime() || date >= now;
                            }
                        ],
                        plugins: [...(this.props.pluginReset ? [new ResetDatePlugin()] : [])]
                    }}
                />
                {distance ? (
                    <div
                        className={classNames(styles.distance, {
                            [styles.past]: distance.inDays < 0,
                            [styles.today]: distance.inDays === 0
                        })}
                    >
                        {distance.inWords}
                    </div>
                ) : null}
            </div>
        );
    }
}
