import React, { Component } from "react";
import { autobind } from "core-decorators";
import { ActionBox, ActionBoxGroup } from "../../../components/ActionBox/ActionBox";
import Button from "../../../components/Button/Button";
import FormMessage from "../../../components/FormElements/FormMessage";
import { resolveAction } from "../../../mappings/CraftsmanActionMapping";

export default class CraftsmanActionBox extends Component {
    state = {
        submitting: false,
        action: null
    };

    renderAction(actionName, render) {
        const action = resolveAction(actionName, this.props.status);
        return action ? render(action) : null;
    }

    @autobind
    viewCraftsmanButton() {
        return this.renderAction("viewCraftsman", options => (
            <Button variant={"secondary"} onClick={this.props.viewCraftsman} disabled={options.disabled}>
                Zum Profil
            </Button>
        ));
    }

    @autobind
    contactCraftsmanButton() {
        return this.renderAction("contactCraftsman", options => (
            <Button variant={"secondary"} onClick={this.props.contactCraftsman} disabled={options.disabled}>
                <span>E-Mail</span>
            </Button>
        ));
    }

    render() {
        return (
            <ActionBox>
                <ActionBoxGroup>{this.props.requestCraftsmanButtonPlaceholder}</ActionBoxGroup>
                <ActionBoxGroup>
                    {!this.props.isDetail ? this.viewCraftsmanButton() : null}
                    {this.contactCraftsmanButton()}
                </ActionBoxGroup>

                {this.props.formMessage && this.props.formMessageRelation === "craftsman" ? (
                    <FormMessage relatesTo={"bottom"} {...this.props.formMessage} />
                ) : null}

                {this.props.children}
            </ActionBox>
        );
    }
}
