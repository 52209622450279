import React from "react";
import { classNames } from "@lucify/utils";
import { renderVariantClasses } from "../../utils/helpers/render";
import Icon from "../Icon/Icon";
import styles from "./DocumentTag.css";

export interface UIDocumentTagProps {
    tag: string;
    prefix?: UIDocumentTagPrefix;
    inList?: boolean;
    icon?: string;
    variant?: "blank" | "fill" | "outline";
}

export type UIDocumentTagPrefix = string;

const DocumentTag: React.FC<UIDocumentTagProps> = ({ tag, icon, variant = "fill", prefix = "#", inList = false }) => (
    <span
        className={classNames(styles.tag, renderVariantClasses(variant, styles), {
            [styles.inList]: inList,
            [styles.withIcon]: !!icon
        })}
    >
        {icon ? <Icon name={icon} width={20} height={20} className={styles.icon} /> : prefix}
        {tag}
    </span>
);

export default DocumentTag;
