import React, { PureComponent } from "react";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { getUserCountry } from "../../../utils/helpers/auth";
import CheckboxGroup from "./CheckboxGroup";

export default class DocumentTagSelect extends PureComponent {
    static defaultProps = {
        columns: 1,
        documentTags: []
    };

    static propTypes = {
        field: PropTypes.object.isRequired,
        columns: PropTypes.number,
        documentTags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                short: PropTypes.string,
                countries: PropTypes.arrayOf(PropTypes.string),
                userSelectable: PropTypes.bool
            })
        ).isRequired
    };

    @autobind
    handleChange(newOptions) {
        this.props.form.setFieldValue(this.props.field.name, this.transformToFormik(newOptions));
    }

    @autobind
    transformToFormik(options) {
        return options.filter(option => option.checked).map(option => option.name);
    }

    @autobind
    transformToCheckboxGroup() {
        const options = this.props.documentTags.filter(
            ({ countries, userSelectable }) => userSelectable && countries.includes(getUserCountry())
        );

        return options.map(({ name, short }) => ({
            name: name,
            label: `${name} (#${short})`,
            checked: Array.isArray(this.props.field.value) ? !!this.props.field.value.find(t => t === name) : false
        }));
    }

    render() {
        return (
            <CheckboxGroup
                columns={this.props.columns}
                options={this.transformToCheckboxGroup()}
                onChange={this.handleChange}
                field={this.props.field}
                form={this.props.form}
                paddingX={this.props.paddingX}
                marginX={this.props.marginX}
            />
        );
    }
}
