import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import foundation from "./Foundation.css";
import styles from "./Grid.css";

export default class GridContainer extends Component {
    static propTypes = {
        type: PropTypes.oneOf(["fluid", "full"]),
        layout: PropTypes.string
    };

    render() {
        return (
            <div
                className={classNames(foundation.gridContainer, {
                    [foundation[this.props.type]]: this.props.type,
                    [styles[this.props.layout]]: this.props.layout
                })}
            >
                {this.props.children}
            </div>
        );
    }
}
