import { AkquiseQuelle } from "@lucify/services/src/generated/craftsmen-service.types";

export enum CraftsmanErinnerungGrund {
    nachweisdokumenteNachfassen = "nachweisdokumenteNachfassen",
    vertragsdokumenteNachfassen = "vertragsdokumenteNachfassen",
    vertragArchivieren = "vertragArchivieren",
    kontaktAufnehmen = "kontaktAufnehmen",
    kontaktAufnehmenPAM = "kontaktAufnehmenPAM",
    sonstiges = "sonstiges",
    kontaktAufnehmenPIM = "kontaktAufnehmenPIM"
}

export const craftsmanRadius = [-1, 10, 25, 50, 75, 100, 150, 200, 250, 300];

export enum HandwerkerBewertungskriterium {
    freundlichkeit = "freundlichkeit",
    erreichbarkeit = "erreichbarkeit",
    puenktlichkeit = "puenktlichkeit",
    loyalitaetProzesstreue = "loyalitaetProzesstreue",
    arbeitsqualitaet = "arbeitsqualitaet",
    professionellesAuftreten = "professionellesAuftreten"
}

export enum HandwerkerBewertungskategorie {
    freundlichkeitUndVerlaesslichkeit = "freundlichkeitUndVerlaesslichkeit",
    geleisteteArbeitProfessionalitaet = "geleisteteArbeitProfessionalitaet"
}

export const HandwerkerBewertungskriteriumToHandwerkerBewertungskategorieMap = {
    [HandwerkerBewertungskriterium.freundlichkeit]: HandwerkerBewertungskategorie.freundlichkeitUndVerlaesslichkeit,
    [HandwerkerBewertungskriterium.erreichbarkeit]: HandwerkerBewertungskategorie.freundlichkeitUndVerlaesslichkeit,
    [HandwerkerBewertungskriterium.puenktlichkeit]: HandwerkerBewertungskategorie.freundlichkeitUndVerlaesslichkeit,
    [HandwerkerBewertungskriterium.loyalitaetProzesstreue]:
        HandwerkerBewertungskategorie.freundlichkeitUndVerlaesslichkeit,
    [HandwerkerBewertungskriterium.arbeitsqualitaet]: HandwerkerBewertungskategorie.geleisteteArbeitProfessionalitaet,
    [HandwerkerBewertungskriterium.professionellesAuftreten]:
        HandwerkerBewertungskategorie.geleisteteArbeitProfessionalitaet
};

export const AkquiseQuellen: AkquiseQuelle[] = [
    AkquiseQuelle.KONTAKTFORMULAR,
    AkquiseQuelle.EIGENBEWERBUNG_HANDWERKER,
    AkquiseQuelle.HKO,
    AkquiseQuelle.PKO,
    AkquiseQuelle.INNENDIENST,
    AkquiseQuelle.MARKT,
    AkquiseQuelle.DIENSTLEISTER,
    AkquiseQuelle.JACANDO,
    AkquiseQuelle.ORS,
    AkquiseQuelle.EIGENBEWERBUNG_INNENDIENST,
    AkquiseQuelle.PARTNER_ACCOUNT_MANAGEMENT,
    AkquiseQuelle.PARTNER_MANAGEMENT
];
