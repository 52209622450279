import React, { ReactNode } from "react";
import { Formik, FormikActions, FormikErrors } from "formik";
import { filterValuesByFieldsArray } from "@lucify/utils";
import { ActionBox, ActionBoxGroup } from "../../components/ActionBox/ActionBox";
import Button from "../../components/Button/Button";
import { ButtonGroup } from "../../components/Button/ButtonGroup";
import ContainerRow, { ContainerCol } from "../../components/Container/Grid";
import FormMessage from "../../components/FormElements/FormMessage";
import SyncErrors from "../../components/FormInput/Formik/SyncErrors";
import ShortInfo from "../../components/ShortInfo/ShortInfo";
import { withFormMessage } from "../../interfaces/Globals";

interface EditContainerFormProps<Entity> extends Partial<withFormMessage> {
    onReset: Function;
    fields: {
        name: string;
        label: string;
        validate?: Function;
        column: string[];
        component: ReactNode;
    }[];
    headline: string;
    onSubmit: (values: Partial<Entity>, formikActions: FormikActions<{}>) => void;
    breakpoint;
    profile: Entity;
    fieldsEntity: string;
    validate?: (values: Partial<Entity>) => void | object | Promise<FormikErrors<{}>>;
    container: boolean;
    layoutClass?: string;
}

const EditContainerForm = <Entity extends {}>({
    onReset,
    onSubmit,
    fields,
    formMessage,
    profile,
    validate,
    headline,
    children,
    layoutClass,
    container = true
}: React.PropsWithChildren<EditContainerFormProps<Entity>>) => (
    <Formik onSubmit={onSubmit} initialValues={filterValuesByFieldsArray(profile, fields)} validate={validate}>
        {({ handleSubmit, isSubmitting, resetForm, errors }) => (
            <form onSubmit={handleSubmit}>
                <ContainerRow layout={"profileShort"}>
                    <ContainerCol layout={"profileContent"}>
                        <ShortInfo headline={headline} container={container} layoutClass={layoutClass}>
                            <FormMessage {...formMessage} />
                            <SyncErrors errors={errors} fieldsEntity={"vermittlung"} />
                            {children}
                        </ShortInfo>
                    </ContainerCol>
                    <ContainerCol layout={"profileActions"}>
                        <ActionBox>
                            <ActionBoxGroup>
                                <ButtonGroup unstack={false}>
                                    <Button type={"submit"} disabled={isSubmitting} mini={true} fullWidth={true}>
                                        Speichern
                                    </Button>
                                    <Button
                                        type={"button"}
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm();
                                            onReset();
                                        }}
                                        variant={"secondary"}
                                        mini={true}
                                        fullWidth={true}
                                    >
                                        Abbrechen
                                    </Button>
                                </ButtonGroup>
                            </ActionBoxGroup>
                        </ActionBox>
                    </ContainerCol>
                </ContainerRow>
            </form>
        )}
    </Formik>
);

export default EditContainerForm;
