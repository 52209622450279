import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { getGenericMessage } from "@lucify/utils";
import { getMarkets } from "../../../store/actions/markets.actions";
import Button from "../../Button/Button";
import { FormFieldHint } from "../../FormElements/FormElements";
import FormMessage from "../../FormElements/FormMessage";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import styles from "../MarketSelect.css";
import Select from "./Select";

const mapDispatchToProps = dispatch => bindActionCreators({ getMarkets }, dispatch);
const mapStateToProps = ({ markets }) => ({ markets });

@connect(mapStateToProps, mapDispatchToProps)
export default class MarketSelect extends PureComponent {
    state = {
        options: []
    };

    static propTypes = {
        label: PropTypes.string,
        filterOption: PropTypes.func,
        field: PropTypes.object,
        form: PropTypes.object,
        isClearable: PropTypes.bool
    };

    static defaultProps = {
        isMulti: true,
        isClearable: false
    };

    componentDidMount() {
        if (!this.props.markets.list.length) {
            this.fetchData();
        } else {
            this.setState({
                options: this.props.markets.list.map(market => ({
                    label: market.description,
                    value: market.id
                }))
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.markets.list.length && !this.state.options.length) {
            this.setState({
                options: this.props.markets.list.map(market => ({
                    label: market.description,
                    value: market.id
                }))
            });
        }
    }

    @autobind
    async fetchData() {
        if (!this.props.markets.list.length) {
            this.props.getMarkets();
        }
    }

    @autobind
    filter({ label, value }, string) {
        if (this.props.filterOption && !this.props.filterOption(value)) {
            return false;
        }

        return !!~label.toLowerCase().search(
            string
                .replace(/[^a-zA-ZäÄöÖüÜß0-9\- ]/g, "")
                .toLowerCase()
                .split(" ")
                .map(str => `(?=.*${str})`)
                .join("")
        );
    }

    render() {
        const customError = {
            type: "error",
            message: "Beim Laden der Märkte ist ein Fehler aufgetreten."
        };

        const { field, form, label, isMulti, isClearable } = this.props;

        const formMessageRetry = {
            component: Button,
            onClick: this.fetchData,
            variant: "blank",
            label: "Nochmal versuchen"
        };

        return (
            <React.Fragment>
                {this.props.markets.failed ? (
                    <FormMessage
                        {...getGenericMessage({ status: this.props.markets.status }, { default: customError })}
                        action={formMessageRetry}
                    />
                ) : null}
                {this.state.options.length ? (
                    <Select
                        options={this.state.options}
                        filterOption={this.filter}
                        isSearchable
                        isMulti={isMulti}
                        isClearable={isClearable}
                        field={field}
                        form={form}
                        label={label}
                    />
                ) : (
                    <LoadingIndicator className={styles.loadingIndicatorWrapper} />
                )}

                <FormFieldHint hint={this.props.hint} />
            </React.Fragment>
        );
    }
}
