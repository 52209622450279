import { validate } from "uuid";

const statusLevel = (status = null) => {
    switch (status) {
        case "created":
            return "warning";

        case "operator_assigned":
            return "warning";

        case "craftsman_requested":
            return "warning";

        case "in_execution":
            return "warning";

        case "completed":
            return "positive";

        case "rejected":
            return "negative";

        case "cancelled":
            return "negative";

        default:
            return "neutral";
    }
};

export const projectProfileTags = (data, t) => {
    const { status, state, origin, projectId, categoryKey, fullState } = data;

    const inMigration = state === "InMigration";
    const projectIdIsUUID = validate(projectId);
    const vorgangStatus = (!inMigration && fullState) || undefined;

    return data
        ? [
              {
                  value: t("internals:categoryKeys." + categoryKey),
                  variant: categoryKey.toLowerCase()
              },
              {
                  value: t("internals:states." + status),
                  variant: ["semiBold", statusLevel(status)]
              },
              {
                  value: vorgangStatus && t("internals:fullState." + vorgangStatus),
                  variant: ["semiBold", "neutral"]
              },
              {
                  value: t("internals:origin." + origin),
                  variant: origin.toLowerCase()
              },
              {
                  label: "Projekt ID",
                  value: projectIdIsUUID ? "keine" : projectId,
                  variant: "neutral"
              },
              {
                  label: "Debitor ID",
                  value: data.customer.debitorId,
                  variant: "neutral"
              }
          ]
        : [];
};
