import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { VorOrtTerminTyp } from "@lucify/services/src/generated/macher-service.types";
import { $TSFixMe, IDateString } from "@lucify/types";
import { ActionBoxGroup } from "../../../../components/ActionBox/ActionBox";
import Button from "../../../../components/Button/Button";
import FormMessage from "../../../../components/FormElements/FormMessage";
import Checkbox from "../../../../components/FormInput/Formik/Checkbox";
import Datepicker from "../../../../components/FormInput/Formik/Datepicker";
import Select from "../../../../components/FormInput/Formik/Select";
import TransientField from "../../../../components/FormInput/Formik/TransientField";
import { withFormMessage } from "../../../../interfaces/Globals";
import { required } from "../../../../utils/validation/validators";

interface VorOrtTerminAbstimmenProps {
    abstimmen(data): Promise<$TSFixMe>;
    handwerkerZusageDatum: IDateString;
}

const VorOrtTerminAbstimmen: React.FC<VorOrtTerminAbstimmenProps & withFormMessage> = ({
    abstimmen,
    handwerkerZusageDatum,
    formMessage,
    formMessageRelation
}) => {
    const { t } = useTranslation();

    return (
        <Formik
            onSubmit={async (data, { setSubmitting }) => {
                try {
                    await abstimmen(data.vorOrtTermin);
                } catch (e) {
                    console.log(e);
                }
                setSubmitting(false);
            }}
            initialValues={{
                internal__ohneVorOrtTermin: false,
                vorOrtTermin: {
                    datum: null,
                    typ: "ERFORDERLICH"
                }
            }}
        >
            {({ values, setFieldValue, initialValues, isValid, isSubmitting, submitForm }) => (
                <Form>
                    {formMessage && formMessageRelation === "vorOrtTerminAbstimmen" ? (
                        <FormMessage relatesTo={"bottom"} {...formMessage} />
                    ) : null}

                    <ActionBoxGroup>
                        <Field
                            name={"vorOrtTermin.datum"}
                            component={Datepicker}
                            layout={"noPadding"}
                            label={t("internals:fields.vermittlung.vorOrtTermin.datum")}
                            disabled={values.internal__ohneVorOrtTermin}
                            validate={!values.internal__ohneVorOrtTermin ? required : undefined}
                            options={{
                                enable: [
                                    date => {
                                        date.setHours(0, 0, 0, 0);
                                        return date.getTime() >= new Date(handwerkerZusageDatum).setHours(0, 0, 0, 0);
                                    }
                                ]
                            }}
                        />

                        <Field
                            name={"internal__ohneVorOrtTermin"}
                            component={Checkbox}
                            onChange={event => {
                                if (event.currentTarget.checked) {
                                    setFieldValue("vorOrtTermin.datum", initialValues.vorOrtTermin.datum);
                                } else {
                                    setFieldValue("vorOrtTermin.typ", initialValues.vorOrtTermin.typ);
                                }
                            }}
                            label={"Ohne Vor Ort Termin fortfahren"}
                        />

                        <TransientField
                            name={"vorOrtTermin.typ"}
                            component={Select}
                            options={[
                                {
                                    label: t("internals:terminTypes.UNBEKANNT"),
                                    value: VorOrtTerminTyp.UNBEKANNT
                                },
                                {
                                    label: t("internals:terminTypes.NICHT_NOTWENDIG"),
                                    value: VorOrtTerminTyp.NICHT_NOTWENDIG
                                }
                            ]}
                            resetValue={initialValues.vorOrtTermin.typ}
                            renderCondition={values => values.internal__ohneVorOrtTermin}
                            validate={value =>
                                value && value !== VorOrtTerminTyp.ERFORDERLICH ? undefined : "VorOrtTypeInvalid"
                            }
                        />
                        <Button type={"submit"} disabled={!isValid || isSubmitting} loading={isSubmitting}>
                            Abgestimmt
                        </Button>
                    </ActionBoxGroup>
                </Form>
            )}
        </Formik>
    );
};

export default VorOrtTerminAbstimmen;
