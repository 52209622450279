const defaultConfig = {
    resetButtonText: "Datum zurücksetzen",
    resetButtonClass: "flatpickr-reset-button",
    resetContainerClass: "flatpickr-reset-container"
};

export default function ResetDatePlugin(pluginConfig = {}) {
    const config = { ...defaultConfig, ...pluginConfig };
    let resetContainer;
    let resetButton;

    return flatpickrInstance => {
        function showHideResetDatePlugin(flatpickrInstance, resetContainer) {
            if (flatpickrInstance.selectedDates.length) {
                resetContainer.classList.add("visible");
            } else {
                resetContainer.classList.remove("visible");
            }
        }

        return {
            onReady() {
                resetButton = flatpickrInstance._createElement(
                    "button",
                    config.resetButtonClass,
                    config.resetButtonText
                );
                resetButton.addEventListener("click", () => {
                    flatpickrInstance.clear();
                    flatpickrInstance.close();
                });

                resetContainer = flatpickrInstance._createElement("div", config.resetContainerClass);
                resetContainer.appendChild(resetButton);

                flatpickrInstance.calendarContainer.appendChild(resetContainer);
            },
            onOpen() {
                showHideResetDatePlugin(flatpickrInstance, resetContainer);
            },
            onChange() {
                showHideResetDatePlugin(flatpickrInstance, resetContainer);
            }
        };
    };
}
