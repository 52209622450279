import React, { ReactNode } from "react";
import DocumentTag, { UIDocumentTagPrefix, UIDocumentTagProps } from "./DocumentTag";
import styles from "./DocumentTag.css";

interface DocumentTagListProps {
    children?: ReactNode;
    tags?: UIDocumentTagProps[];
    prefix?: UIDocumentTagPrefix;
}

const DocumentTagList: React.FC<DocumentTagListProps> = React.memo(({ tags, prefix, children }) => {
    return (
        <div className={styles.list}>
            {tags
                ? tags.filter(tag => tag.tag).map((tag, i) => <DocumentTag key={i} inList {...tag} prefix={prefix} />)
                : children}
        </div>
    );
});

export default DocumentTagList;
