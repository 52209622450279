import React, { FormEvent, PureComponent } from "react";
import { FieldProps } from "formik";
import { classNames } from "@lucify/utils";
import styles from "../../FormElements/FormElementGroup.css";
import Radiobutton from "./Radiobutton";

type RadiobuttonGroupOption = {
    value: string;
    label: string;
    variant?: string;
};

interface RadiobuttonGroupProps {
    options: RadiobuttonGroupOption[];
    layout?: string;
    labelsTop?: boolean;
    onChange?: (event: FormEvent<HTMLInputElement>) => void;
}

export default class RadiobuttonGroup extends PureComponent<RadiobuttonGroupProps & FieldProps> {
    static defaultProps = {
        layout: "",
        labelsTop: false
    };

    render() {
        const { layout, options, field, labelsTop, onChange } = this.props;

        return (
            <div className={classNames(styles.group, { [styles[layout!]]: !!layout })}>
                {options.map(option => (
                    <div className={styles.groupItem} key={option.value}>
                        <Radiobutton
                            field={field}
                            variant={option.variant}
                            onChange={onChange}
                            value={option.value}
                            checked={option.value === field.value}
                            label={option.label}
                            labelTop={labelsTop}
                        />
                    </div>
                ))}
            </div>
        );
    }
}
