import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FormGroup } from "../FormElements/FormElements";
import styles from "./Textarea.css";

export default class FormFakeTextarea extends PureComponent {
    static defaultProps = {
        content: "",
        placeholder: "",
        readOnly: false,
        resize: "none",
        layout: ""
    };

    static propTypes = {
        readOnly: PropTypes.bool,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        placeholder: PropTypes.string,
        resize: PropTypes.oneOf(["none", "both", "horizontal", "vertical"]),
        layout: PropTypes.string
    };

    render() {
        const sharedAttributes = {
            className: classNames(styles.input, {
                [styles.disabled]: this.props.disabled,
                [styles[this.props.layout]]: this.props.layout,
                [styles[this.props.resize]]: this.props.resize
            }),
            disabled: this.props.disabled,
            placeholder: this.props.placeholder,
            readOnly: this.props.readOnly
        };

        return (
            <FormGroup>
                <textarea {...sharedAttributes} value={this.props.content === null ? "" : this.props.content} />
            </FormGroup>
        );
    }
}
