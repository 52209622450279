import React, { Fragment, ReactElement, ReactNode } from "react";
import { classNames } from "@lucify/utils";
import styles from "./ActionBar.css";

interface ActionBarProps {
    className?: string;
    children?: ReactNode;
    alignRight?: boolean;
    wrap?: boolean;
    wrapMobile?: boolean;
    layout?: string;
}

interface ActionBarItemProps {
    children: any;
    className?: string;
    layout?: string;
    mobileFullsize?: boolean;
}

export function ActionBar({ children, className, alignRight, wrap, wrapMobile, layout }: ActionBarProps) {
    const filteredChildren = React.Children.toArray(children).filter(child => child) as ReactElement[];

    return filteredChildren.length ? (
        <div
            className={classNames(styles.actionBar, className, {
                [styles.actionBarRight]: alignRight,
                [styles.actionBarWrap]: wrap,
                [styles.actionBarMobileWrap]: wrapMobile,
                [styles[layout!]]: !!layout
            })}
        >
            {React.Children.map(filteredChildren, (child, i) => {
                const isActionBarItem = typeof child.type === "function" && child.type === ActionBarItem;
                const childProps = child.props.actionBarProps || {};

                return isActionBarItem ? (
                    <Fragment key={i} children={child} />
                ) : (
                    <ActionBarItem key={i} children={child} {...childProps} />
                );
            })}
        </div>
    ) : null;
}

export const ActionBarItem = ({ mobileFullsize, children, layout, className }: ActionBarItemProps) => {
    return (
        <div
            className={classNames(styles.actionBarItem, className, {
                [styles[layout!]]: !!layout,
                [styles.actionBarItemMobileFullsize]: mobileFullsize
            })}
        >
            {children}
        </div>
    );
};
