import React, { Component } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import Button from "../../Button/Button";
import Cell from "../../Grid/Cell";
import Grid from "../../Grid/Grid";
import Icon from "../../Icon/Icon";
import Headline from "../../Typo/Headline";
import styles from "../Search.css";
import { SearchTextFilter } from "./SearchTextFilter";
import Select from "./Select";

export default class Search extends Component {
    state = {
        extended: this.props.extended,
        extendedFilter: [],
        sortParams: null,
        sharedOptions: {}
    };

    static propTypes = {
        breakpoint: PropTypes.object.isRequired,
        fetching: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
        extended: PropTypes.bool,
        extendedFilter: PropTypes.array,
        searchTextFilter: PropTypes.arrayOf(PropTypes.object),
        formMessage: PropTypes.element
    };

    static defaultProps = {
        extended: false,
        extendedFilter: [],
        searchTextFilter: []
    };

    componentDidMount() {
        this.setState({
            sortParams: this.props.initialParams || null
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { pathname: currentPathname } = this.props.location;
        const { pathname: prevPathname } = prevProps.location;

        if (currentPathname !== prevPathname) {
            this.reset();
        }
    }

    @autobind
    reset() {
        this.setState({
            sortParams: this.props.initialParams || null,
            sharedOptions: {},
            additionalColumn: null
        });
    }

    @autobind
    toggleFilter(extended) {
        this.setState({
            sortParams: this.props.initialParams,
            extended: !extended
        });

        if (this.props.onFilterToggle) {
            this.props.onFilterToggle(!extended);
        }
    }

    @autobind
    submitForm(values) {
        this.props.onSubmit({ ...values, ...this.state.sortParams }, this.state.additionalColumn);
    }

    getSubsetOfSharedOptions(options, sharedWith) {
        return sharedWith
            ? options.filter(option => !sharedWith.find(sharedOption => sharedOption === option.value))
            : options;
    }

    render() {
        const { extendedFilter, searchTextFilter, initialValues } = this.props;

        return (
            <Formik
                onSubmit={this.submitForm}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={{
                    ...initialValues
                }}
            >
                {({ values, resetForm, submitForm }) => (
                    <Form>
                        <SearchTextFilter sharedOptions={searchTextFilter} />

                        {extendedFilter.map(({ filterGroupTitle, filterGroupEntries }, index) => {
                            return (
                                <Grid
                                    key={index}
                                    paddingX
                                    className={classNames(styles.filterRow, styles.hiddenFilterRow, {
                                        [styles.showHiddenFilterRow]: this.state.extended,
                                        [styles.rowHasGroupTitle]: !!filterGroupTitle
                                    })}
                                >
                                    {filterGroupTitle ? (
                                        <Cell small={12}>
                                            <Headline className={styles.filterGroupTitle} headline={filterGroupTitle} />
                                        </Cell>
                                    ) : null}

                                    {filterGroupEntries.map(({ options, sharedWith, name, ...filterObject }, index) => {
                                        return (
                                            <Cell small={12} medium={6} key={name + index}>
                                                <Field
                                                    component={Select}
                                                    {...filterObject}
                                                    isSearchable={false}
                                                    name={name}
                                                    options={
                                                        sharedWith
                                                            ? this.getSubsetOfSharedOptions(options, values[sharedWith])
                                                            : options
                                                    }
                                                    onChange={selection => {
                                                        if (filterObject.updateParams) {
                                                            this.setState({
                                                                sortParams: selection.value
                                                                    ? filterObject.updateParams
                                                                    : this.props.initialParams
                                                            });
                                                        }

                                                        if (filterObject.additionalColumn) {
                                                            this.setState({
                                                                additionalColumn:
                                                                    (filterObject.isMulti && selection.length) ||
                                                                    selection.value
                                                                        ? filterObject.additionalColumn
                                                                        : null
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Cell>
                                        );
                                    })}
                                    {this.props.formMessage && (
                                        <Cell className={styles.formMessage} small={12}>
                                            {this.props.formMessage}
                                        </Cell>
                                    )}
                                </Grid>
                            );
                        })}

                        <Grid paddingX customClasses={["alignMiddle"]} className={styles.filterRow}>
                            <Cell small={12} medium={"Auto"}>
                                {extendedFilter.length ? (
                                    <button
                                        type={"button"}
                                        className={styles.toggleFilter}
                                        onClick={() => this.toggleFilter(this.state.extended)}
                                    >
                                        <span className={styles.toggleText}>
                                            {this.state.extended ? "Weniger" : "Mehr"} Suchoptionen
                                        </span>
                                        <Icon
                                            className={classNames(styles.toggleIcon, {
                                                [styles.toggleIconExtended]: this.state.extended
                                            })}
                                            name={"IconArrowDown"}
                                        />
                                    </button>
                                ) : null}
                            </Cell>
                            <Cell small={12} medium={"Shrink"}>
                                <Button
                                    variant={"inlineLink"}
                                    className={styles.reset}
                                    onClick={() => {
                                        resetForm({
                                            categoryKey: values.categoryKey,
                                            vertriebsRegion: values.vertriebsRegion,
                                            marketId: values.marketId
                                        });
                                        this.reset();
                                        submitForm();
                                    }}
                                >
                                    Zurücksetzen
                                </Button>
                                <Button type={"submit"} className={styles.submit} loading={this.props.fetching}>
                                    Suchen
                                </Button>
                            </Cell>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    }
}
