import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { autobind } from "core-decorators";
import { getGenericMessage } from "@lucify/utils";
import BusinessCard from "../../components/BusinessCard/BusinessCard";
import BusinessCardActionBar from "../../components/BusinessCard/BusinessCardActionbar";
import Button from "../../components/Button/Button";
import CommentForm from "../../components/Comment/CommentForm";
import CommentList from "../../components/Comment/CommentList";
import ContainerRow, { ContainerCol } from "../../components/Container/Grid";
import FormMessage from "../../components/FormElements/FormMessage";
import FormFakeTextarea from "../../components/FormInput/FormFakeTextarea";
import Cell from "../../components/Grid/Cell";
import Grid from "../../components/Grid/Grid";
import GridContainer from "../../components/Grid/GridContainer";
import InfoTable from "../../components/InfoTable/InfoTable";
import ShortInfo from "../../components/ShortInfo/ShortInfo";
import ShortInfoHeadline from "../../components/ShortInfo/ShortInfoHeadline";
import StatsItem from "../../components/StatsItem/StatsItem";
import StatsOverview, { StatsOverviewList, StatsOverviewLoading } from "../../components/StatsItem/StatsOverview";
import { withCraftsman } from "../../hoc/withCraftsman";
import { restructuredBody } from "../../mappings/InfoTable/craftsmanInfo";
import { craftsmanProfileTags } from "../../mappings/TagList/craftsmanProfile";
import { renderGravatarSrcURL } from "../../utils/helpers/gravatar";
import CraftsmanActionBox from "./Profile/CraftsmanActionBox";
import { CraftsmanErinnerungList } from "./Profile/CraftsmanErinnerungList";

const mapStateToProps = ({ breakpoint, ui }) => ({ breakpoint, ui });

@withTranslation("internals")
@connect(mapStateToProps)
@withCraftsman("profile", "actions", "stats", "comments")
export default class CraftsmanInfo extends PureComponent {
    @autobind
    craftsmanRequestBlock() {
        if (this.props.requestAction || this.props.requestMessage) {
            return (
                <React.Fragment>
                    {this.props.requestMessage || null}
                    {this.props.requestAction ? (
                        <Button
                            onClick={() => this.props.requestAction()}
                            icon={"IconMailOutline"}
                            loading={!!this.props.loading}
                            small
                            disabled={this.props.requestPending}
                        >
                            Handwerker anfragen
                        </Button>
                    ) : null}
                </React.Fragment>
            );
        }

        return null;
    }

    render() {
        const { currentCraftsman, refreshCraftsman } = this.props;
        const { profile, stats, meta, actions, comments } = currentCraftsman;
        const status = meta.stats.failed ? meta.stats.status : meta.profile.failed ? meta.profile.status : null;
        const message = status ? getGenericMessage({ status }) : null;

        const formMessageRetry = {
            component: Button,
            onClick: refreshCraftsman,
            variant: "blank",
            label: "Nochmal versuchen"
        };

        const HKO = currentCraftsman.profile?.handwerkerKoordinator || {};

        const HKOAttributes =
            HKO.vorname || HKO.nachname
                ? {
                      firstName: HKO.vorname,
                      lastName: HKO.nachname,
                      image: {
                          src: renderGravatarSrcURL(HKO.email),
                          alt: ""
                      },
                      description: this.props.t("internals:vertriebsRegionen." + HKO.vertriebsRegion)
                  }
                : {
                      name: "Region unbekannt"
                  };

        return meta.stats.failed || meta.profile.failed ? (
            <ContainerRow layout={"profileError"}>
                <FormMessage {...message} action={formMessageRetry} />
            </ContainerRow>
        ) : (
            <React.Fragment>
                <ContainerRow layout={"profileShort"}>
                    <ContainerCol layout={"profileContent"}>
                        <ShortInfo
                            headline={"Handwerker Kurzprofil"}
                            loading={!profile}
                            layoutClass={"craftsmanProfile"}
                            tags={craftsmanProfileTags(profile, this.props.t)}
                        >
                            {profile ? (
                                <InfoTable
                                    body={restructuredBody(profile, this.props.t)}
                                    mobile={this.props.breakpoint.device === "mobile"}
                                />
                            ) : null}
                        </ShortInfo>
                    </ContainerCol>
                    <ContainerCol layout={"profileActions"}>
                        {profile ? (
                            <CraftsmanActionBox
                                isDetail={false}
                                formMessage={this.props.formMessage}
                                formMessageRelation={this.props.formMessageRelation}
                                craftsmanId={this.props.craftsmanId}
                                status={profile.status}
                                requestCraftsmanButtonPlaceholder={this.craftsmanRequestBlock()}
                                {...actions}
                            >
                                {/* Erinnerungen */}
                                <CraftsmanErinnerungList
                                    profile={profile}
                                    formMessage={this.props.formMessage}
                                    formMessageRelation={this.props.formMessageRelation}
                                    updateCraftsmanProfile={actions.updateCraftsmanProfileFormik}
                                    resetFormMessage={actions.resetFormMessage}
                                    hideNewAction
                                />

                                {/* Handwerkerkoordinator */}
                                <BusinessCard {...HKOAttributes} role={"Handwerkerkoordinator:"}>
                                    {HKO.vorname || HKO.nachname ? (
                                        <BusinessCardActionBar
                                            actions={[
                                                {
                                                    href: `tel:${HKO.telefon}`,
                                                    iconName: "IconPhone",
                                                    disabled: !HKO.telefon
                                                },
                                                {
                                                    href: `mailto:${HKO.email}`,
                                                    iconName: "IconMailOutline",
                                                    disabled: !HKO.email
                                                }
                                            ]}
                                        />
                                    ) : null}
                                </BusinessCard>
                            </CraftsmanActionBox>
                        ) : null}
                    </ContainerCol>
                </ContainerRow>

                <ContainerRow renderCols={true} layout={"profileShort"}>
                    <StatsOverview headline={"Handwerkerstatistik"}>
                        {!stats ? (
                            <StatsOverviewLoading />
                        ) : (
                            <StatsOverviewList>
                                {/* <StatsItem label={'Interne Bewertung'} type={'StatTextRating'} value={profile ? profile.ratingInternal : null}/> */}
                                {/* <StatsItem label={'Kundenbewertung'} type={'StatFloatRating'} value={stats.ratingCustomer}/> */}
                                <StatsItem
                                    label={"Erstellt am"}
                                    type={"StatDate"}
                                    value={profile ? profile.created : null}
                                />
                                <StatsItem label={"Angenommen"} value={stats.acceptedRequestsCount} />
                                <StatsItem label={"Umgesetzt"} value={stats.completedVorgangCount} />
                                <StatsItem label={"Nicht umgesetzt"} value={stats.cancelledVorgangCount} />
                                <StatsItem label={"Abgelehnt"} value={stats.declinedRequestsCount} />
                                <StatsItem
                                    label={"Zuletzt angefragt"}
                                    type={"StatDate"}
                                    value={stats.lastRequestedDate}
                                />
                                {/* <StatsItem label={'Ausgelaufen'} value={stats.expiredRequestsCount}/> */}
                            </StatsOverviewList>
                        )}
                    </StatsOverview>

                    {profile ? (
                        <ShortInfo headline={"Notizen"} layoutClass={"profileNotes"}>
                            <FormFakeTextarea
                                readOnly={true}
                                layout={"notes"}
                                resize={"vertical"}
                                disabled={!profile.notes}
                                content={profile.notes}
                                placeholder={"Keine Notizen"}
                            />
                        </ShortInfo>
                    ) : null}
                </ContainerRow>
                {profile ? (
                    <GridContainer layout={"borderTop"}>
                        <Grid marginX paddingX>
                            <Cell small={12}>
                                <ShortInfoHeadline headline={"Kommentare"} />
                            </Cell>
                            <Cell small={12} medium={6}>
                                <CommentList comments={comments || []} actions={actions} />
                            </Cell>
                            <Cell small={12} medium={6}>
                                <CommentForm
                                    addComment={actions.addComment}
                                    formMessage={this.props.formMessage}
                                    formMessageRelation={this.props.formMessageRelation}
                                />
                            </Cell>
                        </Grid>
                    </GridContainer>
                ) : null}
            </React.Fragment>
        );
    }
}
