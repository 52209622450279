import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import helperStyles from "../../styles/helper.css";
import { BlockShortCut, ShortCut } from "../../utils/keymapManager";
import getBreakPoints from "../../utils/redux-breakpoint/breakpointCalculator";
import Icon from "../Icon/Icon";
import styles from "./Lightbox.css";

export default class Lightbox extends Component {
    static propTypes = {
        open: PropTypes.bool,
        title: PropTypes.string,
        fitToScreen: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        onUp: PropTypes.func,
        onDown: PropTypes.func,
        children: PropTypes.any.isRequired
    };

    static defaultProps = {
        open: false,
        title: "",
        fitToScreen: getBreakPoints().device === "mobile",
        onUp: () => {},
        onDown: () => {}
    };

    componentDidUpdate() {
        if (this.props.open) {
            document.body.classList.add(helperStyles.noScroll);
            document.documentElement.classList.add(helperStyles.noScroll);
        } else {
            document.body.classList.remove(helperStyles.noScroll);
            document.documentElement.classList.remove(helperStyles.noScroll);
        }
    }

    componentWillUnmount() {
        document.body.classList.remove(helperStyles.noScroll);
        document.documentElement.classList.remove(helperStyles.noScroll);
    }

    render() {
        const { open, title, onClose, onUp, onDown, children, fitToScreen } = this.props;
        return open ? (
            <div className={styles.wrapper}>
                <ShortCut name="lightbox.close" fn={onClose} />
                <ShortCut name="lightbox.up" fn={onUp} />
                <ShortCut name="lightbox.down" fn={onDown} />
                <BlockShortCut name="pagination.nextPage" />
                <BlockShortCut name="pagination.prevPage" />
                <div
                    className={classNames(styles.lightbox, {
                        [styles.fitToScreen]: fitToScreen
                    })}
                >
                    <div className={styles.titleBar}>
                        {title ? <span className={styles.title}>{title}</span> : null}
                        <button className={styles.closeButton} onClick={onClose}>
                            <Icon className={styles.closeIcon} name="IconClose" />
                        </button>
                    </div>
                    <div className={styles.content}>{children}</div>
                </div>
                <span onClick={onClose} className={classNames({ [styles.mask]: open })} />
            </div>
        ) : null;
    }
}
