export function formatCurrency(number: number | null | undefined, fallbackString: string = "-"): string {
    if (number !== null && number !== undefined) {
        return new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR"
        }).format(number);
    }

    return fallbackString;
}

export function roundTo(value: number, places: number): number {
    const power = Math.pow(10, places);
    return Number.isNaN(value) ? 0 : Math.round(value * power) / power;
}
