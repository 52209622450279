import React, { useState } from "react";
import { $TSFixMe } from "@lucify/types";
import { classNames } from "@lucify/utils";
import Icon from "../Icon/Icon";
import styles from "./BusinessCardActionbar.css";

export interface UIBusinessCardActionBarProps {
    actions: ActionBarItemProps[];
}

type ActionBarItemProps = {
    iconName: string;
    href?: string;
    content?: $TSFixMe;
    disabled?: boolean;
};

const BusinessCardActionBar: React.FC<UIBusinessCardActionBarProps> = ({ actions = [] }) => {
    const [isContentVisible, setContentVisibility] = useState(false);
    const [visibleItemIndex, setVisibleItemIndex] = useState();

    function toggleContent(index) {
        setVisibleItemIndex(index);
        setContentVisibility(index !== visibleItemIndex || !isContentVisible);
    }

    return (
        <div className={styles.actionbar}>
            <div className={styles.actionItems}>
                {actions.map(({ href, iconName, disabled, ...actionProps }, i) => {
                    return (
                        <div
                            key={i}
                            className={classNames(styles.actionbarItem, {
                                [styles.disabled]: disabled
                            })}
                        >
                            {href && disabled ? (
                                <Icon className={styles.icon} name={iconName} />
                            ) : href ? (
                                <a href={href} className={styles.link}>
                                    <Icon className={styles.icon} name={iconName} />
                                </a>
                            ) : (
                                <div className={styles.toggable} onClick={() => toggleContent(i)}>
                                    <Icon
                                        className={classNames(styles.icon, {
                                            [styles.iconActive]: visibleItemIndex === i && isContentVisible
                                        })}
                                        name={iconName}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            {isContentVisible && actions[visibleItemIndex!].content ? (
                <div className={styles.actionContent}>{actions[visibleItemIndex!].content}</div>
            ) : null}
        </div>
    );
};

BusinessCardActionBar.displayName = "BusinessCard.Actionbar";

export default BusinessCardActionBar;
