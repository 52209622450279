import { displayName } from "../../utils/helpers/render";

export const fallbackProjectMap = data => {
    const companyName = data.currentRequest ? data.currentRequest.craftsmanFacility.companyName : "-";

    return [
        [
            {
                label: "Bezeichung",
                text: data.projectDescription || "-",
                colSpan: 2
            }
        ],
        [
            {
                label: "Name Kunde",
                text: displayName(data.customer.firstName, data.customer.lastName)
            },
            {
                label: "Firma",
                text: companyName || "-"
            }
        ]
    ];
};
