import React, { AnchorHTMLAttributes, useState } from "react";
import { classNames } from "@lucify/utils";
import Icon from "../Icon/Icon";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { ButtonProps } from "./Button";
import styles from "./Button.css";

interface ButtonLinkProps
    extends AnchorHTMLAttributes<HTMLAnchorElement>,
        Pick<ButtonProps, "variant" | "disabled" | "fullWidth" | "className" | "loading" | "icon" | "hasIndicator"> {
    // custom stuff
}

export const ButtonLink: React.FC<ButtonLinkProps> = props => {
    const { href, variant, disabled, fullWidth, className, loading, icon, hasIndicator, children, ...rest } = props;
    const [hover, setHover] = useState(false);

    function setHoverState(event) {
        setHover(event.type === "mouseenter");
    }

    return (
        <a
            className={classNames(styles[variant!], className, {
                [styles.expand]: Boolean(fullWidth),
                [styles.disabled]: Boolean(disabled),
                [styles.hover]: Boolean(hover && !disabled),
                [styles.buttonHasIndicator]: Boolean(loading || icon || hasIndicator),
                [styles.iconOnly]: Boolean(icon && !children)
            })}
            onMouseEnter={setHoverState}
            onMouseLeave={setHoverState}
            href={href}
            {...rest}
        >
            {children}

            {loading ? (
                <LoadingIndicator
                    variant={
                        (hover && !(variant === "primaryInvert")) || (!hover && variant === "primaryInvert") || disabled
                            ? "onLight"
                            : "onDark"
                    }
                    className={classNames(styles.indicator)}
                />
            ) : icon ? (
                <Icon name={icon} className={styles.indicator} />
            ) : null}
        </a>
    );
};
