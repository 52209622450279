import { SchemaOf, array, bool, object, string } from "yup";
import { VorgangWorkTask } from "@lucify/services/src/generated/macher-service.types";
import { AssignedWorkTask, RecordedWorkTask } from "@lucify/services/src/generated/realization-service.types";
import { $TSFixMe, KeysOfType } from "@lucify/types";
import { formatCurrency, isEnhancedRecordedWorkTask, isNumber, roundTo, sortBy, uuid } from "@lucify/utils";
import { EnhancedWorkTask, NormalizedWorkTask, NormalizedWrapperWorkTask, WorkTaskCluster } from "./types";

export const DEFAULT_CLUSTER = "Weitere Leistungen";
export const DEFAULT_CLUSTER_UUID = uuid();
export const DEFAULT_CLUSTER_STANDALONE_DISPLAYNAME = "Alle Leistungen";
export const MAX_DESCRIPTION_LENGTH = 1500;

export function getClusterValueCounts<WorkTaskType extends { optional: boolean }>(
    clusterValues: WorkTaskType[]
): string {
    const optionalTasks = clusterValues.filter(value => value.optional).length;
    const requiredTasks = clusterValues.length - optionalTasks;

    return [requiredTasks, ...(optionalTasks ? ["+", optionalTasks] : [])].join("");
}

export function availableDescriptionLength<WorkTaskType extends { workTask: { description?: string } }>(
    wtEntry: WorkTaskType
) {
    return wtEntry.workTask.description
        ? MAX_DESCRIPTION_LENGTH - wtEntry.workTask.description.length
        : MAX_DESCRIPTION_LENGTH;
}

export function getListPrices(workTask: NormalizedWorkTask, priceCluster?: string) {
    return workTask.priceClusters?.find(cluster => priceCluster === cluster.clusterNumber);
}

export function sumWorkTask<WorkTaskType>(
    workTask: WorkTaskType,
    valueKey: KeysOfType<WorkTaskType, number | undefined>,
    amountKey: KeysOfType<WorkTaskType, number | undefined>
) {
    const value = (isNumber(workTask[valueKey]) ? workTask[valueKey] : 0) as number;
    const amount = (isNumber(workTask[amountKey]) ? workTask[amountKey] : 0) as number;
    return value * amount;
}

// export function sumWorkTasks<WorkTaskType extends EnhancedWorkTask<NormalizedWrapperWorkTask>>(
export function sumWorkTasks<WorkTaskType>(
    arrayOfWorkTasks: WorkTaskType[],
    valueKey: KeysOfType<WorkTaskType, number | undefined>,
    amountKey: KeysOfType<WorkTaskType, number | undefined>
) {
    return arrayOfWorkTasks.reduce(
        (acc, curr) =>
            (curr as unknown as EnhancedWorkTask<WorkTaskType>).deleted ||
            ((curr as unknown as NormalizedWrapperWorkTask).workTask &&
                !(curr as unknown as NormalizedWrapperWorkTask).workTask.articleNumber)
                ? acc
                : acc +
                  ((isNumber(curr[valueKey]) ? curr[valueKey] : 0) as number) *
                      ((isNumber(curr[amountKey]) ? curr[amountKey] : 0) as number),
        0
    );
}

export function sumClusteredWorkTasks<WorkTaskType>(
    arrayOfClusters: WorkTaskCluster<WorkTaskType>[],
    valueKey: KeysOfType<WorkTaskType, number | undefined>,
    amountKey: KeysOfType<WorkTaskType, number | undefined>
) {
    return arrayOfClusters.reduce((acc, cluster) => acc + sumWorkTasks(cluster.values, valueKey, amountKey), 0);
}

export function fixPriorityGaps<WorkTaskType extends { clusterName: string; priority: number }>(
    workTasks: WorkTaskType[]
) {
    const existingPriorities = workTasks
        .filter(workTask => workTask.clusterName)
        .reduce((acc: number[], curr: WorkTaskType) => {
            if (curr.priority && !acc.includes(curr.priority)) {
                acc.push(curr.priority);
            }

            return acc;
        }, [])
        .sort((a, b) => a - b);

    const transforms = existingPriorities.map((priority, index) => ({
        priority,
        nextPriority: index + 1
    }));

    return workTasks.map(workTask => {
        const transform = transforms.find(tf => tf.priority === workTask.priority);
        return {
            ...workTask,
            priority: workTask.clusterName && transform ? transform.nextPriority : 0 // workTask.priority
        };
    });
}

export function processDataForUpdate<WorkTaskType extends EnhancedWorkTask<NormalizedWrapperWorkTask>>(
    name: string,
    data: $TSFixMe,
    fixPriority = true
) {
    const workTaskClusters = data[name] as WorkTaskCluster<WorkTaskType>[];
    const normalizedWorkTasks = workTaskClusters.reduce((acc, { name, priority, values }) => {
        acc.push(
            ...values
                .filter(workTask => !workTask.deleted)
                .map(({ internal__uuid, internal__initialValue, deleted, ...workTask }) => ({
                    ...workTask,
                    priority,
                    clusterName: name === DEFAULT_CLUSTER ? "" : name
                }))
        );
        return acc;
    }, [] as NormalizedWrapperWorkTask[]);

    return {
        ...data,
        [name]: fixPriority ? fixPriorityGaps(normalizedWorkTasks) : normalizedWorkTasks
    };
}

export function preprocessData<WorkTaskType extends VorgangWorkTask | AssignedWorkTask | RecordedWorkTask>(
    workTasks: WorkTaskType[],
    additionalCluster?: WorkTaskCluster<EnhancedWorkTask<WorkTaskType>>
): WorkTaskCluster<EnhancedWorkTask<WorkTaskType>>[] {
    const defaultCluster: WorkTaskCluster<EnhancedWorkTask<WorkTaskType>> = {
        name: DEFAULT_CLUSTER,
        internal__uuid: DEFAULT_CLUSTER_UUID,
        priority: -Infinity,
        values: []
    };

    return sortBy<WorkTaskCluster<EnhancedWorkTask<WorkTaskType>>[]>(
        workTasks.reduce(
            (acc, { clusterName, priority, ...rest }) => {
                const cluster = acc.find(cluster =>
                    clusterName ? cluster.name === clusterName : cluster.name === DEFAULT_CLUSTER
                );

                const workTask: EnhancedWorkTask<WorkTaskType> = {
                    ...rest,
                    internal__uuid: uuid(),
                    internal__initialValue: rest
                };

                if (!cluster) {
                    acc.push({
                        name: clusterName,
                        internal__uuid: uuid(),
                        priority,
                        values: [workTask]
                    });
                } else if (cluster) {
                    cluster.values.push(workTask);

                    if (cluster.priority !== priority) {
                        cluster.hasPatchyPriority = true;
                        cluster.priority = cluster.priority < priority ? priority : cluster.priority;
                    }
                }

                return acc;
            },
            [defaultCluster, ...(additionalCluster ? [additionalCluster] : [])]
        ),
        "priority",
        "desc"
    );
}

export function enhanceWorkTask<WorkTaskType extends VorgangWorkTask | AssignedWorkTask | RecordedWorkTask>(
    workTask: NormalizedWorkTask,
    currentPriceCluster?: string,
    amountKey = "amount",
    craftsmanFacilityId?: string
) {
    const listPrices = getListPrices(workTask, currentPriceCluster);

    return {
        workTask,
        netPurchasePrice: listPrices ? listPrices.netPurchasePrice : undefined,
        netSellingPrice:
            listPrices && listPrices.netSellingPrice
                ? listPrices.netSellingPrice
                : // Set netPurchasePrice with profit margin if no netSellingPrice is defined
                listPrices && listPrices.netPurchasePrice
                ? roundTo(listPrices.netPurchasePrice * 1.1, 2)
                : 0,
        additionalDescription: "",
        [amountKey as any]: 1, // should be 1 to prevent issues with non-editing amount (e.g. unit "pauschal")
        optional: false,
        purchasePriceCluster: currentPriceCluster,
        internal__uuid: uuid(),
        craftsmanFacilityId
    } as unknown as EnhancedWorkTask<WorkTaskType>;
}

export function getLeistungTitel(workTask: Partial<Pick<NormalizedWorkTask, "category" | "trade">>): string | null {
    const { trade, category } = workTask;

    return trade && category
        ? category.includes(trade)
            ? category
            : trade.includes(category)
            ? trade
            : `${trade}, ${category}`
        : category
        ? category
        : trade || null;
}

export function getListPricesAndHints(
    staticWorktask: NormalizedWorkTask,
    currentPurchasePriceCluster?: string,
    currentNetPurchasePrice?: number
) {
    // TODO purchasePriceCluster bleibt bestehen oder wird entfernt, ggf mit Backend quatschen?
    const purchasePriceCluster = currentPurchasePriceCluster;
    const { netPurchasePrice, netSellingPrice } = getListPrices(staticWorktask, purchasePriceCluster) || {};
    const netPurchasePriceHint = netPurchasePrice
        ? "Listenpreis " + purchasePriceCluster + ": " + formatCurrency(netPurchasePrice)
        : undefined;
    const netSellingPriceHint = netSellingPrice
        ? "Richtpreis " + purchasePriceCluster
        : staticWorktask.netSellingPriceEditable
        ? "10% Marge: " + formatCurrency(roundTo((currentNetPurchasePrice ?? 0) * 1.1, 2))
        : "inkl. 10% Marge";

    return {
        netPurchasePrice,
        netSellingPrice,
        netPurchasePriceHint,
        netSellingPriceHint
    };
}

export function getClusterTotals(clusters: WorkTaskCluster<EnhancedWorkTask<NormalizedWrapperWorkTask>>[]) {
    const netEkSum = sumClusteredWorkTasks(clusters, "netPurchasePrice", "amount");
    const netVkSum = sumClusteredWorkTasks(clusters, "netSellingPrice", "amount");
    const diff = netVkSum - netEkSum;
    const marge = roundTo((diff * 100) / netEkSum, 2);

    return {
        diff,
        marge,
        netEkSum,
        netVkSum
    };
}

export function getClusterTotalsComparison(clusters: WorkTaskCluster<EnhancedWorkTask<RecordedWorkTask>>[]) {
    const nettoTargetVK = sumClusteredWorkTasks(clusters, "netSellingPrice", "targetAmount");
    const nettoActualVK = sumClusteredWorkTasks(clusters, "netSellingPrice", "actualAmount");
    const nettoTargetEK = sumClusteredWorkTasks(clusters, "netPurchasePrice", "targetAmount");
    const nettoActualEK = sumClusteredWorkTasks(clusters, "netPurchasePrice", "actualAmount");
    const bruttoTargetEK = nettoTargetEK * 1.19;
    const bruttoActualEK = nettoActualEK * 1.19;

    const threshold = 15;
    const diff = (nettoActualVK * 100) / nettoTargetVK - 100;
    const deviation = `${diff > 0 ? "+" : ""}${roundTo(diff, 0)}`;
    const deviationAlert = diff >= threshold;

    return {
        nettoTargetEK,
        bruttoTargetEK,
        nettoTargetVK,
        nettoActualEK,
        bruttoActualEK,
        nettoActualVK,
        deviation,
        deviationAlert,
        diff
    };
}

export function getLeistungTotals(
    leistung: EnhancedWorkTask<NormalizedWrapperWorkTask> | EnhancedWorkTask<RecordedWorkTask>
) {
    if (isEnhancedRecordedWorkTask(leistung)) {
        return {
            netEkSum: sumWorkTask(leistung, "netPurchasePrice", "actualAmount"),
            netVkSum: sumWorkTask(leistung, "netSellingPrice", "actualAmount")
        };
    }

    return {
        netEkSum: sumWorkTask(leistung, "netPurchasePrice", "amount"),
        netVkSum: sumWorkTask(leistung, "netSellingPrice", "amount")
    };
}

export type clusterOfWorktasksValidationSchemaType = {
    deleted?: boolean;
    workTask: { description?: string };
    values: {
        additionalDescription?: string;
    }[];
}[];

export const clusterOfWorktasksValidationSchema: SchemaOf<clusterOfWorktasksValidationSchemaType> = array().of(
    object().shape({
        deleted: bool(),
        workTask: object().shape({
            description: string()
        }),
        values: array().of(
            object().shape({
                additionalDescription: string().when(["workTask", "deleted"], {
                    is: (workTask: NormalizedWorkTask, deleted: boolean) => workTask.description?.length || deleted,
                    then: string().optional(),
                    otherwise: string()
                        .matches(/.*[^ ].*/g)
                        .required()
                })
            })
        )
    })
);

export function getSequenceNumber(
    workTaskClusters: WorkTaskCluster<EnhancedWorkTask<NormalizedWrapperWorkTask>>[],
    workTaskUUID: string
) {
    return (
        workTaskClusters
            .map(({ values }) => values.map(({ internal__uuid }) => internal__uuid))
            .flat()
            .indexOf(workTaskUUID) + 1
    );
}

export const clusterNameExists =
    (clusters: WorkTaskCluster<EnhancedWorkTask<VorgangWorkTask>>[], id?: string) => (value: string) => {
        const clusterExists = !!clusters.find(
            cluster => cluster.name.toLowerCase().trim() === value.toLowerCase().trim() && id !== cluster.internal__uuid
        );
        return clusterExists ? "DuplicateClusterName" : undefined;
    };
