import React, { PureComponent } from "react";
import classNames from "classnames";
import { formatDate } from "../../utils/helpers/date";
import styles from "./StatsItem.css";

export const StatFloatRating = React.memo(({ value }) => (
    <div className={classNames(styles.rating, { [styles.disabled]: !value })}>
        <span>{value ? value.toString().replace(".", ",") : "0,0"}</span>
    </div>
));

export const StatTextRating = React.memo(({ value }) => (
    <div
        className={classNames(styles.rating, styles.ratingText, {
            [styles.disabled]: !value
        })}
    >
        <span>{value || "keine"}</span>
    </div>
));

export class StatDate extends PureComponent {
    render() {
        const { value } = this.props;
        const isDefined = !!value;

        return (
            <div
                className={classNames(styles.date, {
                    [styles.disabled]: !value
                })}
            >
                <div className={styles.year}>
                    {isDefined
                        ? formatDate(value, {
                              year: "numeric",
                              day: undefined,
                              month: undefined
                          })
                        : "——"}
                </div>
                <div className={styles.day}>
                    {isDefined
                        ? formatDate(value, {
                              day: "2-digit",
                              year: undefined,
                              month: undefined
                          })
                        : "00"}
                </div>
                <div className={styles.month}>
                    {isDefined
                        ? formatDate(value, {
                              year: undefined,
                              day: undefined,
                              month: "short"
                          })
                        : "———"}
                </div>
            </div>
        );
    }
}

export class StatCount extends PureComponent {
    render() {
        const { value } = this.props;
        return (
            <div
                className={classNames(styles.count, {
                    [styles.disabled]: !value
                })}
            >
                <span>{value || 0}</span>
            </div>
        );
    }
}
