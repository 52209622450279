import { DokumentenTag } from "./dokumente";

export const documentTagsMacherProjekt: DokumentenTag[] = [
    {
        name: "Unterschriebene EWE",
        short: "EWE",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Planung",
        short: "PLA",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Materialliste",
        short: "MAT",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Unterschriebene Angebotspauschale",
        short: "APU",
        countries: ["DE"],
        userSelectable: true
    }
];
