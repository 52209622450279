import React, { PureComponent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { noop } from "../../utils/helpers/render";
import Icon from "../Icon/Icon";
import styles from "./Checkbox.css";

export default class Checkbox extends PureComponent {
    input = React.createRef();

    static defaultProps = {
        disabled: false,
        label: "Checkbox Label"
    };

    static propTypes = {
        name: PropTypes.string,
        disabled: PropTypes.bool,
        label: PropTypes.string,
        defaultChecked: PropTypes.bool,
        onChange: PropTypes.func
    };

    @autobind
    onClick(e) {
        e.preventDefault();
        this.input.current.checked = !this.input.current.checked;

        if (this.props.onChange) {
            this.props.onChange(this.input.current.checked);
        }
    }

    render() {
        return (
            <label
                className={classNames(styles.wrapper, {
                    [styles.disabled]: this.props.disabled
                })}
                onClick={this.onClick}
            >
                <input
                    ref={this.input}
                    type="checkbox"
                    className={styles.checkbox}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    onChange={noop}
                    checked={this.props.defaultChecked}
                />
                <div className={styles.fakeCheckbox} />
                <Icon className={styles.iconCheck} name={"IconCheck"} />
                <div className={styles.labelText}>{this.props.label}</div>
            </label>
        );
    }
}
