import React, { useState } from "react";
import { Media } from "react-breakpoints";
import { useTranslation } from "react-i18next";
import { HandwerkerBewertung as HandwerkerBewertungType } from "@lucify/services/src/generated/craftsmen-service.types";
import { displayDateTime, getDistance } from "../../utils/helpers/date";
import { displayName, getChunkedArrayByCount } from "../../utils/helpers/render";
import Can from "../Feature/Can";
import Cell from "../Grid/Cell";
import Grid from "../Grid/Grid";
import styles from "./Bewertung.css";
import Score from "./Score";

interface HandwerkerBewertungProps extends HandwerkerBewertungType {
    deleteBewertung(id: string): Promise<void>;
}

const HandwerkerBewertung: React.FC<HandwerkerBewertungProps> = props => {
    const { id, createdAt, bewerter, kommentar, bewertungen, deleteBewertung } = props;
    const { t } = useTranslation();
    const { inDays } = getDistance(createdAt);
    const dateTime = displayDateTime(createdAt, (inDays || 0) >= -1);
    const nameDisplay = displayName(bewerter.vorname, bewerter.nachname);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const scores = bewertungen.map((bewertung, i) =>
        bewertung ? (
            <div key={i} className={styles.score}>
                {t(`bewertungen:handwerkerbewertung.bewertungskriterien.${bewertung.kriteriumName}`)}{" "}
                <Score value={bewertung.score} />
            </div>
        ) : null
    );

    const columnsToDisplay = 2;

    function handleConfirmation() {
        setShowConfirmation(showConfirmation => !showConfirmation);
    }

    return (
        <div className={styles.bewertungsbogen}>
            <div className={styles.author}>{`${nameDisplay}, ${dateTime || "-"}`}</div>
            <div className={styles.scoreWrapper}>
                <Grid marginX customClasses={["alignJustify"]}>
                    <Media>
                        {({ currentBreakpoint, breakpoints }) =>
                            getChunkedArrayByCount(scores, columnsToDisplay).map((columnEntries, i) => (
                                <Cell
                                    key={i}
                                    small={12}
                                    medium={12 / columnsToDisplay}
                                    className={
                                        breakpoints[currentBreakpoint] >= breakpoints.tablet
                                            ? styles.customColumn
                                            : null
                                    }
                                >
                                    {columnEntries}
                                </Cell>
                            ))
                        }
                    </Media>
                </Grid>
            </div>
            {kommentar ? <p className={styles.kommentar}>{kommentar}</p> : null}
            <Can perform={"handwerker:bewertungen:loeschen"}>
                {showConfirmation ? (
                    <div className={styles.confirmation}>
                        <span className={styles.confirmationText}>Willst du diese Bewertung wirklich löschen?</span>
                        <div className={styles.actions}>
                            <button className={styles.link} onClick={handleConfirmation}>
                                Abbrechen
                            </button>
                            <button
                                className={styles.link}
                                onClick={() => {
                                    deleteBewertung(id);
                                }}
                            >
                                Bestätigen
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.actions}>
                        <button className={styles.link} onClick={handleConfirmation}>
                            Löschen
                        </button>
                    </div>
                )}
            </Can>
            <div />
        </div>
    );
};

export default HandwerkerBewertung;
