import React, { useState } from "react";
import { Translation } from "react-i18next";
import { RestSuccessResponse } from "@lucify/services";
import { $TSFixMe } from "@lucify/types";
import { ActionBoxGroup } from "../../../../components/ActionBox/ActionBox";
import LongHoldButton from "../../../../components/Button/LongHoldButton";
import { FormFakeInput } from "../../../../components/FormElements/FormElements";
import FormMessage from "../../../../components/FormElements/FormMessage";
import LinkIcon from "../../../../components/LinkIcon/LinkIcon";
import { withFormMessage } from "../../../../interfaces/Globals";
import { displayDateTime } from "../../../../utils/helpers/date";

interface BaustellenStartTerminBestaetigungProps {
    baustellenStartBestaetigen(): Promise<RestSuccessResponse<unknown>>;

    baustellenStartTerminLoeschen(): Promise<RestSuccessResponse<unknown>>;

    refreshProject(leaveFormMessages?: boolean): void;

    profile: $TSFixMe;
}

export const BaustellenStartTerminBestaetigung: React.FC<BaustellenStartTerminBestaetigungProps & withFormMessage> =
    props => {
        const {
            profile: { baustellenStartTermin }
        } = props;
        const [isSubmittingToService, setSubmittingToService] = useState(false);

        async function loescheTermin() {
            setSubmittingToService(true);

            try {
                await props.baustellenStartTerminLoeschen();
            } catch (e) {
                console.log(e);
                setSubmittingToService(false);
            } finally {
                await props.refreshProject();
            }
        }

        async function bestaetigeTermin() {
            setSubmittingToService(true);
            try {
                await props.baustellenStartBestaetigen();
            } catch (e) {
                console.log(e);
                setSubmittingToService(false);
            } finally {
                await props.refreshProject(true);
            }
        }

        return (
            <Translation>
                {t => (
                    <>
                        {props.formMessage &&
                        (props.formMessageRelation === "baustellenStartTermin" ||
                            props.formMessageRelation === "extendedVorgang") ? (
                            <FormMessage relatesTo={"bottom"} {...props.formMessage} />
                        ) : null}
                        <ActionBoxGroup label={"Baustellenstart"}>
                            <FormFakeInput
                                label={""}
                                disabled
                                layout={"noPadding"}
                                value={
                                    baustellenStartTermin?.datum
                                        ? displayDateTime(baustellenStartTermin.datum, false)
                                        : baustellenStartTermin?.typ
                                        ? t(`internals:terminTypes.${baustellenStartTermin.typ}`)
                                        : "\u00A0"
                                }
                            />
                            <LinkIcon
                                customClass={"smallText"}
                                onClick={loescheTermin}
                                type={"button"}
                                justify={"center"}
                                disabled={isSubmittingToService}
                            >
                                Termin zurücksetzen
                            </LinkIcon>
                            <LongHoldButton onLongHold={bestaetigeTermin} disabled={isSubmittingToService}>
                                Bestätigt
                            </LongHoldButton>
                        </ActionBoxGroup>
                    </>
                )}
            </Translation>
        );
    };
