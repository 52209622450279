import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { calcContainerWidth } from "../../utils/helpers/render";
import styles from "./InfoTable.css";

export default class InfoTable extends PureComponent {
    static defaultProps = {
        body: [
            [
                {
                    label: "Titel / Label",
                    text: "Bezeichnung über zwei Spalten, wird abgeschnitten wenn es nicht mehr passt",
                    colSpan: 2
                }
            ],
            [
                {
                    label: "Label",
                    text: "Text"
                },
                {
                    label: "Bezeichnung Label",
                    text: "Bezeichnung Text"
                }
            ]
        ],
        mobile: false
    };

    static propTypes = {
        body: PropTypes.arrayOf(PropTypes.array).isRequired,
        mobile: PropTypes.bool,
        actions: PropTypes.arrayOf(PropTypes.element),
        variant: PropTypes.string
    };

    render() {
        return (
            <table
                className={classNames(this.props.mobile ? styles.infoTableMobile : styles.infoTable, {
                    [styles[this.props.variant]]: this.props.variant
                })}
            >
                <tbody>
                    {this.props.body.map((row, index) => (
                        <tr key={index}>
                            {row.map((info, index) => (
                                <td
                                    key={index}
                                    className={classNames(styles.infoCell, {
                                        [styles.hasActionInside]: info.actions
                                    })}
                                    colSpan={info.colSpan}
                                    style={{
                                        paddingRight: info.actions ? calcContainerWidth(info.actions.length) : null
                                    }}
                                >
                                    <div className={styles.infoLabel}>{info.label}</div>
                                    <div
                                        className={classNames(styles.infoText, {
                                            [styles.hasHint]: info.hint,
                                            [styles.noEllipsis]: info.noEllipsis
                                        })}
                                    >
                                        {info.text}
                                        {info.hint ? <div className={styles.infoHint}>{info.hint}</div> : null}
                                    </div>
                                    {info.actions ? <div className={styles.infoActions}>{info.actions}</div> : null}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}
