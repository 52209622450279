import React from "react";
import { Media } from "react-breakpoints";
import { useTranslation } from "react-i18next";
import { CraftsmanFacility } from "@lucify/services/src/generated/craftsmen-service.types";
import InfoTable from "../../components/InfoTable/InfoTable";
import { displayName } from "../../utils/helpers/render";

type HandwerkerKurzInfoProps = Pick<CraftsmanFacility, "facilityName" | "contact" | "qualifications">;

const HandwerkerKurzInfo = ({ facilityName, contact, qualifications }: HandwerkerKurzInfoProps) => {
    const { t } = useTranslation();

    return (
        <Media>
            {({ currentBreakpoint, breakpoints }) => (
                <InfoTable
                    body={[
                        [
                            {
                                label: "Firma",
                                text: facilityName,
                                colSpan: 1
                            },
                            {
                                label: t("internals:labels.craftsman.contact.name"),
                                text: contact
                                    ? displayName(contact.firstName, contact.lastName, contact.salutation)
                                    : "-"
                            }
                        ],

                        [
                            {
                                label: "Qualifikationen",
                                text:
                                    qualifications && qualifications.length
                                        ? qualifications.map(obj => obj.name).join(", ")
                                        : "-",
                                colSpan: 2,
                                noEllipsis: true
                            }
                        ]
                    ]}
                    mobile={breakpoints[currentBreakpoint] < breakpoints.tablet}
                />
            )}
        </Media>
    );
};
export default HandwerkerKurzInfo;
