import React, { Component } from "react";
import classNames from "classnames";
import { Form } from "formik";
import styles from "./InfoEdit.css";

export class InfoEditForm extends Component {
    render() {
        const { fullWidth, ...rest } = this.props;

        return (
            <Form
                {...rest}
                className={classNames(styles.editForm, {
                    [styles.fullWidth]: fullWidth
                })}
            >
                {this.props.children}
            </Form>
        );
    }
}
