import React, { PureComponent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { getChunkedArrayByCount } from "../../../utils/helpers/render";
import { FormGroup } from "../../FormElements/FormElements";
import Cell from "../../Grid/Cell";
import Grid from "../../Grid/Grid";
import Icon from "../../Icon/Icon";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import styles from "../Checkbox.css";

export default class CheckboxGroup extends PureComponent {
    static defaultProps = {
        disabled: false,
        label: "Checkbox Label",
        required: false,
        columns: 2,
        paddingX: true
    };

    static propTypes = {
        onChange: PropTypes.func,
        columns: PropTypes.number,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                disabled: PropTypes.bool,
                checked: PropTypes.bool
            })
        ).isRequired,
        marginX: PropTypes.bool,
        paddingX: PropTypes.bool
    };

    @autobind
    onChange(event) {
        const changedOption = this.props.options.find(option => option.name === event.currentTarget.name);

        this.props.onChange([
            ...this.props.options.filter(option => option !== changedOption),
            {
                ...changedOption,
                checked: event.currentTarget.checked
            }
        ]);
    }

    render() {
        const { options, loading, columns } = this.props;
        const customColumnsArray = getChunkedArrayByCount(options, columns);
        const smallColumns = 12 % columns ? 12 : 12 / columns;

        return loading ? (
            <LoadingIndicator />
        ) : (
            <Grid marginX={this.props.marginX} paddingX={this.props.paddingX}>
                {customColumnsArray.map((options, i) => (
                    <Cell key={i} small={smallColumns}>
                        <FormGroup>
                            {options.map(option => (
                                <label
                                    key={option.name}
                                    data-cy={this.props.field.name + "_" + option.name}
                                    className={classNames(styles.wrapper, styles.groupItem, {
                                        [styles.disabled]: option.disabled
                                    })}
                                >
                                    <input
                                        type="checkbox"
                                        className={classNames(styles.checkbox)}
                                        name={option.name}
                                        defaultChecked={option.checked}
                                        onChange={this.onChange}
                                    />
                                    <div className={styles.fakeCheckbox} />
                                    <Icon className={styles.iconCheck} name={"IconCheck"} />
                                    <div className={styles.labelText}>{option.label}</div>
                                </label>
                            ))}
                        </FormGroup>
                    </Cell>
                ))}
            </Grid>
        );
    }
}
