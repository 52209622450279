export enum AllowedFileTypes {
    pdf = "application/pdf",
    rtf = "application/rtf,text/rtf",
    msword = "application/msword",
    openDocument = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    msexcel = "application/vnd.ms-excel",
    openSheet = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    jpeg = "image/jpeg",
    png = "image/png",
    svg = "image/svg+xml",
    gif = "image/gif",
    plain = "text/plain",
    mp4 = "video/mp4",
    mov = "video/quicktime"
}

export const allAllowedFileTypes = Object.values(AllowedFileTypes);

export function isAllowedFileType(file: File) {
    return allAllowedFileTypes.some(type => file.type.includes(type as AllowedFileTypes));
}

export interface DokumentenTag {
    name: string;
    short: string;
    countries: Array<"DE" | "AT">;
    userSelectable: boolean;
}
