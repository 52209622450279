import React, { ReactElement, ReactNode } from "react";
import { classNames } from "@lucify/utils";
import styles from "./Erinnerungen.css";

interface ErinnerungenGruppeProps {
    children?: ReactNode;
    bordered?: boolean;
}

const ErinnerungenGruppe: React.FC<ErinnerungenGruppeProps> = ({ children, bordered = false }) => {
    const filteredChildren = React.Children.toArray(children).filter(child => child) as ReactElement[];
    return filteredChildren.length ? (
        <div className={classNames(styles.gruppe, { [styles.gruppeBordered]: bordered })}>{children}</div>
    ) : null;
};

export default ErinnerungenGruppe;
