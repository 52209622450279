import React, { Component } from "react";
import { autobind } from "core-decorators";
import { checkPermission } from "@lucify/auth";
import { VermittlungStatus } from "@lucify/services/src/generated/macher-service.types";
import { ActionBox, ActionBoxGroup } from "../../../../components/ActionBox/ActionBox";
import Button from "../../../../components/Button/Button";
import FormMessage from "../../../../components/FormElements/FormMessage";
import { resolveAction, resolveVorgangAction } from "../../../../mappings/ProjectActionMapping";
import { lucifyOIDCProvider } from "../../../../services/lucifyOIDCProvider";
import VorOrtTerminAbstimmen from "./VorOrtTerminAbstimmen";
import VorOrtTerminBestaetigung from "./VorOrtTerminBestaetigung";

export default class AktuellerHandwerkerAktionen extends Component {
    state = {
        submitting: false,
        action: null
    };

    renderAction(actionName, render) {
        const action = resolveAction(actionName, this.props.status);
        return (action && !action.needs) || (action && checkPermission(lucifyOIDCProvider, action.needs))
            ? render(action)
            : null;
    }

    renderSubstateAction(actionName, render) {
        const action = resolveVorgangAction(actionName, this.props.vorgangStatus, this.props.vorgangPhase);
        return action ? render(action) : null;
    }

    renderTransitionAction(actionName, render) {
        // TODO: Sind Transitions für die keine Berechtigungen vorhanden sind in der Liste enthalten?
        return checkPermission(lucifyOIDCProvider, "projekt:bearbeiten") && this.props.hasVorgangTransition(actionName)
            ? render()
            : null;
    }

    @autobind
    viewCraftsmanButton() {
        return this.renderAction("viewCraftsman", options => (
            <Button
                variant={"secondary"}
                onClick={() => this.props.viewCraftsman(this.props.craftsmanId)}
                disabled={options.disabled}
            >
                Zum Profil
            </Button>
        ));
    }

    @autobind
    craftsmenOperations() {
        return this.renderAction("craftsmanOperations", options => (
            <React.Fragment>
                {!(this.props.contact && this.props.contact.email) ? (
                    <FormMessage
                        type={"info"}
                        message={
                            "Markt kann nicht über eine Zusage informiert werden, da keine E-Mail Adresse hinterlegt ist."
                        }
                    />
                ) : null}
                <ActionBoxGroup
                    label={"Antwort des Handwerkers:"}
                    hint={"Sofern E-Mail Adresse vorhanden, werden bei Zusage Kunde und Markt per E-Mail informiert."}
                    layout={"boxed"}
                >
                    <Button
                        mini={true}
                        loading={this.state.submitting && this.state.action === "accept"}
                        disabled={this.state.submitting}
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                submitting: true,
                                action: "accept"
                            });

                            this.props.setRequestAccepted(this.props.requestId, this.props.vermittlungId);
                        }}
                    >
                        Zusage
                    </Button>

                    <Button
                        mini={true}
                        loading={this.state.submitting && this.state.action === "decline"}
                        disabled={this.state.submitting}
                        onClick={() => {
                            this.setState({ ...this.state, submitting: false });
                            this.props.startRequestDeclinement(this.props.requestId);
                        }}
                        variant={"secondary"}
                    >
                        Absage
                    </Button>
                </ActionBoxGroup>
            </React.Fragment>
        ));
    }

    @autobind
    startRevocationCraftsmanButton() {
        const isRevocationBetreuungDisallowed =
            !this.props.hasVorgangTransition("zurueckziehen") && !this.props.hasVorgangTransition("entziehen");

        return this.props.status === VermittlungStatus.CraftsmanRequested ||
            this.props.status === VermittlungStatus.InExecution ? (
            <>
                <Button
                    variant={"secondary"}
                    onClick={this.props.startProjectRevocation}
                    disabled={isRevocationBetreuungDisallowed}
                >
                    Vermittlung entziehen
                </Button>
                {isRevocationBetreuungDisallowed ? (
                    <FormMessage
                        type={"info"}
                        message={
                            'Kommt es zu einem HW-Wechsel? Vorgang abbrechen und einen neuen Vorgang ("Neue Vermittlung") zum Matchen eines neuen HW starten'
                        }
                    />
                ) : null}
            </>
        ) : null;
    }

    vorortTerminAbstimmung() {
        return this.renderTransitionAction("vorOrtTerminAbstimmen", () => (
            <VorOrtTerminAbstimmen
                abstimmen={this.props.vorOrtTerminAbstimmen}
                handwerkerZusageDatum={this.props.currentRequestStateChangedAt}
            />
        ));
    }

    vorortTerminBestaetigung() {
        return this.renderTransitionAction("vorOrtTerminBestaetigen", () => (
            <VorOrtTerminBestaetigung
                vorOrtTermin={this.props.vorOrtTermin}
                vorOrtTerminLoeschen={this.props.vorOrtTerminLoeschen}
                vorOrtTerminBestaetigen={this.props.vorOrtTerminBestaetigen}
            />
        ));
    }

    @autobind
    async submit(data, { setSubmitting }) {
        try {
            await this.props.createProject(data);
            this.props.createNotification({
                type: "success",
                message: this.props.t("messages:success.transition", {
                    newState: this.props.t("internals:states.created")
                })
            });
            this.props.history.push("/projekte/created");
        } catch (e) {
            console.error(e);
            this.setErrorMessage(e.response, "createProject");
        } finally {
            setSubmitting(false);
        }
    }

    render() {
        return (
            <ActionBox>
                <ActionBoxGroup>
                    {!this.props.isDetail ? this.viewCraftsmanButton() : null}
                    {/*{this.startRevocationCraftsmanButton()}*/}
                </ActionBoxGroup>

                {this.props.formMessage && this.props.formMessageRelation === "vorOrtTermin" ? (
                    <FormMessage relatesTo={"bottom"} {...this.props.formMessage} />
                ) : null}

                {/*<ActionBoxGroup>{this.vorortTerminAbstimmung()}</ActionBoxGroup>*/}
                {/*{this.vorortTerminBestaetigung()}*/}

                {this.props.formMessage && this.props.formMessageRelation === "craftsman" ? (
                    <FormMessage relatesTo={"bottom"} {...this.props.formMessage} />
                ) : null}

                {/*{this.craftsmenOperations()}*/}

                {this.props.children}
            </ActionBox>
        );
    }
}
