import { DokumentenTag } from "./dokumente";

export const documentTagsVorgang: DokumentenTag[] = [
    {
        name: "Aufmaß",
        short: "AUF",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Baustellenfoto",
        short: "BFO",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Warenauftrag",
        short: "WA",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Handwerkeranfrage",
        short: "HA",
        countries: ["DE"],
        userSelectable: false
    },
    {
        name: "Bestätigte Handwerkeranfrage",
        short: "HAU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Dienstleistungsangebot",
        short: "DA",
        countries: ["DE"],
        userSelectable: false
    },
    {
        name: "Unterschriebener Warenauftrag",
        short: "WAU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Dienstleistungvertrag",
        short: "DV",
        countries: ["DE"],
        userSelectable: false
    },
    {
        name: "Unterschriebener Dienstleistungsvertrag",
        short: "DVU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Handwerkerbeauftragung",
        short: "HB",
        countries: ["DE"],
        userSelectable: false
    },
    {
        name: "Beidseitig unterschriebenes Vertragsdokument",
        short: "VBU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Regiebericht",
        short: "RB",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Warenreklamation",
        short: "WRK",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Dienstleistungsreklamation",
        short: "DRK",
        countries: ["DE", "AT"],
        userSelectable: true
    },
    {
        name: "Bestätigte Handwerkerbeauftragung",
        short: "HBU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Baurechtliches Dokument",
        short: "BRD",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Bedenkenanzeige",
        short: "BA",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Unterschriebene Bedenkenanzeige",
        short: "BAU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Unterschriebener Leistungsnachweis",
        short: "LNU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Unterschriebenes Abnahmeprotokoll",
        short: "ABU",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Handwerkerrechnung",
        short: "HWR",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Kundenrechnung",
        short: "KUR",
        countries: ["DE"],
        userSelectable: true
    },
    {
        name: "Korrespondenz",
        short: "KOR",
        countries: ["DE", "AT"],
        userSelectable: true
    }
];
