import React, { ReactElement } from "react";
import { WithTranslation } from "react-i18next";
import classNames from "classnames";
import Icon from "../Icon/Icon";
import Popover from "../Tooltip/Popover";
import styles from "./Indicator.css";

interface IndicatorFeedbackProps {
    feedback: {
        feedbackChoices: {
            question: string;
            answer: string;
            id: string;
        }[];
        type: string;
    };
    className: string;
}

const IndicatorFeedback: React.FC<IndicatorFeedbackProps & Omit<WithTranslation, "i18n" | "tReady">> = React.memo(
    props => {
        const { feedbackChoices, type } = props.feedback;

        // list of questions where translation(answer) is available
        const fbAnswerWhitelist = ["reason", "deviatedEstimate", "heardAboutReason"];

        const mixedAnswer = ["otherReason", "reasonForDeviation", "heardAboutOtherReason"];

        const fbBlacklist = [
            "reason.other",
            "deviatedEstimate.yes",
            "heardAboutReason.other"
            // ,
            // 'ratingMarketConsulting'
        ];

        const headline = `Feedback${
            type.includes(".customer")
                ? ": Kunde"
                : type.includes(".craftsman")
                ? ": Handwerker"
                : type.includes(".operator")
                ? ": Innendienst"
                : ""
        }`;
        const renderedFeedback = feedbackChoices.length
            ? feedbackChoices.reduce((arr: ReactElement[], { question, answer, id }) => {
                  const q = props.t(`feedbacks:${type}.questions.${question}`);
                  let a = answer;

                  if (fbBlacklist.includes(`${question}.${answer}`) || fbBlacklist.includes(question)) {
                      return arr;
                  }

                  if (mixedAnswer.includes(question)) {
                      a = props.t(`feedbacks:${type}.answers.${question}`, {
                          answer: answer
                      });
                  } else if (question.toLowerCase().includes("rating")) {
                      a = props.t(`feedbacks:rating`, { answer });
                  } else if (fbAnswerWhitelist.includes(question)) {
                      a = props.t(`feedbacks:${type}.answers.${question}.${answer}`);
                  }

                  arr.push(
                      <div key={id} className={styles.fbWrap}>
                          <div className={styles.fbQuestion}>{q}</div>
                          <div className={styles.fbAnswer}>{a}</div>
                      </div>
                  );

                  return arr;
              }, [])
            : "Es wurde kein Feedback abgegeben";

        return (
            <Popover className={classNames(props.className)} headline={headline} text={renderedFeedback}>
                <Icon width={18} height={18} name={"IconTextsmsOutline"} />
            </Popover>
        );
    }
);

export default IndicatorFeedback;
