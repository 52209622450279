import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { Field } from "formik";
import { filterValuesByFieldsArray } from "@lucify/utils";
import AutoSaveForm from "../../../../components/FormInput/Formik/AutoSaveForm";
import Select from "../../../../components/FormInput/Formik/Select";
import { required } from "../../../../utils/validation/validators";

@withTranslation(["internals"])
export default class EditProjectTypeForm extends PureComponent {
    render() {
        const typeOptions = [
            {
                label: this.props.t("internals:operationRoles.VERMITTLUNG"),
                value: "VERMITTLUNG"
            }
        ];

        return (
            <AutoSaveForm
                onChange={this.props.onSubmit}
                initialValues={filterValuesByFieldsArray(this.props.profile, ["type"])}
                validateOnChange
            >
                {({ isSubmitting }) => (
                    <Field
                        component={Select}
                        name={"type"}
                        label={this.props.t("internals:fields.vermittlung.type")}
                        isLoading={isSubmitting}
                        validate={required}
                        options={typeOptions}
                        showInlineError
                    />
                )}
            </AutoSaveForm>
        );
    }
}
