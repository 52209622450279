import React from "react";
import styles from "./FormInputHint.css";

interface FormInputHintProps extends React.HTMLProps<HTMLDivElement> {
    inputHint?: string;
}

const FormInputHint: React.FC<FormInputHintProps> = React.memo(({ inputHint }) => (
    <div className={styles.hint}>{inputHint}</div>
));

FormInputHint.displayName = "Form.InputHint";

export default FormInputHint;
