export const staticProjectLabels = [
    {
        label: "Rekla vorhanden",
        value: "Rekla vorhanden"
    },
    {
        label: "Rekla abgeschlossen",
        value: "Rekla abgeschlossen"
    },
    {
        label: "Kleinstmangel vorhanden",
        value: "Kleinstmangel vorhanden"
    },
    {
        label: "Kleinstmangel abgeschlossen",
        value: "Kleinstmangel abgeschlossen"
    },
    {
        label: "Mangel aus Ware vorhanden",
        value: "Mangel aus Ware vorhanden"
    },
    {
        label: "Mangel aus Ware abgeschlossen",
        value: "Mangel aus Ware abgeschlossen"
    },
    {
        label: "Bedenkenanzeige an Kunden verschickt",
        value: "Bedenkenanzeige an Kunden verschickt"
    },
    {
        label: "Bedenkenanzeige unterzeichnet",
        value: "Bedenkenanzeige unterzeichnet"
    },
    {
        label: "Abnahmedokumente vollständig",
        value: "Abnahmedokumente vollständig"
    },
    {
        label: "Abnahmedokumente formell fehlerhaft",
        value: "Abnahmedokumente formell fehlerhaft"
    },
    {
        label: "Abnahmedokumente inhaltlich fehlerhaft",
        value: "Abnahmedokumente inhaltlich fehlerhaft"
    },
    {
        label: "Zahlungsverzug Anzahlungsrechnung",
        value: "Zahlungsverzug Anzahlungsrechnung"
    },
    {
        label: "Mahnsperre",
        value: "Mahnsperre"
    },
    {
        label: "Inkasso",
        value: "Inkasso"
    }
];
