import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { renderVariantClasses } from "../../utils/helpers/render";
import styles from "./TagList.css";

export default class Tag extends PureComponent {
    static defaultProps = {
        variant: "neutral"
    };

    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.string.isRequired,
        variant: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.oneOfType([
                    PropTypes.oneOf(["neutral", "positive", "negative", "warning", "primary"]),
                    PropTypes.string
                ])
            ),
            PropTypes.string
        ]),
        compact: PropTypes.bool,
        inline: PropTypes.bool
    };

    render() {
        const { variant, compact, label, value } = this.props;

        return (
            <div
                className={classNames(styles.tag, renderVariantClasses(variant, styles), {
                    [styles.compact]: compact,
                    [styles.inline]: compact
                })}
            >
                {label ? `${label}: ${value || "-"}` : value}
            </div>
        );
    }
}
