import React, { ButtonHTMLAttributes, FC, SyntheticEvent, useRef, useState } from "react";
import { useEffect } from "react";
import classNames from "classnames";
import Icon from "../Icon/Icon";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import styles from "./Button.css";

export type LongHoldButtonVariant = "primary" | "primaryInvert" | "secondary" | "none";

export interface LongHoldButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: LongHoldButtonVariant;
    disabled?: boolean;
    fullWidth?: boolean;
    className?: string;
    loading?: boolean;
    icon?: string;
    small?: boolean;
    hasIndicator?: boolean;
    inputRef?: any;
    onLongHold: Function;
    executeAfter?: number;
}

const LongHoldButton: FC<LongHoldButtonProps> = ({
    variant = "primary",
    fullWidth = false,
    disabled = false,
    className = "primaryHold",
    loading = false,
    type = "button",
    children,
    icon = "",
    hasIndicator,
    small,
    inputRef,
    executeAfter = 3000,
    onClick,
    onLongHold,
    ...rest
}) => {
    const isMounted = useRef(true);
    const [isHolding, setHolding] = useState(false);
    const [isLoading, setLoading] = useState(loading);
    const [isHoverActive, setHover] = useState(false);
    const timeout = useRef(0);

    useEffect(() => {
        if (isHolding) {
            timeout.current = window.setTimeout(async () => {
                setLoading(true);

                try {
                    await onLongHold();
                } finally {
                    if (isMounted.current) {
                        setLoading(false);
                    }
                }
            }, executeAfter);
        } else {
            clearTimeout(timeout.current);
            setHolding(false);
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, [isHolding, children, isLoading, onClick, executeAfter, onLongHold]);

    useEffect(
        () => () => {
            isMounted.current = false;
        },
        []
    );

    const handleHover = (event: SyntheticEvent) => {
        setHover(event.type === "mouseenter");
        setHolding(false);
    };

    const handleLongHold = (start: boolean = false) => {
        setHolding(start);
    };

    const handleClick = (event: SyntheticEvent) => {
        event.preventDefault();
    };

    const handleRightClick = () => {
        setHolding(false);
    };

    return (
        <button
            className={classNames(styles[variant!], className, {
                [styles.expand]: fullWidth,
                [styles.smallText]: small,
                [styles.disabled]: disabled || isLoading,
                [styles.hover]: isHoverActive && !disabled,
                [styles.buttonHasIndicator]: isLoading || icon || hasIndicator,
                [styles.iconOnly]: icon && !children,
                [styles.holding]: isHolding
            })}
            style={isHolding ? { transition: `all ${executeAfter}ms ease-out` } : { transition: "all 0s ease-in" }}
            type={type}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            disabled={disabled || isLoading}
            {...rest}
            ref={inputRef}
            onClick={handleClick}
            onMouseDown={() => handleLongHold(true)}
            onMouseUp={() => handleLongHold()}
            onTouchStart={() => handleLongHold(true)}
            onTouchEnd={() => handleLongHold()}
            onContextMenu={handleRightClick}
        >
            <span>{children}</span>

            {isLoading ? (
                <LoadingIndicator
                    variant={
                        (isHoverActive && !(variant === "primaryInvert")) ||
                        (!isHoverActive && variant === "primaryInvert") ||
                        disabled
                            ? "onLight"
                            : "onDark"
                    }
                    className={classNames(styles.indicator)}
                />
            ) : icon ? (
                <Icon name={icon} className={styles.indicator} />
            ) : null}
        </button>
    );
};

export default LongHoldButton;
