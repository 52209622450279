import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { formatNumber, getChunkedArrayByCount } from "../../utils/helpers/render";
import Cell from "../Grid/Cell";
import Grid from "../Grid/Grid";
import styles from "./History.css";
import HistoryItem from "./HistoryItem";

@withTranslation(["internals", "feedbacks"])
export class History extends React.PureComponent {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
        customerOffers: PropTypes.arrayOf(PropTypes.object),
        t: PropTypes.func.isRequired,
        maxEntries: PropTypes.number,
        columnCount: PropTypes.oneOf([12, 6, 4, 3, 2, 1])
    };

    static defaultProps = {
        customerOffers: [],
        maxEntries: Infinity,
        columnCount: 1
    };

    render() {
        const {
            data,
            columnCount,
            maxEntries,
            t,
            openCraftsmanRequestDocument,
            openCustomerOfferDocument,
            customerOffers
        } = this.props;
        const entries = data.reduce((entries, entry, i) => {
            const displayDistanceToTargetInKm =
                typeof entry.distanceToTargetInKm === "number" && entry.distanceToTargetInKm >= 0
                    ? formatNumber(entry.distanceToTargetInKm, 0) + " km"
                    : "-";

            let additionalText = entry.craftsmanFacility
                ? `${entry.craftsmanFacility.companyName}, ${displayDistanceToTargetInKm}`
                : "";
            let feedbacks = entry.craftsmanFacility ? [] : entry.feedbacks;
            let status = entry.craftsmanFacility ? { status: "requested" } : entry.status;
            let documents = entry.documentLinks || [];

            // HistoryItem CLOSED REQUEST
            if (
                entry.craftsmanFacility &&
                entry.status &&
                entry.status.status !== "requested" &&
                entry.status.status !== "coordination"
            ) {
                entries.push(
                    <HistoryItem
                        key={i + ".1"}
                        {...entry}
                        dateTime={entry.stateChangedAt}
                        additionalText={additionalText}
                        t={t}
                    />
                );
            }

            documents.forEach((document, docIndex) => {
                entries.push(
                    <HistoryItem
                        key={i + "." + docIndex + 3}
                        {...entry}
                        feedbacks={[]}
                        dateTime={document.createdAt}
                        additionalText={entry.craftsmanFacility.companyName}
                        status={{ status: "craftsmanRequestDocumentCreated" }}
                        documents={[
                            ...(document.gaebAvailable
                                ? [
                                      {
                                          ...document,
                                          tooltip: `Handwerkeranfrage Version ${document.version} (XLS) ansehen`,
                                          iconName: "IconXLS",
                                          openDocument: () =>
                                              openCraftsmanRequestDocument(
                                                  document.documentId,
                                                  !!document.gaebAvailable
                                              )
                                      }
                                  ]
                                : []),
                            {
                                ...document,
                                tooltip: `Handwerkeranfrage Version ${document.version} (PDF) ansehen`,
                                openDocument: () => openCraftsmanRequestDocument(document.documentId)
                            }
                        ]}
                        t={t}
                    />
                );
            });

            // HistoryItem OPENED REQUEST or Fake Entry via Feedback
            entries.push(
                <HistoryItem
                    key={i + ".2"}
                    {...entry}
                    dateTime={entry.createdAt}
                    feedbacks={feedbacks}
                    additionalText={additionalText}
                    status={status}
                    t={t}
                />
            );

            return entries;
        }, []);

        const customerOfferDocuments = customerOffers.filter(customerOffer => customerOffer.customerOfferDocumentLink);

        customerOfferDocuments.forEach(customerOffer => {
            entries.push(
                <HistoryItem
                    key={customerOffer.customerOfferDocumentLink.documentId}
                    feedbacks={[]}
                    dateTime={customerOffer.customerOfferDocumentLink.createdAt}
                    additionalText={customerOffer.customerOfferDocumentLink.documentId}
                    status={{ status: "customerOfferDocumentCreated" }}
                    documents={[
                        {
                            ...customerOffer.customerOfferDocumentLink,
                            tooltip: (
                                <Fragment>
                                    <strong>{customerOffer.title}</strong> (PDF) ansehen
                                </Fragment>
                            ),
                            openDocument: openCustomerOfferDocument.bind(
                                null,
                                customerOffer.id,
                                customerOffer.customerOfferDocumentLink.documentId
                            )
                        }
                    ]}
                    t={t}
                />
            );
        });

        // entries.sort((a, b) => (a.props.dateTime > b.props.dateTime ? -1 : 1));
        entries.sort((a, b) => new Date(b.props.dateTime) - new Date(a.props.dateTime));

        const itemsHiddenCount = entries.length - maxEntries;
        const itemsToDisplay = entries.splice(0, maxEntries || entries.length);

        const columnsToDisplay = itemsToDisplay.length > 3 ? columnCount : 1;

        return (
            <div className={styles.history}>
                <Grid>
                    {getChunkedArrayByCount(itemsToDisplay, columnsToDisplay).map((columnEntries, i) => (
                        <Cell key={i} small={12} medium={12 / columnsToDisplay}>
                            {columnEntries}
                        </Cell>
                    ))}
                    {isFinite(itemsHiddenCount) && itemsHiddenCount > 0 ? (
                        <Cell small={12}>
                            <p className={styles.details}>{`${itemsHiddenCount} weitere Einträge verfügbar`}</p>
                        </Cell>
                    ) : null}
                </Grid>
            </div>
        );
    }
}
