// TODO: remove workaround when state transitions are available from backend API
const vorgangTransitionMapWithStates = {
    // phase
    Koordination: {
        // fallback "status"
        default: [],
        // status
        Koordination: []
    },
    InMigration: {
        default: [
            {
                trigger: "startProjectCompletion"
            }
        ],
        traurigeskarneval: [
            {
                trigger: "traurigeskarneval"
            }
        ]
    },
    HandwerkerAnfrage: {
        default: [],
        BetreuungsanfrageBestaetigt: [
            {
                trigger: "vorOrtTerminAbstimmen"
            },
            {
                trigger: "startProjectRevocation",
                disabled: false
            }
        ],
        VorOrtTerminAbgestimmt: [
            {
                trigger: "vorOrtTerminBestaetigen"
            },
            {
                trigger: "startProjectRevocation",
                disabled: false
            },
            {
                trigger: "vorOrtTerminLoeschen"
            }
        ]
    },
    VertragsManagement: {
        default: [],
        VorOrtTerminBestaetigt: [
            {
                trigger: "startProjectRevocation",
                disabled: true
            }
        ],
        BeauftragungVersendet: [
            {
                trigger: "baustellenStartTerminFestlegen"
            }
        ],
        BeauftragungBestaetigt: [
            {
                trigger: "baustellenStartTerminFestlegen"
            }
        ],
        BaustellenStartTerminFestgelegt: [
            {
                trigger: "baustellenStartBestaetigen"
            },
            {
                trigger: "baustellenStartTerminLoeschen"
            }
        ]
    },
    Umsetzung: {
        default: [],
        BaustelleGestartet: [],
        LeistungenGebucht: [],
        SchlussrechnungErstellt: [
            {
                trigger: "startProjectCompletion"
            }
        ]
    }
};

const projectActionMapping = {
    default: [
        {
            name: "viewProject",
            disabled: false
        }
    ],
    coordination: [
        {
            name: "viewProject",
            disabled: false
        },
        {
            name: "abrechnungenStornieren",
            disabled: false
        }
    ],
    created: [
        {
            name: "editVermittlungsType",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "setProjectOperator",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "verifyProject",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "startRejection",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "viewProject",
            disabled: false
        }
    ],
    operator_assigned: [
        {
            name: "editVermittlungsType",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "startMatching",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "startCancelation",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "viewProject",
            disabled: false
        }
    ],
    craftsman_requested: [
        {
            name: "startCancelation",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "viewCraftsman",
            disabled: false
        },
        {
            name: "startProjectRevocation",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "craftsmanOperations",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "viewProject",
            disabled: false
        }
    ],
    in_execution: [
        {
            name: "startProjectCompletion",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "startCancelation",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "viewCraftsman",
            disabled: false
        },
        {
            name: "startProjectRevocation",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "vorOrtTermin",
            needs: "projekt:bearbeiten"
        },
        {
            name: "baustellenStartTermin",
            needs: "projekt:bearbeiten"
        },
        {
            name: "viewProject",
            disabled: false
        },
        {
            name: "abrechnungenStornieren",
            disabled: false
        }
    ],
    completed: [
        {
            name: "viewProject",
            disabled: false
        },
        {
            name: "viewCraftsman",
            disabled: false
        },
        {
            name: "setProjectReopen",
            disabled: false,
            needs: "projekt:bearbeiten"
        }
    ],
    rejected: [
        {
            name: "viewProject",
            disabled: false
        },
        {
            name: "createSuccessor",
            disabled: false,
            needs: "projekt:bearbeiten"
        }
    ],
    cancelled: [
        {
            name: "viewProject",
            disabled: false
        },
        {
            name: "viewCraftsman",
            disabled: false
        },
        {
            name: "createSuccessor",
            disabled: false,
            needs: "projekt:bearbeiten"
        },
        {
            name: "abrechnungenStornieren",
            disabled: false,
            needs: "projekt:bearbeiten"
        }
    ]
};

export const resolveAction = (actionName, status) => {
    status = projectActionMapping.hasOwnProperty(status) ? status : "default";
    return projectActionMapping[status].find(object => object.name === actionName);
};

export const resolveVorgangAction = (actionName, vorgangStatus, vorgangPhase) => {
    const selectedPhase = vorgangTransitionMapWithStates.hasOwnProperty(vorgangPhase) ? vorgangPhase : "InMigration";
    const selectedStatus = vorgangTransitionMapWithStates[selectedPhase].hasOwnProperty(vorgangStatus)
        ? vorgangStatus
        : "default";

    return vorgangTransitionMapWithStates[selectedPhase][selectedStatus].find(object => object.trigger === actionName);
};
