import React, { PureComponent } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import styles from "./ShortInfo.css";

export default class ShortInfoHeadline extends PureComponent {
    static defaultProps = {
        level: 2,
        count: null,
        underlined: false,
        icon: null,
        standalone: true
    };

    static propTypes = {
        headline: PropType.string.isRequired,
        count: PropType.any,
        level: PropType.number,
        underlined: PropType.bool,
        icon: PropType.element,
        standalone: PropType.bool
    };

    render() {
        const Headline = `h${this.props.level || 2}`;

        return (
            <Headline
                className={classNames(styles.headline, this.props.className, {
                    [styles.underlined]: this.props.underlined,
                    [styles.standalone]: this.props.standalone
                })}
            >
                {this.props.headline}
                {this.props.count ? <span className={styles.count}> ({this.props.count || 0})</span> : null}
                {this.props.icon ? <span className={styles.headlineIcon}>{this.props.icon}</span> : null}
            </Headline>
        );
    }
}
