import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./Grid.css";

const renderColumns = (children, layout) => {
    return React.Children.map(children, child => {
        return <div className={classNames(styles.column, styles[`${layout}Column`])}>{child}</div>;
    });
};

export default class ContainerRow extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.element),
            PropTypes.objectOf(PropTypes.element),
            PropTypes.element
        ])
    };

    render() {
        const { layout, renderCols } = this.props;

        return (
            <div className={classNames(styles.row, styles[layout])}>
                {renderCols ? renderColumns(this.props.children, layout) : this.props.children}
            </div>
        );
    }
}

export class ContainerCol extends Component {
    render() {
        const { layout } = this.props;

        return <div className={classNames(styles.column, styles[`${layout}Column`])}>{this.props.children}</div>;
    }
}
