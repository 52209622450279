import React from "react";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { scrollTo } from "../../utils/helpers/ui";
import styles from "./Markdown.css";

export class Markdown extends React.PureComponent<{ content: string }> {
    markdownRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        const { current: markdownHTMLElement } = this.markdownRef;

        if (markdownHTMLElement) {
            [...markdownHTMLElement.getElementsByTagName("a")]
                .filter(a => a.hash.length)
                .forEach(a => {
                    a.addEventListener("click", this.scrollToElement);
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // todo handle event when markdown updates
    }

    componentWillUnmount() {
        const { current: markdownHTMLElement } = this.markdownRef;

        if (markdownHTMLElement) {
            [...markdownHTMLElement.getElementsByTagName("a")].forEach(a => {
                a.removeEventListener("click", this.scrollToElement);
            });
        }
    }

    scrollToElement(event) {
        if (event.currentTarget) {
            event.preventDefault();

            scrollTo(document.getElementById(event.currentTarget.hash.slice(1)));
        }
    }

    render() {
        return (
            <div
                ref={this.markdownRef}
                className={styles.wrapper}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(this.props.content)) }}
            />
        );
    }
}
