import React, { PureComponent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { getGenericMessage } from "@lucify/utils";
import { isScrollable } from "../../utils/helpers/render";
import FormMessage from "../FormElements/FormMessage";
import Icon from "../Icon/Icon";
import styles from "./ResultsView.css";

const Results = React.memo(({ t, body, onRowClick, rowShouldBeClickable, meta }) =>
    body.map((results, index) => {
        const active = rowShouldBeClickable ? rowShouldBeClickable(meta[index]) : true;

        const rowAttributes = {
            onClick: onRowClick && active ? event => onRowClick(meta[index], index, event) : undefined
        };

        return (
            <tr
                className={classNames({
                    [styles.clickable]: onRowClick && active,
                    [styles.disabled]: !active
                })}
                key={index}
                {...rowAttributes}
            >
                {results.map((col, index) => (
                    <td key={index} className={styles[col.name]}>
                        <span className={styles.entry} {...col.attributes}>
                            {col.content}
                        </span>
                    </td>
                ))}
            </tr>
        );
    })
);

const Head = React.memo(({ headers, sortParams, onClickSort }) =>
    headers.map((head, index) => (
        <th key={index} className={styles[head.name]}>
            {sortParams && onClickSort && head.sortBy ? (
                <button className={styles.sortButton} onClick={() => onClickSort(head)}>
                    <span className={styles.entry}>{head.label}</span>

                    {sortParams.sortBy === head.sortBy ? (
                        <span
                            className={classNames(styles.sortArrow, {
                                [styles.up]: sortParams.sortOrder === "asc",
                                [styles.down]: sortParams.sortOrder !== "asc"
                            })}
                        >
                            <Icon name={"IconArrowDown"} />
                        </span>
                    ) : null}
                </button>
            ) : (
                <span className={styles.entry}>{head.label}</span>
            )}
        </th>
    ))
);

const Foot = React.memo(({ footers }) =>
    footers.map((foot, index) => (
        <td key={index} className={styles[foot.name]}>
            <span className={styles.entry}>{foot.label}</span>
        </td>
    ))
);

export const ResultsViewAction = React.memo(props => (
    <Icon name="IconMoreHorizontal" className={classNames(styles.more, styles.action)} />
));

export default class ResultsView extends PureComponent {
    static propTypes = {
        head: PropTypes.array.isRequired,
        body: PropTypes.array.isRequired,
        meta: PropTypes.array,
        tableClass: PropTypes.string.isRequired,
        onRowClick: PropTypes.func,
        rowShouldBeClickable: PropTypes.func
    };

    static defaultProps = {
        meta: [],
        responseData: {}
    };

    constructor() {
        super();
        this.state = {
            scrollable: null,
            formMessage: null
        };
    }

    componentDidMount() {
        this.checkScrollable();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scrollable !== prevState.scrollable) {
            return {
                ...prevState,
                scrollable: nextProps.scrollable
            };
        }
        return null;
    }

    @autobind
    handleFormMessage() {
        return this.props.responseData.status
            ? {
                  ...getGenericMessage(this.props.responseData, {
                      200: {
                          message: "Ihre Suche ergab leider kein Ergebnis.",
                          type: "info"
                      },
                      500: {
                          message:
                              "Bei der Verarbeitung der Anfrage ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. (500)",
                          type: "error"
                      }
                  })
              }
            : null;
    }

    checkScrollable() {
        const scrollable = isScrollable(this.scrollContainer);
        if (this.state.scrollable !== scrollable) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    scrollable
                };
            });
        }
    }

    @autobind
    sort(target) {
        const { sortParams } = this.props;

        this.props.onSort({
            sortBy: target.sortBy,
            sortOrder: sortParams.sortBy !== target.sortBy || sortParams.sortOrder === "desc" ? "asc" : "desc"
        });
    }

    render() {
        const { sortParams } = this.props;

        if (!this.props.body.length || this.props.responseData.failed) {
            return (
                <div className={styles.noResultsBody}>
                    <FormMessage className={styles.noResultsMessage} {...this.handleFormMessage()} />
                </div>
            );
        } else {
            return (
                <div className={classNames([styles.results, { [styles.scrollable]: this.state.scrollable }])}>
                    <div
                        className={styles.scrollcontainer}
                        ref={element => {
                            this.scrollContainer = element;
                        }}
                    >
                        <table className={classNames(styles.table, styles[this.props.tableClass])}>
                            <thead className={styles.thead}>
                                <tr className={styles.tr}>
                                    <Head headers={this.props.head} sortParams={sortParams} onClickSort={this.sort} />
                                </tr>
                            </thead>
                            <tbody className={styles.tbody}>
                                <Results
                                    body={this.props.body}
                                    onRowClick={this.props.onRowClick}
                                    rowShouldBeClickable={this.props.rowShouldBeClickable}
                                    meta={this.props.meta}
                                />
                            </tbody>

                            {this.props.foot ? (
                                <tfoot className={styles.tfoot}>
                                    <tr className={styles.tr}>
                                        <Foot footers={this.props.foot} />
                                    </tr>
                                </tfoot>
                            ) : null}
                        </table>
                    </div>
                </div>
            );
        }
    }
}
