import React from "react";
import classNames from "classnames";
import { HandwerkerBewertung as HandwerkerBewertungType } from "@lucify/services/src/generated/craftsmen-service.types";
import { withFormMessage } from "../../interfaces/Globals";
import FormMessage from "../FormElements/FormMessage";
import styles from "./Bewertung.css";
import HandwerkerBewertung from "./HandwerkerBewertung";

interface HandwerkerBewertungenListeProps extends Omit<withFormMessage, "resetFormMessage"> {
    bewertungen: HandwerkerBewertungType[];
    deleteBewertung: (bewertungId: string) => Promise<void>;
}

const HandwerkerBewertungenListe: React.FC<HandwerkerBewertungenListeProps> = props => {
    const { bewertungen = [], deleteBewertung } = props;

    return (
        <div
            className={classNames(styles.list, {
                [styles.listEmpty]: !bewertungen.length
            })}
        >
            {bewertungen.length
                ? bewertungen.map(bewertung => (
                      <React.Fragment key={bewertung.id}>
                          {props.formMessageRelation === bewertung.id && <FormMessage {...props.formMessage} />}
                          <HandwerkerBewertung deleteBewertung={deleteBewertung} {...bewertung} />
                      </React.Fragment>
                  ))
                : "Keine Bewertungen"}
        </div>
    );
};

export default HandwerkerBewertungenListe;
