import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./Indicator.css";

const indicatorsGeoLocation = {
    high: null,
    medium: (
        <Tooltip className={styles.indicator} text={"Ein Teil der Adresse ist eventuell zu ungenau."}>
            <Icon name={"IconError"} intent={"warning"} />
        </Tooltip>
    ),
    low: (
        <Tooltip className={styles.indicator} text={"Die Adresse ist möglicherweise sehr ungenau."}>
            <Icon name={"IconError"} intent={"error"} />
        </Tooltip>
    ),
    invalid: (
        <Tooltip className={styles.indicator} text={"Die Adresse ist nicht vollständig."}>
            <Icon name={"IconError"} intent={"invalid"} />
        </Tooltip>
    ),
    unknown: (
        <Tooltip className={styles.indicator} text={"Die Adresse konnte nicht verifiziert werden."}>
            <Icon name={"IconWarningRound"} intent={"hint"} />
        </Tooltip>
    ),
    pending: (
        <Tooltip className={styles.indicator} text={"Die Adresse wird noch ausgewertet."}>
            <Icon name={"IconError"} intent={"hint"} />
        </Tooltip>
    )
};

const IndicatorGeolocationConfidence = React.memo(
    ({ geoLocation }) => indicatorsGeoLocation[geoLocation.confidence] || null
);

IndicatorGeolocationConfidence.propTypes = {
    geoLocation: PropTypes.shape({
        confidence: PropTypes.oneOf(["high", "medium", "low", "invalid", "unknown", "pending"])
    }).isRequired
};

export default IndicatorGeolocationConfidence;
