import React, { PureComponent } from "react";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { checkPermission } from "@lucify/auth";
import FormFakeTextarea from "../../../components/FormInput/FormFakeTextarea";
import TextEditForm from "../../../components/FormInput/Formik/TextEditForm";
import Textarea from "../../../components/FormInput/Formik/Textarea";
import Icon from "../../../components/Icon/Icon";
import ContainerViewItem from "../../../components/Profile/ContainerView";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { lucifyOIDCProvider } from "../../../services/lucifyOIDCProvider";
import EditContainerForm from "../../Form/EditContainerForm";

export default class Notizen extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        headline: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
        updateProjectProfile: PropTypes.func.isRequired,
        formMessage: PropTypes.shape({
            message: PropTypes.string,
            type: PropTypes.string
        }),
        formMessageRelation: PropTypes.string,
        resetFormMessage: PropTypes.func.isRequired,
        hint: PropTypes.string,
        container: PropTypes.bool
    };

    state = {
        editing: false,
        editingRef: null
    };

    @autobind
    toggleEditMode(refName) {
        this.setState({ editing: false });

        setTimeout(() => {
            this.setState({
                editing: this.state.editingRef !== refName,
                editingRef: this.state.editingRef === refName ? null : refName
            });
        });
    }

    render() {
        const { profile, ...props } = this.props;
        const sharedProps = {
            ...props,
            editing: this.state.editing,
            editingRef: this.state.editingRef,
            toggleEditMode: this.toggleEditMode,
            editProfileForm: EditContainerForm,
            editProfileFormMessageRelation: "editProject",
            updateProfile: this.props.updateProjectProfile,
            profile: profile
        };

        return (
            <ContainerViewItem
                editorView={checkPermission(lucifyOIDCProvider, "projekt:bearbeiten")}
                text={profile[this.props.name]}
                icon={
                    this.props.hint ? (
                        <Tooltip text={this.props.hint} noLayout>
                            <Icon name={"IconInfo"} />
                        </Tooltip>
                    ) : null
                }
                editForm={TextEditForm}
                formRefName={this.props.name}
                displayAs={
                    <FormFakeTextarea
                        readOnly={true}
                        layout={"notes"}
                        content={profile[this.props.name] || this.props.placeholder}
                        disabled={!profile[this.props.name]}
                        resize={profile[this.props.name] ? "vertical" : "none"}
                    />
                }
                fields={[
                    {
                        name: this.props.name,
                        type: "text",
                        label: "",
                        resize: "vertical",
                        component: Textarea,
                        layout: "notes"
                    }
                ]}
                {...sharedProps}
            />
        );
    }
}
