import React, { PureComponent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import { RestSuccessResponse } from "@lucify/services";
import { getGenericMessage, isRestErrorResponse } from "@lucify/utils";
import { displayDateTime, getDistance } from "../../utils/helpers/date";
import FormMessage from "../FormElements/FormMessage";
import styles from "./Comment.css";

export const DIVIDER = " ⟩ ";

export interface CommentProps {
    createdAt: string;
    id: string;
    comment?: string;
    deleteComment: Function;
}

interface CommentState {
    showConfirmation: boolean;
    failed: boolean;
    response?: RestSuccessResponse;
}

class Comment extends PureComponent<CommentProps, CommentState> {
    _isMounted = true;

    state = {
        showConfirmation: false,
        failed: false,
        response: undefined
    };

    @autobind
    handleConfirmation() {
        this.setState({
            showConfirmation: !this.state.showConfirmation,
            failed: false,
            response: undefined
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    @autobind
    async deleteComment() {
        try {
            const res = await this.props.deleteComment(this.props.id);
            if (this._isMounted) {
                this.setState({
                    failed: false,
                    response: res.value
                });
            }
        } catch (e) {
            if (isRestErrorResponse(e)) {
                if (this._isMounted) {
                    this.setState({
                        failed: true,
                        response: e.response
                    });
                }
            } else {
                console.error(e);
            }
        }
    }

    render() {
        const { createdAt, comment = "" } = this.props;
        const { inDays } = getDistance(createdAt);

        const dateTime = displayDateTime(createdAt, (inDays || 0) >= -1);
        const errorMessage = getGenericMessage(this.state.response);
        const hasDivider = comment.includes(DIVIDER);
        const name = hasDivider ? comment.substr(0, comment.indexOf(DIVIDER)) : null;
        const commentTransformed = hasDivider ? comment.substr(comment.indexOf(DIVIDER) + DIVIDER.length) : comment;

        return (
            <div className={styles.comment}>
                <div className={styles.author}>
                    {name ? name + ", " : ""}
                    {dateTime || "-"}
                </div>
                <p
                    className={classNames(styles.content, {
                        [styles.toBeConfirmed]: this.state.showConfirmation
                    })}
                >
                    {commentTransformed}
                </p>

                {this.state.failed && errorMessage ? <FormMessage {...errorMessage} /> : null}
                {this.state.showConfirmation ? (
                    <div className={styles.confirmation}>
                        <span className={styles.confirmationText}>Willst du diesen Kommentar wirklich löschen?</span>
                        <div className={styles.actions}>
                            <button className={styles.link} onClick={this.handleConfirmation}>
                                Abbrechen
                            </button>
                            <button className={styles.link} onClick={this.deleteComment}>
                                Bestätigen
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.actions}>
                        <button className={styles.link} onClick={this.handleConfirmation}>
                            Löschen
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default Comment;
