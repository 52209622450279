import React from "react";
import { isFlagActive } from "../../components/Feature/Feature";
import IndicatorGeolocationConfidence from "../../components/Indicator/IndicatorGeolocationConfidence";
import { InfoActionPhoneNumber } from "../../components/InfoTable/InfoActionPhoneNumber";
import { InlineMailOrExtLink } from "../../components/Typo/Typo";
import { displayAddress, displayName, displayPhoneNumbers, displayTermin } from "../../utils/helpers/render";

export const restructuredBody = (data, t) => {
    return [
        [
            {
                label: t("internals:fields.vermittlung.projectDescription"),
                text: data.projectDescription || "-",
                colSpan: 2
                // noEllipsis: false
            }
        ],
        [
            {
                label: t("internals:fields.vermittlung.market.id"),
                text: data.market ? data.market.description : "-",
                colSpan: data.contact && data.contact.firstName && data.contact.lastName ? 1 : 2
            },
            ...(data.contact && data.contact.firstName && data.contact.lastName
                ? [
                      {
                          label: t("internals:labels.vermittlung.contact.name"),
                          text: data.contact.firstName + " " + data.contact.lastName
                      }
                  ]
                : [])
        ],
        [
            ...(data.labels && data.labels.length
                ? [
                      {
                          label: t("internals:fields.vermittlung.labels"),
                          text: data.labels.join(", "),
                          colSpan: 2
                      }
                  ]
                : [])
        ],
        [
            {
                label:
                    data.contact && data.contact.phoneNumber
                        ? t("internals:fields.vermittlung.contact.phoneNumber")
                        : t("internals:fields.vermittlung.market.phoneNumber"),
                text:
                    data.contact && data.contact.phoneNumber
                        ? data.contact.phoneNumber
                        : data.market
                        ? data.market.phoneNumber
                        : "-",
                colSpan: data.contact && data.contact.email ? 1 : 2,
                actions: [
                    <InfoActionPhoneNumber
                        key={"phoneNumber"}
                        phoneNumber={
                            data.contact && data.contact.phoneNumber
                                ? data.contact.phoneNumber
                                : data.market
                                ? data.market.phoneNumber
                                : null
                        }
                    />
                ]
            },
            ...(data.contact && data.contact.email
                ? [
                      {
                          label: t("internals:fields.vermittlung.contact.email"),
                          text: (
                              <InlineMailOrExtLink mailto={data.contact.email}>
                                  {data.contact.email}
                              </InlineMailOrExtLink>
                          )
                      }
                  ]
                : [])
        ],
        [
            {
                label: t("internals:labels.vermittlung.customer.name"),
                text: displayName(data.customer.firstName, data.customer.lastName, data.customer.salutation),
                colSpan: data.customer.debitorId ? 1 : 2
            },
            ...(data.customer.debitorId
                ? [
                      {
                          label: t("internals:labels.vermittlung.customer.debitorId"),
                          text: data.customer.debitorId
                      }
                  ]
                : [])
        ],
        [
            {
                label: t("internals:fields.vermittlung.customer.phoneNumbers"),
                text: displayPhoneNumbers(data.customer.phoneNumbers),
                actions: [
                    <InfoActionPhoneNumber
                        key={"phoneNumber"}
                        phoneNumber={displayPhoneNumbers(data.customer.phoneNumbers)}
                    />
                ]
            },
            {
                label: t("internals:fields.vermittlung.customer.email"),
                text: data.customer.email ? (
                    <InlineMailOrExtLink mailto={data.customer.email}>{data.customer.email}</InlineMailOrExtLink>
                ) : (
                    "-"
                )
            }
        ],
        [
            {
                label: t("internals:labels.vermittlung.projectAddress"),
                text: displayAddress(data.street, data.zip, data.city, data.houseNumber, data.addressAddition),
                hint: <IndicatorGeolocationConfidence geoLocation={data.geoLocation} />,
                colSpan: 2
            }
        ],
        [
            {
                label: t("internals:fields.vermittlung.vorOrtTermin.datum"),
                text: displayTermin(data.vorOrtTermin, t),
                colSpan: isFlagActive("vermittlungsType") ? 1 : 2
            },
            ...(isFlagActive("vermittlungsType")
                ? [
                      {
                          label: t("internals:fields.vermittlung.baustellenStartTermin.datum"),
                          text: displayTermin(data.baustellenStartTermin, t)
                      }
                  ]
                : [])
        ]
    ].filter(i => i.length);
};

export const restructuredCraftsmanData = (data, t) => {
    return data
        ? [
              [
                  {
                      label: t("internals:labels.craftsman.contact.name"),
                      text: data.contact
                          ? displayName(data.contact.firstName, data.contact.lastName, data.contact.salutation)
                          : "-"
                  },
                  {
                      label: t("internals:fields.craftsman.facilityName"),
                      text: data.companyName
                  }
              ],
              [
                  {
                      label: t("internals:fields.craftsman.contact.phoneNumber"),
                      text: data.contact && data.contact.phoneNumber ? data.contact.phoneNumber : "-",
                      actions: [
                          <InfoActionPhoneNumber
                              key={"phoneNumber"}
                              phoneNumber={data.contact && data.contact.phoneNumber ? data.contact.phoneNumber : null}
                          />
                      ]
                  },
                  {
                      label: t("internals:fields.craftsman.contact.mobileNumber"),
                      text: data.contact && data.contact.mobileNumber ? data.contact.mobileNumber : "-",
                      actions: [
                          <InfoActionPhoneNumber
                              key={"mobileNumber"}
                              phoneNumber={data.contact && data.contact.mobileNumber ? data.contact.mobileNumber : null}
                          />
                      ]
                  }
              ],
              [
                  {
                      label: t("internals:fields.craftsman.email"),
                      text: data.email ? (
                          <InlineMailOrExtLink mailto={data.email}>{data.email}</InlineMailOrExtLink>
                      ) : (
                          "-"
                      ),
                      colSpan: 2
                  }
              ]
          ]
        : [];
};
