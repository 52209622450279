import { VorgangTransitionDTO } from "@lucify/services/src/generated/macher-service.types";
import { TransitionDTO } from "@lucify/services/src/generated/realization-service.types";
import { CustomerOfferEvent, ICustomerOffer } from "@lucify/types";

export function hasCustomerOfferTransition(customerOffer: ICustomerOffer, transition: CustomerOfferEvent) {
    return Boolean(
        customerOffer &&
            customerOffer.transitions &&
            customerOffer.transitions.find(({ event }) => event === transition)
    );
}

export function hasVorgangTransition(transitions: VorgangTransitionDTO[] | TransitionDTO[], transition: string) {
    return Boolean(Array.isArray(transitions) && transitions.find(({ trigger }) => trigger === transition));
}
