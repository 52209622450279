export const scrollTo = (element?: HTMLElement | null) => {
    setTimeout(() => {
        if (element) {
            const hook = document.createElement("div");
            hook.style.marginTop = "-70px";

            try {
                element.prepend(hook);
            } catch (e) {
                // TODO: at this point we only jump to block/flex elements
                element.insertBefore(hook, element.firstChild);
            }

            hook.scrollIntoView({
                block: "start",
                behavior: "smooth"
            });

            hook.parentNode?.removeChild(hook);
        }
    });
};
