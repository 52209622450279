import React, { useState } from "react";
import { Translation } from "react-i18next";
import { VorOrtTermin } from "@lucify/services/src/generated/macher-service.types";
import { $TSFixMe } from "@lucify/types";
import { ActionBoxGroup } from "../../../../components/ActionBox/ActionBox";
import LongHoldButton from "../../../../components/Button/LongHoldButton";
import { FormFakeInput } from "../../../../components/FormElements/FormElements";
import LinkIcon from "../../../../components/LinkIcon/LinkIcon";
import { displayDateTime } from "../../../../utils/helpers/date";

interface VorOrtTerminBestaetigungProps {
    vorOrtTerminLoeschen(): Promise<$TSFixMe>;
    vorOrtTerminBestaetigen(): Promise<$TSFixMe>;
    vorOrtTermin: VorOrtTermin;
}

const VorOrtTerminBestaetigung: React.FC<VorOrtTerminBestaetigungProps> = ({
    vorOrtTerminLoeschen,
    vorOrtTerminBestaetigen,
    vorOrtTermin
}) => {
    const [isPending, setIsPending] = useState(false);

    async function onClick() {
        setIsPending(true);
        try {
            await vorOrtTerminLoeschen();
        } catch (e) {
            console.error(e);
        } finally {
            setIsPending(false);
        }
    }

    return (
        <Translation>
            {t => (
                <ActionBoxGroup
                    label="Vor-Ort-Termin"
                    hint={
                        "Nach Bestätigung des Vor-Ort-Termins wird der HW permanent dem Vorgang zugeordnet. Ein Entziehen ist anschließend nicht mehr möglich."
                    }
                >
                    <FormFakeInput
                        label={""}
                        disabled
                        layout={"noPadding"}
                        value={
                            vorOrtTermin?.datum
                                ? displayDateTime(vorOrtTermin.datum, false)
                                : vorOrtTermin?.typ
                                ? t(`internals:terminTypes.${vorOrtTermin.typ}`)
                                : "\u00A0"
                        }
                    />
                    <LinkIcon
                        customClass={"smallText"}
                        onClick={onClick}
                        type={"button"}
                        disabled={isPending}
                        justify={"center"}
                    >
                        Vor-Ort-Termin zurücksetzen
                    </LinkIcon>
                    <LongHoldButton onLongHold={vorOrtTerminBestaetigen}>Bestätigt</LongHoldButton>
                </ActionBoxGroup>
            )}
        </Translation>
    );
};

export default VorOrtTerminBestaetigung;
