import React, { Fragment, SyntheticEvent } from "react";
import { AllowedFileTypes, allAllowedFileTypes } from "@lucify/internals";
import { FormFakeInput, FormLabel } from "../../FormElements/FormElements";
import { SearchAndSelect } from "../../FormElements/SearchAndSelect";

interface FileUploadProps {
    onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
    name: string;
    accept?: AllowedFileTypes[];
    hasError?: boolean;
    multiple?: boolean;
    value?: FileList | File;
}

export function FileUpload({
    onChange,
    accept = allAllowedFileTypes,
    name,
    multiple = false,
    hasError,
    value,
    ...rest
}: FileUploadProps) {
    const fileInputRef = React.createRef<HTMLInputElement>();

    function handleChange(e) {
        onChange(multiple ? e.target.files : e.target.files[0]);
    }

    function handleClick(e) {
        fileInputRef.current?.click();
    }

    return (
        <Fragment>
            <FormLabel labelText={`Dokument${multiple ? "e" : ""} auswählen`} />
            <SearchAndSelect buttonOnClick={handleClick} buttonIconName={"IconFolderOpenOutline"}>
                <Fragment>
                    <input
                        type={"file"}
                        ref={fileInputRef}
                        accept={accept.join(",")}
                        hidden
                        multiple={multiple}
                        onChange={handleChange}
                        name={name}
                    />
                    <FormFakeInput
                        onClick={handleClick}
                        hasError={hasError}
                        value={
                            value instanceof File
                                ? value.name
                                : value instanceof FileList && value.length
                                ? value.length > 1
                                    ? `${value.length} Dokumente ausgewählt`
                                    : value[0].name
                                : "kein Dokument gewählt"
                        }
                    />
                </Fragment>
            </SearchAndSelect>
        </Fragment>
    );
}
