import { Methods } from "@lucify/services";
import { Fetch } from "@lucify/services";
import { lucifyOIDCProvider } from "./lucifyOIDCProvider";

const fetch = new Fetch(lucifyOIDCProvider);

let host = "";

// const setHost = newHost => {
//     host = newHost;
// };

export const apiRoutes = {
    agb: `${process.env.PUBLIC_URL}/legal/AGB_{country}.md`,
    customerAgb: `${process.env.PUBLIC_URL}/legal/KUNDENAGB_{country}.md`,
    ewe: `${process.env.PUBLIC_URL}/legal/EWE_{country}.md`,
    impressum: `${process.env.PUBLIC_URL}/legal/Impressum_{country}.md`,
    datenschutz: `${process.env.PUBLIC_URL}/legal/Datenschutz_{country}.md`
};

const getAGB = (params, country) => {
    return fetch.request<string>({
        method: Methods.GET,
        rest: {
            url: host + apiRoutes.agb,
            data: {
                country
            }
        },
        params: {
            ...params,
            v: process.env.__COMMIT_HASH__
        }
    });
};

const getCustomerAGB = (params, country) => {
    return fetch.request<string>({
        method: Methods.GET,
        rest: {
            url: host + apiRoutes.customerAgb,
            data: {
                country
            }
        },
        params: {
            ...params,
            v: process.env.__COMMIT_HASH__
        }
    });
};

const getEWE = (params, country) => {
    return fetch.request<string>({
        method: Methods.GET,
        rest: {
            url: host + apiRoutes.ewe,
            data: {
                country
            }
        },
        params: {
            ...params,
            v: process.env.__COMMIT_HASH__
        }
    });
};

const getImpressum = (params, country) => {
    return fetch.request<string>({
        method: Methods.GET,
        rest: {
            url: host + apiRoutes.impressum,
            data: {
                country
            }
        },
        params: {
            ...params,
            v: process.env.__COMMIT_HASH__
        }
    });
};

const getDatenschutz = (params, country) => {
    return fetch.request<string>({
        method: Methods.GET,
        rest: {
            url: host + apiRoutes.datenschutz,
            data: {
                country
            }
        },
        params: {
            ...params,
            v: process.env.__COMMIT_HASH__
        }
    });
};

export const legalService = {
    getAGB,
    getCustomerAGB,
    getEWE,
    getImpressum,
    getDatenschutz
};
