import React, { ReactNode } from "react";
import { Field } from "formik";
import { FormGroupRow } from "../../FormElements/FormGroupRow";
import SingleInput from "./SingleInput";

interface AddressEditFormProps {
    fields: {
        name: string;
        label: string;
        validate?: Function;
        column: string[];
        component: ReactNode;
    }[];
}

const AddressEditForm: React.FC<AddressEditFormProps> = ({ fields }) => {
    return (
        <FormGroupRow columns={fields.map(field => field.column)}>
            {fields.map((field, index) => (
                <Field {...field} key={index} component={field.component || SingleInput} />
            ))}
        </FormGroupRow>
    );
};

export default AddressEditForm;
