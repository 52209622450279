import React from "react";
import { WithTranslation } from "react-i18next";
import { $TSFixMe, IDateString, ProjectState } from "@lucify/types";
import { displayDateTime } from "../../utils/helpers/date";
import IndicatorFeedback from "../Indicator/IndicatorFeedback";
import LinkIcon from "../LinkIcon/LinkIcon";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./History.css";

interface HistoryItemProps {
    status: {
        status: ProjectState;
    };
    dateTime: IDateString;
    additionalText: string;
    feedbacks: $TSFixMe;
    documents: $TSFixMe;
    id: string;
}

const HistoryItem: React.FC<HistoryItemProps & WithTranslation> = React.memo(
    ({ status, dateTime, additionalText = "", t, feedbacks, documents = [], id }) => {
        return (
            <React.Fragment>
                <div className={styles.entry}>
                    <span className={styles.status}>{t(`internals:historyStates.${status.status}`)}</span>
                    <span className={styles.details}>
                        {displayDateTime(dateTime, false)}
                        {additionalText ? `, ${additionalText}` : ""}

                        {feedbacks.length ? (
                            <IndicatorFeedback className={styles.entryIcon} t={t} feedback={feedbacks[0]} />
                        ) : null}

                        <span className={styles.documents}>
                            {documents.map((document, i) => (
                                <Tooltip key={i} text={document.tooltip}>
                                    <LinkIcon
                                        onClick={document.openDocument}
                                        className={styles.entryIcon}
                                        icon={{ name: document.iconName || "IconPDF", width: 18, height: 18 }}
                                    />
                                </Tooltip>
                            ))}
                        </span>
                    </span>
                </div>
            </React.Fragment>
        );
    }
);

export default HistoryItem;
