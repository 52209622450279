import React, { DragEvent, FC, ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AllowedFileTypes, allAllowedFileTypes } from "@lucify/internals";
import FormMessage from "../FormElements/FormMessage";
import Icon from "../Icon/Icon";
import styles from "./FileDropArea.css";

interface FileDropAreaProps {
    children?: ReactNode;
    onDrop(files: File, event?: DragEvent<HTMLDivElement>): void;
    disabled?: boolean;
}

enum FileDropAreaError {
    FileListNotAllowed = "FileListNotAllowed",
    FileTypeNotAllowed = "FileTypeNotAllowed"
}

export const FileDropArea: FC<FileDropAreaProps> = props => {
    const { children, onDrop, disabled } = props;
    const [isDragging, setIsDragging] = useState(false);
    const [error, setError] = useState<FileDropAreaError>();
    const dragCounter = useRef(0);
    const { t } = useTranslation("messages");

    function onDragEnterHandler(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        dragCounter.current++;
        setIsDragging(true);
    }

    function onDragLeaveHandler(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        dragCounter.current--;
        if (!dragCounter.current) {
            setIsDragging(false);
        }
    }

    function onDragOverHandler(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
    }

    function onDropHandler(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            if (event.dataTransfer.files.length > 1) {
                setError(FileDropAreaError.FileListNotAllowed);
            } else if (!allAllowedFileTypes.includes(event.dataTransfer.files[0].type as AllowedFileTypes)) {
                setError(FileDropAreaError.FileTypeNotAllowed);
            } else {
                onDrop(event.dataTransfer.files[0], event);
                setError(undefined);
            }
        }
        dragCounter.current = 0;
        setIsDragging(false);
    }

    return (
        <div
            onDragEnter={disabled ? undefined : onDragEnterHandler}
            onDragLeave={disabled ? undefined : onDragLeaveHandler}
            onDragOver={disabled ? undefined : onDragOverHandler}
            onDrop={disabled ? undefined : onDropHandler}
            className={styles.fileDropArea}
        >
            {error ? <FormMessage message={t(`validation.syncError.${error}`)} type={"error"} /> : null}

            {isDragging ? (
                <div className={styles.fileDropAreaMessage}>
                    <Icon name={"IconFileDownload"} width={32} height={32} />
                    <div>Dokument hier ablegen</div>
                </div>
            ) : null}

            {children}
        </div>
    );
};
