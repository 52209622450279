import React, { useState } from "react";
import isEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { checkPermission } from "@lucify/auth";
import { CraftsmanErinnerungGrund } from "@lucify/internals";
import {
    CraftsmanFacility,
    Erinnerung as ErinnerungType
} from "@lucify/services/src/generated/craftsmen-service.types";
import { $TSFixMe, ICraftsmanErinnerung } from "@lucify/types";
import { uniqueDeep } from "@lucify/utils";
import Button from "../../../components/Button/Button";
import ContainerHeadline from "../../../components/Container/ContainerHeadline";
import Erinnerung from "../../../components/Erinnerungen/Erinnerung";
import Erinnerungen from "../../../components/Erinnerungen/Erinnerungen";
import { FormWrapper } from "../../../components/FormElements/FormElements";
import FormMessage from "../../../components/FormElements/FormMessage";
import FormSection, {
    FormSectionBody,
    FormSectionHeadline,
    FormSectionSubmitGroup
} from "../../../components/FormElements/FormSection";
import RadiobuttonGroup from "../../../components/FormInput/Formik/RadiobuttonGroup";
import Reminder from "../../../components/FormInput/Formik/Reminder";
import Cell from "../../../components/Grid/Cell";
import Grid from "../../../components/Grid/Grid";
import Lightbox from "../../../components/Lightbox/Lightbox";
import { lucifyOIDCProvider } from "../../../services/lucifyOIDCProvider";
import { required } from "../../../utils/validation/validators";
import HandwerkerKurzInfo from "../HandwerkerKurzInfo";

interface CraftsmanErinnerungListProps {
    profile: CraftsmanFacility;
    updateCraftsmanProfile(values, formMessageRelation: string): Promise<undefined>;
    resetFormMessage(): void;
    formMessage: $TSFixMe;
    formMessageRelation: string;
    hideNewAction?: boolean;
}

export const CraftsmanErinnerungList: React.FC<CraftsmanErinnerungListProps> = ({
    profile,
    updateCraftsmanProfile,
    resetFormMessage,
    formMessage,
    formMessageRelation,
    hideNewAction
}) => {
    const [completedErinnerungen, setCompletedErinnerung] = useState<ICraftsmanErinnerung[]>([]);
    const [showLightbox, toggleLightbox] = useState(false);
    const [isLoading, setLoadingState] = useState<ICraftsmanErinnerung | false>(false);
    const { t } = useTranslation();
    const { erinnerungen, facilityName, contact, qualifications } = profile;

    const editorView = checkPermission(lucifyOIDCProvider, "handwerker:erinnerungen");

    const add = async (erinnerung: Partial<ICraftsmanErinnerung>, { setSubmitting }) => {
        try {
            await updateCraftsmanProfile(
                { erinnerungen: uniqueDeep([...erinnerungen, erinnerung]) },
                "CraftsmanErinnerungList"
            );
            toggleLightbox(false);
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitting(false);
        }
    };

    const update = async erinnerung => {
        const isCompleted = !completedErinnerungen.find(item => isEqual(item, erinnerung));

        const erinnerungenToKeep = isCompleted
            ? [...completedErinnerungen, erinnerung]
            : completedErinnerungen.filter(item => !isEqual(item, erinnerung));

        const erinnerungenToSend = isCompleted
            ? erinnerungen.filter(item => !isEqual(item, erinnerung))
            : [...erinnerungen, erinnerung];

        setLoadingState(erinnerung);

        try {
            await updateCraftsmanProfile(
                {
                    erinnerungen: erinnerungenToSend
                },
                "CraftsmanErinnerungList"
            );

            setCompletedErinnerung(erinnerungenToKeep);
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingState(false);
        }
    };

    const validate = erinnerung => {
        if (erinnerungen.find(item => isEqual(item, erinnerung))) {
            return {
                grund: "ErinnerungAlreadyExists",
                datum: "ErinnerungAlreadyExists"
            };
        } else {
            return {};
        }
    };

    return (
        <>
            <Erinnerungen
                onClickNeu={hideNewAction ? undefined : () => toggleLightbox(true)}
                editorView={editorView}
                prerenderedChildren={uniqueDeep<ErinnerungType>([...erinnerungen, ...completedErinnerungen])
                    .sort((a, b) => Date.parse(a.datum) - Date.parse(b.datum) || a.grund.localeCompare(b.grund))
                    .map((erinnerung, index) => ({
                        component: (
                            <Erinnerung
                                datum={erinnerung.datum}
                                grund={t(`internals:fields.craftsman.erinnerungen.grund.${erinnerung.grund}`)}
                                erledigt={!!completedErinnerungen.find(item => isEqual(item, erinnerung))}
                                key={index}
                                disabled={!!isLoading}
                                viewOnly={!editorView}
                                loading={isEqual(isLoading, erinnerung)}
                                onClick={() => {
                                    update(erinnerung);
                                }}
                            />
                        ),
                        ...erinnerung
                    }))}
            />

            <Lightbox
                onClose={() => {
                    toggleLightbox(false);
                    resetFormMessage();
                }}
                open={showLightbox}
            >
                <Formik validate={validate} onSubmit={add} initialValues={{ datum: undefined, grund: undefined }}>
                    {({ isSubmitting, isValid, errors }) => (
                        <Form>
                            <FormWrapper>
                                <ContainerHeadline headline={"Erinnerung"} lightboxed underlined />

                                <FormSection headline={"Handwerker"} divided={false}>
                                    <HandwerkerKurzInfo
                                        facilityName={facilityName}
                                        contact={contact}
                                        qualifications={qualifications}
                                    />
                                </FormSection>

                                <FormSection divided={false}>
                                    <FormSectionHeadline headline={"Wann möchten Sie erinnert werden:"} />
                                    <FormSectionBody>
                                        <Grid>
                                            <Cell small={12} medium={6}>
                                                <Field
                                                    name={"datum"}
                                                    validate={required}
                                                    component={Reminder}
                                                    layout={"noPadding"}
                                                    showDistance={false}
                                                    pluginReset={false}
                                                />
                                            </Cell>
                                        </Grid>
                                    </FormSectionBody>
                                </FormSection>
                                <FormSection>
                                    <FormSectionHeadline headline={"Woran möchten Sie erinnert werden:"} />
                                    <FormSectionBody>
                                        <Field
                                            component={RadiobuttonGroup}
                                            name={"grund"}
                                            layout={"fullsize"}
                                            validate={required}
                                            options={[
                                                ...Object.keys(CraftsmanErinnerungGrund).map(grund => ({
                                                    value: grund,
                                                    label: t(`internals:fields.craftsman.erinnerungen.grund.${grund}`)
                                                }))
                                            ]}
                                        />
                                    </FormSectionBody>
                                </FormSection>

                                <FormSection>
                                    {errors.datum === "ErinnerungAlreadyExists" &&
                                    errors.grund === "ErinnerungAlreadyExists" ? (
                                        <FormMessage
                                            message={t("messages:validation.syncError.ErinnerungAlreadyExists")}
                                        />
                                    ) : null}

                                    {formMessage && formMessageRelation === "CraftsmanErinnerungList" ? (
                                        <FormMessage {...formMessage} />
                                    ) : null}

                                    <FormSectionSubmitGroup>
                                        <Button
                                            type={"submit"}
                                            hasIndicator={true}
                                            disabled={isSubmitting || !isValid}
                                            loading={isSubmitting}
                                            fullWidth={true}
                                        >
                                            Abschicken
                                        </Button>

                                        <Button
                                            type={"button"}
                                            disabled={isSubmitting}
                                            onClick={() => toggleLightbox(!showLightbox)}
                                            variant={"secondary"}
                                            fullWidth={true}
                                        >
                                            Abbrechen
                                        </Button>
                                    </FormSectionSubmitGroup>
                                </FormSection>
                            </FormWrapper>
                        </Form>
                    )}
                </Formik>
            </Lightbox>
        </>
    );
};
