import React from "react";
import { classNames } from "@lucify/utils";
import { formatDate, getDistance } from "../../utils/helpers/date";
import Button from "../Button/Button";
import styles from "./Erinnerungen.css";

export interface UIErinnerung {
    datum: Date | string;
    grund: string;
    erledigt?: boolean;
    onClick?: any;
    loading?: boolean;
    disabled?: boolean;
    viewOnly?: boolean;
}

const Erinnerung: React.FC<UIErinnerung> = ({
    datum,
    grund,
    erledigt = false,
    loading,
    disabled,
    viewOnly,
    onClick = () => {}
}) => {
    const { inWords, inDays } = getDistance(datum);

    return datum ? (
        <div
            className={classNames(styles.erinnerung, {
                [styles.zukuenftig]: inDays! > 0,
                [styles.vergangen]: inDays! < 0,
                [styles.heute]: inDays === 0,
                [styles.erledigt]: erledigt
            })}
        >
            <div className={classNames(styles.datumWrap, { [styles.lineThrough]: erledigt })}>
                <span className={styles.datumInWorten}>{inWords}</span>
                <span className={styles.datum}>{formatDate(datum)}</span>
            </div>

            <br />

            <div className={classNames(styles.grund, { [styles.lineThrough]: erledigt })}>{grund}</div>

            {!viewOnly && (
                <Button
                    className={styles.piktogram}
                    variant="blank"
                    onClick={onClick}
                    square
                    loading={loading}
                    disabled={disabled}
                    icon={erledigt ? "IconAlarmOn" : "IconRadioUnchecked"}
                />
            )}
        </div>
    ) : null;
};

export default Erinnerung;
