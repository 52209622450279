import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Tag from "./Tag";
import styles from "./TagList.css";

export default class TagList extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string
            }).isRequired
        )
    };

    static defaultProps = {
        className: null
    };

    render() {
        return (
            <div className={classNames(styles.list, this.props.className)}>
                {this.props.tags.map((tag, i) => (tag.value ? <Tag key={i} {...tag} /> : null))}
            </div>
        );
    }
}
