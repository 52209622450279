import { MacherProjektDTO } from "@lucify/services/src/generated/macher-service.types";
import { CountryCode, LucifyEnvironment } from "@lucify/types";

export const environmentHostMappingLucifyDE: Record<LucifyEnvironment, string> = {
    [LucifyEnvironment.LOCAL]: "localhost:3000",
    [LucifyEnvironment.DEV]: "lucify-de-development.stage-next.obi-solution.net",
    [LucifyEnvironment.TEST]: "lucify-de-test.stage-next.obi-solution.net",
    [LucifyEnvironment.STAGE]: "lucify-de.stage-next.obi-solution.net",
    [LucifyEnvironment.PROD]: "lucify-de.intern.next.obi-solution.net"
};

export const environmentHostMappingLucifyAT: Record<LucifyEnvironment, string> = {
    [LucifyEnvironment.LOCAL]: "localhost:3001",
    [LucifyEnvironment.DEV]: "lucify-at-development.stage-next.obi-solution.net",
    [LucifyEnvironment.TEST]: "lucify-at-test.stage-next.obi-solution.net",
    [LucifyEnvironment.STAGE]: "lucify-at.stage-next.obi-solution.net",
    [LucifyEnvironment.PROD]: "lucify-at.intern.next.obi-solution.net"
};

export const environmentHostMappingLucifyLegacy: Record<LucifyEnvironment, string> = {
    [LucifyEnvironment.LOCAL]: "localhost:3003",
    [LucifyEnvironment.DEV]: "lucify-client-development.stage-next.obi-solution.net",
    [LucifyEnvironment.TEST]: "lucify-client-test.stage-next.obi-solution.net",
    [LucifyEnvironment.STAGE]: "lucify-client.stage-next.obi-solution.net",
    [LucifyEnvironment.PROD]: "machbar-macher.de"
};

export const environmentHostMappingBackoffice: Record<LucifyEnvironment, string> = {
    [LucifyEnvironment.LOCAL]: "localhost:3033",
    [LucifyEnvironment.DEV]: "backoffice-development.stage-next.obi-solution.net",
    [LucifyEnvironment.TEST]: "backoffice-test.stage-next.obi-solution.net",
    [LucifyEnvironment.STAGE]: "backoffice.stage-next.obi-solution.net",
    [LucifyEnvironment.PROD]: "backoffice.intern.next.obi-solution.net"
};

export const srtHostMapping: Record<LucifyEnvironment, string> = {
    [LucifyEnvironment.LOCAL]: "https://srt-dev.production.next.obi-solution.net",
    [LucifyEnvironment.DEV]: "https://srt-dev.production.next.obi-solution.net",
    [LucifyEnvironment.TEST]: "https://srt-stage2.production.next.obi-solution.net",
    [LucifyEnvironment.STAGE]: "https://example.com",
    [LucifyEnvironment.PROD]: "https://srt.production.next.obi-solution.net"
};

export function srtDetailsLink(environment: LucifyEnvironment, macherProjekt: MacherProjektDTO) {
    const host = srtHostMapping[environment];
    return `${host}/main/expert/inquiries/${macherProjekt.id}/${macherProjekt.externerKunde?.baseCustomerId}/details`;
}

export function lucifyLegacyVorgangLink(environment: LucifyEnvironment, macherProjektId: string, vorgangId: string) {
    const protocol = window.location.protocol;
    const host = environmentHostMappingLucifyLegacy[environment];
    return `${protocol}//${host}/projekt/${macherProjektId}/vorgang/${vorgangId}`;
}

export function lucifyVorgangLink(
    environment: LucifyEnvironment,
    country: CountryCode,
    macherProjektId: string,
    vorgangId: string
) {
    const protocol = window.location.protocol;
    const host =
        country === CountryCode.de
            ? environmentHostMappingLucifyDE[environment]
            : environmentHostMappingLucifyAT[environment];
    return `${protocol}//${host}/app/macherprojekt/${macherProjektId}/vorgang/${vorgangId}`;
}

export function lucifyMacherProjekteLink(environment: LucifyEnvironment, country: CountryCode) {
    const protocol = window.location.protocol;
    const host =
        country === CountryCode.de
            ? environmentHostMappingLucifyDE[environment]
            : environmentHostMappingLucifyAT[environment];
    return `${protocol}//${host}/app/macherprojekte`;
}
