import React from "react";
import { useSelector } from "react-redux";
import { $TSFixMe } from "@lucify/types";
import Button from "../../components/Button/Button";
import InfoTable from "../../components/InfoTable/InfoTable";
import ShortInfo, { ShortInfoActions } from "../../components/ShortInfo/ShortInfo";
import { fallbackProjectMap } from "../../mappings/InfoTable/profileInfo";
import { selectBreakpoint } from "../../store/selectors/breakpoint.selectors";

interface KurzInfoProps {
    profile: $TSFixMe;
    profileLink?(): void;
}

export default function KurzInfo(props: KurzInfoProps) {
    const { profile } = props;
    const breakpoint = useSelector(selectBreakpoint);

    return (
        <ShortInfo headline={"Projekt"} layoutClass={"projectProfileInfo"} loading={!profile}>
            {profile ? (
                <React.Fragment>
                    <InfoTable
                        body={fallbackProjectMap(profile)}
                        mobile={breakpoint ? breakpoint.width < 768 : false}
                    />

                    {props.profileLink ? (
                        <ShortInfoActions>
                            <Button variant="secondary" onClick={props.profileLink}>
                                Zur Detailansicht
                            </Button>
                        </ShortInfoActions>
                    ) : null}
                </React.Fragment>
            ) : null}
        </ShortInfo>
    );
}
