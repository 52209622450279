import React from "react";
import Icon from "../Icon/Icon";
import styles from "./InfoTable.css";

type InfoActionPhoneNumberProps = {
    phoneNumber: string;
};

export const InfoActionPhoneNumber: React.FC<InfoActionPhoneNumberProps> = React.memo(({ phoneNumber }) => {
    const number = phoneNumber ? phoneNumber.replace(/[^0-9+]/g, "") : null;

    return number ? (
        <a href={`tel:${number}`} className={styles.infoActionPhoneNumber}>
            <Icon name={"IconPhone"} />
        </a>
    ) : null;
});
