const statusLevel = (status = "") => {
    switch (status) {
        case "toBeChecked":
            return "warning";

        case "active":
            return "positive";

        case "temporarilyDisabled":
            return "warning";

        case "disabled":
            return "negative";

        case "akquise":
            return "warning";

        case "abgebrochen":
            return "negative";

        default:
            return "neutral";
    }
};

export const craftsmanProfileTags = (data, t) =>
    data
        ? [
              ...(data.neu
                  ? [
                        {
                            value: t("internals:labels.neu"),
                            variant: ["semiBold", "new"]
                        }
                    ]
                  : []),
              ...(data.craftsmanCompany?.heyOBIProfiData
                  ? [
                        {
                            value: t("internals:labels.craftsman.heyobiprofi"),
                            variant: ["semiBold", "profi"]
                        }
                    ]
                  : []),
              {
                  value: t("internals:states." + data.status),
                  variant: ["semiBold", statusLevel(data.status)]
              },
              {
                  label: t("internals:labels.craftsman.shortId"),
                  value: data.shortId,
                  variant: "neutral"
              },
              {
                  label: t("internals:labels.craftsman.jacandoId"),
                  value: data.jacandoId,
                  variant: "neutral"
              },
              {
                  label: t("internals:labels.craftsman.orsId"),
                  value: data.orsId,
                  variant: "neutral"
              }
          ]
        : [];
