import React, { PureComponent } from "react";
import ReactSelect from "react-select";
import classNames from "classnames";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "../FormElements/FormElements";
import {
    ClearIndicator,
    DropdownIndicator,
    MultiValueRemove,
    Option,
    SelectContainer
} from "./Formik/SelectComponents";
import styles from "./Select.css";

export default class Select extends PureComponent {
    selectRef = React.createRef();

    static defaultProps = {
        className: "",
        options: [
            { label: "Option 1 Label", value: "option1", isDisabled: false },
            { label: "Option 2 selected", value: "option2", isDisabled: false }
        ],
        isDisabled: false,
        required: false,
        placeholder: "Bitte auswählen",
        noOptionsMessage: () => null,
        label: "",
        split: null,
        classNamePrefix: "select",
        component: ReactSelect
    };

    static propTypes = {
        component: PropTypes.any,
        className: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        options: PropTypes.array.isRequired,
        isDisabled: PropTypes.bool,
        required: PropTypes.bool,
        placeholder: PropTypes.string,
        classNameWrapper: PropTypes.string,
        split: PropTypes.string,
        onChange: PropTypes.func,
        noOptionsMessage: PropTypes.func,
        classNamePrefix: PropTypes.string,
        onEnter: PropTypes.func
    };

    @autobind
    onKeyDown(event) {
        if (
            this.props.onEnter &&
            this.selectRef.current &&
            !this.selectRef.current.select.state.inputValue &&
            this.selectRef.current.select.state.value.length &&
            event.keyCode === 13
        ) {
            this.props.onEnter();
        }
    }

    render() {
        const {
            className,
            options,
            isDisabled,
            required,
            placeholder,
            noOptionsMessage,
            isMulti,
            label,
            closeMenuOnSelect,
            classNamePrefix,
            closeMenuOnScroll,
            components,
            component,
            defaultValue,
            ...restProps
        } = this.props;

        const sharedAttributes = {
            className: classNames(styles.select, className, {
                [styles[this.props.split]]: this.props.split,
                [styles.hasError]: false
            }),
            options,
            isDisabled,
            required,
            placeholder,
            noOptionsMessage,
            isMulti,
            closeMenuOnSelect: !this.props.isMulti,
            isSearchable: options.length >= 10,
            classNamePrefix,
            closeMenuOnScroll: this.props.closeMenuOnScroll,
            components: {
                DropdownIndicator,
                Option,
                MultiValueRemove,
                ClearIndicator,
                SelectContainer,
                ...this.props.components
            }
        };

        const ReactSelectComponent = component;

        return (
            <FormGroup hasLabel={!!label}>
                {label ? <FormLabel labelText={label} disabled={isDisabled} /> : null}
                <div
                    className={classNames(styles.wrapper, this.props.classNameWrapper, {
                        [styles.disabled]: isDisabled
                    })}
                >
                    <ReactSelectComponent
                        {...sharedAttributes}
                        {...restProps}
                        ref={this.selectRef}
                        onKeyDown={this.onKeyDown}
                        defaultValue={defaultValue}
                        name={this.props.name}
                        onChange={this.props.onChange}
                        data-cy={this.props.name}
                    />
                </div>
            </FormGroup>
        );
    }
}
