import React, { PureComponent } from "react";
import { WithBreakpointsProps, withBreakpoints } from "react-breakpoints";
import { autobind } from "core-decorators";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { ILucifyKeycloakTokenParsed } from "@lucify/auth";
import { $TSFixMe } from "@lucify/types";
import { withFormMessage } from "../../interfaces/Globals";
import { lucifyOIDCProvider } from "../../services/lucifyOIDCProvider";
import Button from "../Button/Button";
import FormMessage from "../FormElements/FormMessage";
import Textarea from "../FormInput/Formik/Textarea";
import { DIVIDER } from "./Comment";
import styles from "./Comment.css";

interface CommentFormProps {
    addComment({ content }: { content: string }): Promise<$TSFixMe>;
}

@withBreakpoints
export default class CommentForm extends PureComponent<CommentFormProps & withFormMessage & WithBreakpointsProps> {
    static propTypes = {
        addComment: PropTypes.func
    };

    @autobind
    async submit(data, { setSubmitting, resetForm }) {
        try {
            await this.props.addComment({
                content: (lucifyOIDCProvider.tokenParsed as ILucifyKeycloakTokenParsed).name
                    ? (lucifyOIDCProvider.tokenParsed as ILucifyKeycloakTokenParsed).name + DIVIDER + data.content
                    : data.content
            });
            resetForm();
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitting(false);
        }
    }

    render() {
        const { breakpoints, currentBreakpoint } = this.props;

        return (
            <Formik onSubmit={this.submit} initialValues={{ content: "" }}>
                {({ isSubmitting, values }) => (
                    <Form className={styles.form}>
                        <Field
                            className={styles.commentInput}
                            component={Textarea}
                            name={"content"}
                            placeholder={"Hier Kommentar verfassen"}
                            resize={"vertical"}
                        />
                        {this.props.formMessageRelation === "addComment" ? (
                            <FormMessage {...this.props.formMessage} />
                        ) : null}
                        <Button
                            fullWidth={breakpoints[currentBreakpoint] < 768}
                            type={"submit"}
                            disabled={isSubmitting || !values.content}
                            loading={isSubmitting}
                        >
                            Hinzufügen
                        </Button>
                    </Form>
                )}
            </Formik>
        );
    }
}
