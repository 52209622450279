import React, { FC, ReactNode, SyntheticEvent } from "react";
import Button from "../Button/Button";
import styles from "./SearchAndSelect.css";

interface SearchAndSelectProps {
    children?: ReactNode;
    buttonOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
    buttonDisabled?: boolean;
    buttonIconName?: string; // todo fix type
}

export const SearchAndSelect: FC<SearchAndSelectProps> = ({
    buttonOnClick,
    buttonDisabled,
    buttonIconName,
    children
}) => {
    return (
        <div className={styles.searchAndAddWrapper}>
            <div className={styles.searchAndAddSearchSlot}>
                {React.Children.map(children, child =>
                    child ? <div className={styles.searchAndAddSearchSlotItem}>{child}</div> : null
                )}
            </div>
            <Button
                className={styles.searchAndAddAddButton}
                disabled={buttonDisabled}
                icon={buttonIconName || "IconAdd"}
                square
                onClick={buttonOnClick}
            />
        </div>
    );
};
