import React, { ReactNode } from "react";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./ActionBox.css";

interface ActionBoxProps {
    children: ReactNode;
}

interface ActionBoxGroupProps {
    layout?: string;
    hint?: string | ReactNode;
    label?: string;
    children: ReactNode;
}

export const ActionBox = (props: ActionBoxProps) => <div className={styles.actionBox}>{props.children}</div>;

export const ActionBoxGroup = (props: ActionBoxGroupProps) =>
    props.children ? (
        <div className={props.layout ? styles[props.layout] : ""}>
            {props.label ? (
                <span className={styles.label}>
                    {props.hint ? (
                        <Tooltip className={styles.hint} text={props.hint}>
                            <Icon name={"IconInfo"} />
                        </Tooltip>
                    ) : null}
                    {props.label}
                </span>
            ) : null}

            <div className={styles.actionBoxGroup}>
                {React.Children.map(props.children, child =>
                    child ? <div className={styles.actionBoxGroupItem}>{child}</div> : null
                )}
            </div>
        </div>
    ) : null;

export default ActionBox;
