import React, { PureComponent, ReactNode } from "react";
import classNames from "classnames";
import styles from "./Headline.css";

export interface HeadlineProps {
    headline: string;
    count?: number | string;
    level?: 1 | 2 | 3 | 4 | 5 | 6;
    underlined?: boolean;
    icon?: ReactNode;
    standalone?: boolean;
    variant?: string;
    className?: string;
}

export type possibleHeadings = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export default class Headline extends PureComponent<HeadlineProps> {
    static defaultProps = {
        level: 2,
        count: null,
        underlined: false,
        icon: null,
        standalone: true,
        variant: "headline",
        className: ""
    };

    render() {
        const Headline = `h${this.props.level}` as possibleHeadings;

        return (
            <Headline
                className={classNames(this.props.className, {
                    [styles.underlined]: this.props.underlined,
                    [styles.standalone]: this.props.standalone,
                    [styles[this.props.variant!]]: this.props.variant
                })}
            >
                {this.props.headline}
                {this.props.count ? <span className={styles.count}> ({this.props.count || 0})</span> : null}
                {this.props.icon ? <span className={styles.headlineIcon}>{this.props.icon}</span> : null}
            </Headline>
        );
    }
}
