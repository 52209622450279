import React from "react";
import { $TSFixMe } from "@lucify/types";
import { classNames } from "@lucify/utils";
import Icon from "../Icon/Icon";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import FormGroup from "./FormGroup";
import styles from "./FormInput.css";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
    hint?: $TSFixMe;
    label?: string;
    labelHint?: string;
    disableBrowserSpinners?: boolean;
    dataCy?: string;
    iconName?: string;
    loading?: boolean;
    hasError?: boolean;
}

const FormInput: React.FC<InputProps> = React.memo(
    ({
        hint,
        label,
        labelHint,
        disableBrowserSpinners,
        dataCy,
        iconName,
        loading,
        hasError,

        className,
        ...inputProps
    }) => {
        //
        const input = (
            <input
                {...inputProps}
                {...(dataCy ? { "data-cy": dataCy } : {})}
                className={classNames(styles.input, className, {
                    [styles.invalid]: hasError,
                    [styles.disableBrowserSpinners]: disableBrowserSpinners
                })}
            />
        );

        const contents =
            loading || iconName ? (
                <div className={styles.wrapped}>
                    {input}

                    {loading ? (
                        <LoadingIndicator variant={"onLight"} className={styles.indicator} />
                    ) : iconName ? (
                        <Icon name={iconName} className={styles.icon} />
                    ) : null}
                </div>
            ) : (
                input
            );

        return label || hint ? (
            <FormGroup label={label} labelHint={labelHint} inputHint={hint}>
                {contents}
            </FormGroup>
        ) : (
            contents
        );
    }
);

FormInput.defaultProps = {
    type: "text",
    dataCy: "",
    disableBrowserSpinners: true,
    autoComplete: "off",
    spellCheck: "false",
    autoCorrect: "off",
    autoCapitalize: "off"
};

FormInput.displayName = "Form.Input";

export default FormInput;
