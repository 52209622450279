import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FieldProps } from "formik";
import { $TSFixMe } from "@lucify/types";
import { selectFilterByDataStr } from "@lucify/utils";
import { StatusMessage, getGenericMessage } from "@lucify/utils";
import { getPKOList } from "../../../store/actions/projects.actions";
import { selectProjectsPKOs } from "../../../store/selectors/projects.selectors";
import { renderGravatarSrcURL } from "../../../utils/helpers/gravatar";
import { displayName } from "../../../utils/helpers/render";
// Was machen wir damit?
import Button from "../../Button/Button";
import FormMessage from "../../FormElements/FormMessage";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import styles from "../MarketSelect.css";
import Select from "./Select";
import { OptionBusinessCard } from "./SelectComponents";

interface PKOSelectProps extends FieldProps {
    label: string;
    isClearable: boolean;
    autoFocus: boolean;
    layout?: string;
    openMenuOnFocus: boolean;
}

interface PKOSelectOption {
    label: $TSFixMe | string;
    value: string;
    filterStr: string;
}
const PKOSelect: React.FC<PKOSelectProps> = ({
    field,
    form,
    label,
    isClearable,
    autoFocus,
    layout,
    openMenuOnFocus
}) => {
    const [options, setOptions] = useState<PKOSelectOption[]>([]);
    const { list, isFetching, status, failed } = useSelector(selectProjectsPKOs);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isFetching && !list.length && !status) {
            dispatch(getPKOList());
        }
    });

    if (list.length && !options.length) {
        setOptions([
            ...(!isClearable
                ? [
                      {
                          label: "Keine Auswahl",
                          value: "",
                          filterStr: ""
                      }
                  ]
                : []),
            ...list
                .filter(pko => pko.aktiv)
                .map(({ id, vorname, nachname, vertriebsRegion, email }) => ({
                    label: (
                        <>
                            {`${vorname} ${nachname} `}
                            <span className={styles.labelHint}>
                                {t("internals:vertriebsRegionen." + vertriebsRegion)}
                            </span>
                        </>
                    ),
                    value: id,
                    filterStr: `${vorname} ${nachname} ${t("internals:vertriebsRegionen." + vertriebsRegion)}`,
                    cardProps: {
                        name: displayName(vorname, nachname),
                        description: t("internals:vertriebsRegionen." + vertriebsRegion),
                        image: {
                            src: renderGravatarSrcURL(email),
                            alt: ""
                        }
                    }
                }))
        ]);
    }

    const customError: StatusMessage = {
        type: "error",
        title: "Beim Laden der Projektkoordinatoren ist ein Fehler aufgetreten."
    };

    const formMessageRetry = {
        component: Button,
        onClick: () => dispatch(getPKOList()),
        variant: "blank",
        label: "Nochmal versuchen"
    };

    return (
        <>
            {failed ? (
                <FormMessage {...getGenericMessage({ status }, { default: customError })} action={formMessageRetry} />
            ) : !isFetching ? (
                <Select
                    options={options}
                    filterOption={selectFilterByDataStr}
                    isSearchable
                    field={field}
                    form={form}
                    layout={layout}
                    label={label}
                    isClearable={isClearable}
                    components={{
                        Option: OptionBusinessCard
                    }}
                    autoFocus={autoFocus}
                    openMenuOnFocus={openMenuOnFocus}
                />
            ) : (
                <LoadingIndicator className={styles.loadingIndicatorWrapper} />
            )}
        </>
    );
};

export default PKOSelect;
