import { Component } from "react";
import isEqual from "react-fast-compare";
import { FormikContext, FormikValues, connect } from "formik";
import { cleanRequestData, debounce } from "@lucify/utils";

export interface AutoSaveProps {
    debounce?: number;
    debug?: boolean;
}

class AutoSaveComponent extends Component<AutoSaveProps & { formik: FormikContext<FormikValues> }> {
    static defaultProps = {
        debounce: 250
    };

    debouncedSubmit = debounce(this.props.formik.submitForm, this.props.debounce);

    componentDidUpdate(prevProps) {
        const { formik } = this.props;
        if (formik.dirty && !isEqual(cleanRequestData(formik.values), cleanRequestData(prevProps.formik.values))) {
            this.debouncedSubmit();
        }
    }

    render() {
        return null;
    }
}

export const AutoSave = connect<AutoSaveProps, FormikValues>(AutoSaveComponent);
