import React from "react";
import { classNames } from "@lucify/utils";
import Icon from "../Icon/Icon";
import styles from "./Score.css";

interface ScoreProps {
    value: number;
    hideUnfilled?: boolean;
}

const Score = ({ value, hideUnfilled }: ScoreProps) => {
    const maxScore = 5;
    const fillMap = Array(maxScore).fill(false).fill(true, 0, value);

    return (
        <span className={styles.stars}>
            {fillMap.map((mapItem, i) =>
                hideUnfilled && !mapItem ? null : (
                    <Icon
                        key={i}
                        name={"IconStar"}
                        width={18}
                        height={18}
                        className={classNames(styles.icon, { [styles.filled]: mapItem })}
                    />
                )
            )}
        </span>
    );
};

export default Score;
