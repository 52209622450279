import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ContainerHeadline from "../Container/ContainerHeadline";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import TagList from "../Tag/TagList";
import styles from "./ShortInfo.css";

export const ShortInfoLoading = React.memo(() => <LoadingIndicator className={styles.loading} />);

export const ShortInfoActions = props => (
    <div
        className={classNames(styles.infoActionBar, {
            [styles.expandActions]: props.expand
        })}
    >
        {React.Children.map(props.children, child => (
            <div
                className={classNames(styles.infoActionItem, {
                    [styles.expandItem]: props.expand
                })}
            >
                {child}
            </div>
        ))}
    </div>
);

export default class ShortInfo extends Component {
    static defaultProps = {
        layoutClass: "",
        headline: "Headline Title",
        container: false,
        custom: {},
        loading: false
    };

    static propTypes = {
        layoutClass: PropTypes.string,
        headline: PropTypes.string.isRequired,
        container: PropTypes.bool,
        custom: PropTypes.object,
        children: PropTypes.any,
        loading: PropTypes.bool,
        actions: PropTypes.any,
        icon: PropTypes.element
    };

    render() {
        return (
            <div className={classNames(styles.wrapper, styles[this.props.layoutClass])}>
                {this.props.container ? (
                    <ContainerHeadline headline={this.props.headline} {...this.props.custom} />
                ) : (
                    <h2 className={styles.headline}>
                        {this.props.headline}
                        {this.props.icon ? <span className={styles.headlineIcon}>{this.props.icon}</span> : null}
                    </h2>
                )}

                {this.props.tags && !this.props.loading ? (
                    <TagList tags={this.props.tags} className={styles.tagList} />
                ) : null}

                <div className={styles.shortInfoBody}>
                    {this.props.loading ? <ShortInfoLoading /> : this.props.children}
                </div>
            </div>
        );
    }
}

export const ShortInfoWrapper = props => (
    <div className={classNames(styles.wrapper, styles[props.layoutClass])}>{props.children}</div>
);

ShortInfoWrapper.propTypes = {
    layoutClass: PropTypes.string,
    children: PropTypes.any
};

export const ShortInfoHeadline = props =>
    props.container ? (
        <ContainerHeadline headline={props.headline} {...props.custom} />
    ) : (
        <h2 className={styles.headline}>
            {props.headline}
            {props.icon ? <span className={styles.headlineIcon}>{props.icon}</span> : null}
        </h2>
    );

ShortInfoHeadline.propTypes = {
    container: PropTypes.bool,
    headline: PropTypes.string.isRequired,
    custom: PropTypes.object,
    icon: PropTypes.element
};

export const ShortInfoBody = props => (
    <React.Fragment>
        {props.tags && !props.loading ? <TagList tags={props.tags} className={styles.tagList} /> : null}

        <div className={styles.shortInfoBody}>{props.loading ? <ShortInfoLoading /> : props.children}</div>
    </React.Fragment>
);

ShortInfoBody.propTypes = {
    tags: PropTypes.array,
    loading: PropTypes.bool,
    children: PropTypes.any
};
