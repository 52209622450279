import React from "react";
import { displayValueOrPlaceholder } from "@lucify/utils";
import { isFlagActive } from "../../components/Feature/Feature";
import IndicatorGeolocationConfidence from "../../components/Indicator/IndicatorGeolocationConfidence";
import { InfoActionPhoneNumber } from "../../components/InfoTable/InfoActionPhoneNumber";
import { InlineMailOrExtLink } from "../../components/Typo/Typo";
import { displayAddress, displayCategories, displayName } from "../../utils/helpers/render";

export const restructuredBody = (data, t) => {
    if (data) {
        const qualifications =
            data.qualifications && data.qualifications.length
                ? data.qualifications.map(obj => obj.name).join(", ")
                : "-";
        const operationRoles =
            data.operationRoles && data.operationRoles.length
                ? data.operationRoles
                      .map(
                          operationRole =>
                              t(`internals:operationRoles.${operationRole}`) +
                              (operationRole === "BETREUUNG" ? ` (${data.ekPriceCluster || "-"})` : "")
                      )
                      .join(", ")
                : "-";
        const partnerTyp = displayValueOrPlaceholder(
            data.partnerTyp && t(`internals:fields.craftsman.partnerTyp.${data.partnerTyp}`)
        );

        return [
            [
                {
                    label: "Firma",
                    text: data.facilityName,
                    colSpan: 2
                }
            ],
            [
                {
                    label: t("internals:labels.craftsman.contact.name"),
                    text: data.contact
                        ? displayName(data.contact.firstName, data.contact.lastName, data.contact.salutation)
                        : "-"
                },
                {
                    label: "Radius",
                    text: data.radius ? data.radius + " km" : "unbegrenzt"
                }
            ],
            [
                {
                    label: "Telefon Festnetz",
                    text: data.contact && data.contact.phoneNumber ? data.contact.phoneNumber : "-",
                    actions: [
                        <InfoActionPhoneNumber
                            key={"phoneNumber"}
                            phoneNumber={data.contact && data.contact.phoneNumber ? data.contact.phoneNumber : null}
                        />
                    ]
                },
                {
                    label: "Telefon Mobil",
                    text: data.contact && data.contact.mobileNumber ? data.contact.mobileNumber : "-",
                    actions: [
                        <InfoActionPhoneNumber
                            key={"mobileNumber"}
                            phoneNumber={data.contact && data.contact.mobileNumber ? data.contact.mobileNumber : null}
                        />
                    ]
                }
            ],
            [
                {
                    label: "Firmen-Account-E-Mail",
                    text: data.craftsmanCompany.email ? (
                        <InlineMailOrExtLink mailto={data.craftsmanCompany.email}>
                            {data.craftsmanCompany.email}
                        </InlineMailOrExtLink>
                    ) : (
                        "-"
                    ),
                    colSpan: 2
                }
            ],
            [
                {
                    label: "Standort-E-Mail für Kommunikation",
                    text: data.email ? (
                        <InlineMailOrExtLink mailto={data.email}>{data.email}</InlineMailOrExtLink>
                    ) : (
                        "-"
                    ),
                    colSpan: 2
                }
            ],
            [
                {
                    label: "Adresse",
                    text: displayAddress(
                        data.address.street,
                        data.address.zip,
                        data.address.city,
                        data.address.houseNumber,
                        data.address.addition
                    ),
                    hint: <IndicatorGeolocationConfidence geoLocation={data.address.geoLocation} />,
                    colSpan: 2
                }
            ],
            [
                {
                    label: "Kategorien",
                    text: displayCategories(data.preferredCategories, t),
                    colSpan: 2
                }
            ],
            [
                {
                    label: "Qualifikationen",
                    text: qualifications,
                    colSpan: 2,
                    noEllipsis: true
                }
            ],
            [
                ...(isFlagActive("vermittlungsType")
                    ? [
                          {
                              label: "Vertragsmodell",
                              text: operationRoles,
                              colSpan: 2,
                              noEllipsis: true
                          }
                      ]
                    : [])
            ],
            [
                {
                    label: t("internals:labels.craftsman.partnerTyp"),
                    text: partnerTyp,
                    colSpan: 2
                }
            ]
        ];
    }

    return [];
};
