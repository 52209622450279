// sort-imports-ignore
import React, { PureComponent } from "react";
import { autobind } from "core-decorators";
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js";
import { Instance } from "flatpickr/dist/types/instance";
import { Options } from "flatpickr/dist/types/options";
import { FieldProps } from "formik";
import SingleInput from "../SingleInput";
import "../Datepicker.css";

flatpickr.localize(German); // FlatpickrFn

const hooks = [
    "onChange",
    "onOpen",
    "onClose",
    "onMonthChange",
    "onYearChange",
    "onReady",
    "onValueUpdate",
    "onDayCreate"
];

interface DatepickerProps {
    options: Options;
}

// Inspired by https://github.com/coderhaoxin/react-flatpickr/
export default class Datepicker extends PureComponent<DatepickerProps & FieldProps & Options> {
    static defaultProps = {
        options: {
            altFormat: "d.m.Y",
            altInput: true,
            disableMobile: true,
            weekNumbers: true
        }
    };

    node?: HTMLInputElement;
    flatpickr?: Instance;

    componentDidUpdate(prevProps) {
        const { options } = this.props;
        const prevOptions = {
            ...Datepicker.defaultProps.options,
            ...prevProps.options
        };

        // Add prop hooks to options
        hooks.forEach(hook => {
            if (this.props.hasOwnProperty(hook)) {
                options[hook] = this.props[hook];
            }
            // Add prev ones too so we can compare against them later
            if (prevProps.hasOwnProperty(hook)) {
                prevOptions[hook] = prevProps[hook];
            }
        });

        const optionsKeys = Object.getOwnPropertyNames(options);

        for (let index = optionsKeys.length - 1; index >= 0; index--) {
            const key = optionsKeys[index];
            let value = options[key];

            if (value !== prevOptions[key]) {
                // Hook handlers must be set as an array
                if (hooks.indexOf(key) !== -1 && !Array.isArray(value)) {
                    value = [value];
                }

                this.flatpickr!.set(key as keyof Options, value);
            }
        }

        if (this.props.hasOwnProperty("field") && this.props.field.value !== prevProps.field.value) {
            this.flatpickr!.setDate(this.props.field.value, false);
        }
    }

    componentDidMount() {
        const options = {
            ...Datepicker.defaultProps.options,
            onClose: () => {
                if (this.node) {
                    this.node.blur && this.node.blur();
                }
            },
            onChange: (selectedDates, dateString) => {
                if (this.props.hasOwnProperty("field")) {
                    this.props.form.setFieldValue(this.props.field.name, dateString);
                }
            },
            defaultDate: this.props.hasOwnProperty("field") && this.props.field.value,
            ...this.props.options
        };

        // Add prop hooks to options
        hooks.forEach(hook => {
            if (this.props[hook]) {
                options[hook] = this.props[hook];
            }
        });

        if (this.node instanceof HTMLInputElement) {
            this.flatpickr = flatpickr(this.node, options);

            if (this.props.hasOwnProperty("input")) {
                this.flatpickr!.setDate(this.props.field.value, false);
            }
        }
    }

    componentWillUnmount() {
        this.flatpickr!.destroy();
    }

    @autobind
    setRef(node: HTMLInputElement) {
        this.node = node;
    }

    render() {
        const { options, field, form, ...props } = this.props;

        // Don't pass hooks to dom node
        hooks.forEach(hook => {
            delete props[hook];
        });

        return <SingleInput {...props} forwardRef={this.setRef} icon={"IconDateRange"} />;
    }
}
