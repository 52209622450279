import React, { PureComponent } from "react";
import isEqual from "react-fast-compare";
import { autobind } from "core-decorators";
import { connect } from "formik";
import PropTypes from "prop-types";
import { uuid } from "@lucify/utils";
import { SplitSelectInput } from "../../FormElements/FormElements";
import Cell from "../../Grid/Cell";
import Grid from "../../Grid/Grid";
import styles from "../Search.css";
import Select from "../Select";
import SingleInput from "../SingleInput";

@connect
export class SearchTextFilter extends PureComponent {
    state = {
        items: [],
        options: [],
        values: {}
    };

    static propTypes = {
        sharedOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
        spreadToCount: PropTypes.number
    };

    static defaultProps = {
        spreadToCount: 2
    };

    componentDidMount() {
        const initialValues = Object.entries(this.props.formik.initialValues).reduce((acc, [key, value]) => {
            if (value && this.props.sharedOptions.find(option => option.value === key)) {
                acc.push({
                    key,
                    value
                });
            }
            return acc;
        }, []);

        const items = Array(this.props.spreadToCount)
            .fill()
            .map(() => uuid());

        const initialItemMap = items.map((item, index) => ({
            item,
            key: initialValues[index] ? initialValues[index].key : null
        }));

        const options = [...this.props.sharedOptions];

        initialItemMap.forEach(({ item, key }, index) => {
            const option = key
                ? options.find(option => option.value === key)
                : options.filter(option => !option.selectedBy)[0];
            option.selectedBy = item;
        });

        const values = options.reduce((acc, curr) => {
            if (curr.selectedBy) {
                acc[curr.selectedBy] = this.props.formik.initialValues[curr.value];
            }
            return acc;
        }, {});

        this.setState({ items, options, values });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps, this.props)) {
            const newFormikValues = Object.entries(this.props.formik.values).reduce((acc, [key, value]) => {
                if (value && this.props.sharedOptions.find(option => option.value === key)) {
                    acc.push({
                        key,
                        value
                    });
                }
                return acc;
            }, []);

            const valueMap = newFormikValues.reduce((acc, { key, value }) => {
                const option = this.state.options.find(option => option.value === key);

                acc[option.selectedBy] = value;
                return acc;
            }, {});

            const values = Object.entries(this.state.values).reduce((acc, [item]) => {
                acc[item] = valueMap[item];
                return acc;
            }, {});

            this.setState({
                values
            });
        }
    }

    @autobind
    handleOnChange(value, selectToUpdate) {
        this.setState({
            options: this.state.options.map((option, i) => ({
                ...option,
                selectedBy:
                    value === option.value
                        ? selectToUpdate
                        : option.selectedBy === selectToUpdate
                        ? null
                        : option.selectedBy
            }))
        });

        setTimeout(this.updateValues);
    }

    @autobind
    updateValues(id, event) {
        const values = {
            ...this.state.values,
            ...(event ? { [id]: event.target.value } : {})
        };

        const newValues = this.state.options.reduce((acc, curr) => {
            acc[curr.value] = curr.selectedBy ? values[curr.selectedBy] : undefined;
            return acc;
        }, {});

        this.props.formik.setValues({
            ...this.props.formik.values,
            ...newValues
        });

        this.setState({
            values
        });
    }

    render() {
        return (
            <Grid paddingX className={styles.filterRow}>
                {this.state.items.map(id => {
                    const initialValue = this.state.options.find(obj => obj.selectedBy === id);

                    return initialValue ? (
                        <Cell key={id} small={12} medium={6}>
                            <SplitSelectInput className={styles.filterRowChild}>
                                <Select
                                    split={"left"}
                                    value={initialValue}
                                    onChange={({ value }) => {
                                        this.handleOnChange(value, id);
                                    }}
                                    options={this.state.options.map(option => ({
                                        ...option,
                                        isDisabled: option.selectedBy && option.selectedBy !== id
                                    }))}
                                />
                                <SingleInput
                                    type={"text"}
                                    onChange={this.updateValues.bind(this, id)}
                                    value={this.state.values[id]}
                                    placeholder={"Eingabe"}
                                    split={"right"}
                                    autoComplete={"on"}
                                />
                            </SplitSelectInput>
                        </Cell>
                    ) : null;
                })}
            </Grid>
        );
    }
}
