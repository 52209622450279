import React, { ReactNode } from "react";
import { Formik, FormikActions, FormikErrors } from "formik";
import { filterValuesByFieldsArray } from "@lucify/utils";
import Button from "../../components/Button/Button";
import { ButtonGroup } from "../../components/Button/ButtonGroup";
import { FormGroup } from "../../components/FormElements/FormElements";
import FormMessage from "../../components/FormElements/FormMessage";
import FormSection from "../../components/FormElements/FormSection";
import SyncErrors from "../../components/FormInput/Formik/SyncErrors";
import LinkIcon from "../../components/LinkIcon/LinkIcon";
import { InfoEditForm } from "../../components/Profile/InfoEdit";
import { InfoViewItemButtonGroup } from "../../components/Profile/InfoView";
import { withFormMessage } from "../../interfaces/Globals";

interface EditProfileInfoFormProps<Entity> extends Partial<withFormMessage> {
    onReset: Function;
    fields: {
        name: string;
        label: string;
        validate?: Function;
        column: string[];
        component: ReactNode;
    }[];
    onSubmit: (values: Partial<Entity>, formikActions: FormikActions<{}>) => void;
    fullWidth: boolean;
    copyHint: string;
    breakpoint;
    profile: Entity;
    layout: string;
    fieldsEntity: string;
    validate?: (values: Partial<Entity>) => void | object | Promise<FormikErrors<{}>>;
    copy: (values: Entity) => Partial<Entity>;
}

const EditProfileInfoForm = <Entity extends {}>({
    fields,
    onSubmit,
    fullWidth,
    copyHint,
    breakpoint,
    profile,
    onReset,
    children,
    formMessage,
    layout,
    fieldsEntity,
    validate,
    copy
}: React.PropsWithChildren<EditProfileInfoFormProps<Entity>>) => (
    <Formik
        onSubmit={onSubmit}
        initialValues={filterValuesByFieldsArray(profile, fields)}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
    >
        {({ isSubmitting, resetForm, errors, setValues }) => (
            <InfoEditForm fullWidth={fullWidth}>
                {copyHint ? (
                    <FormGroup>
                        <LinkIcon
                            customClass={"smallText"}
                            icon={{
                                name: "IconFileCopyOutline",
                                width: 18,
                                height: 18
                            }}
                            onClick={() => setValues(copy(profile))}
                            type={"button"}
                        >
                            {copyHint}
                        </LinkIcon>
                    </FormGroup>
                ) : null}

                <FormSection layout={layout} divided={false}>
                    <FormMessage {...formMessage} />
                    <SyncErrors fieldsEntity={fieldsEntity} errors={errors} />
                    {children}
                </FormSection>

                <InfoViewItemButtonGroup>
                    <ButtonGroup unstack={breakpoint.width >= 480} reverseStack={breakpoint.width < 480}>
                        <Button
                            type={"button"}
                            disabled={isSubmitting}
                            onClick={() => {
                                resetForm();
                                onReset();
                            }}
                            variant={"secondary"}
                            mini={true}
                            fullWidth={true}
                        >
                            Abbrechen
                        </Button>

                        <Button type={"submit"} disabled={isSubmitting} mini={true} fullWidth={true}>
                            Speichern
                        </Button>
                    </ButtonGroup>
                </InfoViewItemButtonGroup>
            </InfoEditForm>
        )}
    </Formik>
);

export default EditProfileInfoForm;
