import React, { Component, PureComponent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { createErrorObject } from "@lucify/utils";
import { calcContainerWidth } from "../../utils/helpers/render";
import Can from "../Feature/Can";
import FormMessage from "../FormElements/FormMessage";
import Icon from "../Icon/Icon";
import styles from "./InfoView.css";

export class InfoViewItem extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        fields: PropTypes.array,
        editForm: PropTypes.func,
        editFormProps: PropTypes.object,
        formRefName: PropTypes.string,
        editProfileForm: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
        editProfileFormMessageRelation: PropTypes.string,
        updateProfile: PropTypes.func.isRequired,
        fullWidth: PropTypes.bool,
        actions: PropTypes.arrayOf(PropTypes.element),
        afterSubmit: PropTypes.func,
        copyHint: PropTypes.string,
        copy: PropTypes.func,
        permission: PropTypes.string
    };

    @autobind
    async submitForm(data, { setSubmitting, setErrors }) {
        try {
            await this.props.updateProfile(data);
            this.props.toggleEditMode(this.props.formRefName);
            if (this.props.afterSubmit) {
                this.props.afterSubmit();
            }
        } catch (err) {
            if (err.response && err.response.data.hasOwnProperty("errors")) {
                setErrors(createErrorObject(err));
            }
        } finally {
            setSubmitting(false);
        }
    }

    @autobind
    resetForm() {
        this.props.toggleEditMode(this.props.formRefName);
        this.props.resetFormMessage();
    }

    render() {
        const {
            fields,
            editForm: EditForm,
            editProfileForm: EditProfileForm,
            editFormProps,
            actions,
            form,
            permission
        } = this.props;
        const isCurrent = this.props.editingRef === this.props.formRefName;
        const editMode = this.props.editing && isCurrent && fields && fields.length;

        return (
            <div
                className={classNames(styles.infoView, {
                    [styles.editModeOn]: editMode
                })}
            >
                <div
                    className={styles.infoCell}
                    style={{
                        paddingRight: actions ? calcContainerWidth(this.props.actions.length + 1) : null
                    }}
                >
                    <div className={styles.infoLabel}>{this.props.label}</div>
                    {editMode ? (
                        <EditProfileForm
                            fullWidth={this.props.fullWidth}
                            id={this.props.id}
                            fields={fields}
                            onReset={this.resetForm}
                            onSubmit={this.submitForm}
                            layout={this.props.layout}
                            formMessage={this.props.formMessage}
                            copyHint={this.props.copyHint}
                            copy={this.props.copy}
                            profile={this.props.profile}
                            fieldsEntity={this.props.fieldsEntity}
                            breakpoint={this.props.breakpoint}
                            validate={this.props.validate}
                        >
                            <EditForm dispatch={this.props.dispatch} fields={fields} form={form} {...editFormProps} />
                            {this.props.formMessageRelation === this.props.editProfileFormMessageRelation ? (
                                <FormMessage {...this.props.formMessage} />
                            ) : null}
                        </EditProfileForm>
                    ) : (
                        <div
                            className={classNames(styles.infoText, {
                                [styles.hasHint]: this.props.hint,
                                [styles.noEllipsis]: this.props.noEllipsis
                            })}
                        >
                            {this.props.text}
                            {this.props.hint ? <div className={styles.infoHint}>{this.props.hint}</div> : null}
                        </div>
                    )}
                    <div className={styles.infoActions}>
                        {editMode ? null : this.props.actions}

                        <Can perform={permission}>
                            <div
                                className={classNames(styles.editIcon, {
                                    [styles.disabled]: !isCurrent && this.props.editing
                                })}
                                onClick={() => {
                                    this.props.toggleEditMode(this.props.formRefName);
                                }}
                            >
                                <Icon name={this.props.editing && isCurrent ? "IconClose" : "IconModeEdit"} />
                            </div>
                        </Can>
                    </div>
                </div>
            </div>
        );
    }
}

export class InfoViewItemButtonGroup extends Component {
    render() {
        return <div className={styles.buttonGroup}>{this.props.children}</div>;
    }
}

export class InfoViewItemStatic extends PureComponent {
    render() {
        return (
            <div
                className={styles.infoCell}
                style={{
                    paddingRight: this.props.actions ? calcContainerWidth(this.props.actions.length) : null
                }}
            >
                <div className={styles.infoLabel}>{this.props.label}</div>
                <div
                    className={classNames(styles.infoText, {
                        [styles.hasHint]: this.props.hint
                    })}
                >
                    {this.props.text}
                    {this.props.hint ? <div className={styles.infoHint}>{this.props.hint}</div> : null}
                </div>
                {this.props.actions ? <div className={styles.infoActions}>{this.props.actions}</div> : null}
            </div>
        );
    }
}
