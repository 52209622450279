import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { push } from "connected-react-router";
import { autobind } from "core-decorators";
import { RestErrorResponse, RestSuccessResponse } from "@lucify/services";
import { CraftsmenServiceTypes } from "@lucify/services";
import {
    CraftsmanFacility,
    CraftsmanFacilityComment,
    DocumentPatchRequest
} from "@lucify/services/src/generated/craftsmen-service.types";
import { CraftsmanFacilityMetricsDTO } from "@lucify/services/src/generated/macher-service.types";
import { $TSFixMe, $TSWorkaround, IComment } from "@lucify/types/src/interfaces/Globals";
import { getGenericMessage, isRestErrorResponse } from "@lucify/utils";
import { initialBewertungenParams } from "../container/Craftsman/Profile/CraftsmanBewertungen";
import { ICraftsmenBewertungenState, ICraftsmenState } from "../interfaces/ApplicationState";
import { withFormMessage } from "../interfaces/Globals";
import { craftsmenService } from "../services/craftsmen";
import {
    addComment,
    deleteComment,
    getBewertungen,
    getComments,
    getCraftsmanProfile,
    getCraftsmanStats,
    updateCraftsmanProfile
} from "../store/actions/craftsmen.actions";
import { connect } from "./typedConnect";

interface WithCraftsmanDispatchProps {
    getCraftsmanStats: typeof getCraftsmanStats;
    getCraftsmanProfile: typeof getCraftsmanProfile;
    updateCraftsmanProfile: typeof updateCraftsmanProfile;
    addComment: typeof addComment;
    getComments: typeof getComments;
    getBewertungen: typeof getBewertungen;
    deleteComment: typeof deleteComment;
    push: typeof push;
}

interface WithCraftsmanProps extends WithTranslation {
    craftsmanId: string;
    craftsmen: ICraftsmenState;
}

interface WithCraftsmanState extends Partial<withFormMessage> {}

export enum WithCraftsmanNamespaces {
    Stats = "stats",
    Profile = "profile",
    Actions = "actions",
    Comments = "comments",
    Bewertungen = "bewertungen"
}

type INamespaces = WithCraftsmanNamespaces[];

interface WithCraftsmanStateProps {
    craftsmen: ICraftsmenState;
}

interface ICraftsmenAction {
    meta: Object;
    payload: Promise<RestSuccessResponse<$TSWorkaround>>;
    type: string;
}

export interface ICurrentCraftsman {
    profile: CraftsmanFacility | null;
    stats: CraftsmanFacilityMetricsDTO | null;
    comments: CraftsmanFacilityComment[] | null;
    meta: ICraftsmanMeta;
    actions: ICraftsmanActions;
    bewertungen: ICraftsmenBewertungenState | null;
}

export interface WithCraftsman extends Partial<withFormMessage> {
    currentCraftsman: ICurrentCraftsman;
    refreshCraftsman(): Promise<$TSFixMe>;
}

export interface ICraftsmanActions {
    viewCraftsman(): void;
    contactCraftsman(): void;
    updateCraftsmanProfile(data: Object, formMessageRelation: string): Promise<undefined>;
    updateCraftsmanProfileFormik(data: Object, formMessageRelation: string): Promise<undefined>;
    resetFormMessage(): void;
    getComments(): Promise<void>;
    getBewertungen(params): Promise<void>;
    addComment(comment: Object): Promise<undefined>;

    deleteComment(commentId: string): Promise<any>;

    deleteBewertung(bewertungId: string, formMessageRelation?: string): Promise<any>;

    openCraftsmanDocument(documentId: string): void;
    openCraftsmanContractDraft(): void;
    createCraftsmanContractDocument(): Promise<undefined>;
    uploadCraftsmanDocument(
        formData: FormData
    ): Promise<RestSuccessResponse<CraftsmenServiceTypes.CraftsmanFacilityDocumentLink>>;
    updateCraftsmanDocument(
        documentId: string,
        data: DocumentPatchRequest
    ): Promise<RestSuccessResponse<CraftsmenServiceTypes.CraftsmanFacilityDocumentLink>>;
    deleteCraftsmanDocument(
        documentId: string
    ): Promise<RestSuccessResponse<CraftsmenServiceTypes.CraftsmanFacilityDocumentLink>>;
    addBewertung(data): Promise<void>;
}

export interface ICraftsmanPromiseState {
    fetching: boolean;
    failed: boolean;
    status?: number;
}

interface ICraftsmanMeta {
    profile: ICraftsmanPromiseState;
    stats: ICraftsmanPromiseState;
    comments: ICraftsmanPromiseState;
    bewertungen: ICraftsmanPromiseState;
}

const mapStateToProps = ({ craftsmen }) => ({ craftsmen });

const mapDispatchToProps = {
    getCraftsmanStats,
    getCraftsmanProfile,
    updateCraftsmanProfile,
    addComment,
    getComments,
    getBewertungen,
    deleteComment,
    push
};

export const withCraftsman = (...namespaces: INamespaces) => {
    return WrappedComponent => {
        @connect<WithCraftsmanStateProps, WithCraftsmanDispatchProps, WithCraftsmanProps & WithCraftsmanDispatchProps>(
            mapStateToProps,
            mapDispatchToProps
        )
        @(withTranslation(["internals", "messages", "pages/craftsman-profile", "bewertungen"]) as $TSWorkaround)
        class WithCraftsman extends Component<WithCraftsmanProps & WithCraftsmanDispatchProps, WithCraftsmanState> {
            state = {
                formMessage: {},
                formMessageRelation: undefined
            };

            static displayName: string;

            params = {
                bewertungen: initialBewertungenParams()
            };

            componentDidMount() {
                this.fetchData();
            }

            componentDidUpdate(prevProps: WithCraftsmanProps & WithCraftsmanDispatchProps) {
                if (prevProps.craftsmanId !== this.props.craftsmanId) {
                    this.fetchData();
                }
            }

            setErrorMessage(error: RestErrorResponse, actionName: string, formMessageRelation: string) {
                const { response }: { response: RestSuccessResponse } = error;

                if (error.name === "AbortError" || !response) {
                    return null;
                }

                const messages: string = this.props.t("messages:actions." + actionName, { returnObjects: true });
                this.setState({
                    formMessage: getGenericMessage(response, typeof messages === "string" ? {} : messages),
                    formMessageRelation
                });
            }

            @autobind
            resetFormMessage() {
                this.setState({
                    formMessage: undefined,
                    formMessageRelation: undefined
                });
            }

            @autobind
            async fetchData() {
                this.resetFormMessage();

                try {
                    const { craftsmenInfo, craftsmenStats } = this.props.craftsmen;

                    const pendingFetches = Array<ICraftsmenAction>();

                    if (
                        namespaces.includes(WithCraftsmanNamespaces.Profile) &&
                        !craftsmenInfo.fetchingItems.includes(this.props.craftsmanId) &&
                        this.props.craftsmanId
                    ) {
                        pendingFetches.push(this.props.getCraftsmanProfile(this.props.craftsmanId));
                    }

                    if (
                        namespaces.includes(WithCraftsmanNamespaces.Stats) &&
                        !craftsmenStats.fetchingItems.includes(this.props.craftsmanId) &&
                        this.props.craftsmanId
                    ) {
                        pendingFetches.push(this.props.getCraftsmanStats(this.props.craftsmanId));
                    }

                    if (namespaces.includes(WithCraftsmanNamespaces.Comments) && this.props.craftsmanId) {
                        pendingFetches.push(this.props.getComments(this.props.craftsmanId));
                    }

                    if (namespaces.includes(WithCraftsmanNamespaces.Bewertungen) && this.props.craftsmanId) {
                        pendingFetches.push(this.props.getBewertungen(this.props.craftsmanId, this.params.bewertungen));
                    }

                    await Promise.all(pendingFetches);
                } catch (err) {
                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "fetchData", "craftsman");
                    }
                }
            }

            @autobind
            viewCraftsman() {
                this.props.push(`/handwerker/${this.props.craftsmanId}`);
            }

            @autobind
            async updateCraftsmanProfile(data: Object, formMessageRelation: string) {
                this.resetFormMessage();

                try {
                    await this.props.updateCraftsmanProfile(this.props.craftsmanId, data);
                } catch (err) {
                    if (isRestErrorResponse(err) && !err.response.data.hasOwnProperty("errors")) {
                        this.resetFormMessage();
                        this.setErrorMessage(err, "updateCraftsmanProfile", formMessageRelation);
                    }
                    return Promise.reject(err);
                }
            }

            @autobind
            async updateCraftsmanProfileFormik(data: Object, formMessageRelation: string) {
                this.resetFormMessage();

                try {
                    await this.props.updateCraftsmanProfile(this.props.craftsmanId, data);
                } catch (err) {
                    this.resetFormMessage();

                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "updateCraftsmanProfile", formMessageRelation);
                    }

                    return Promise.reject(err);
                }
            }

            @autobind
            contactCraftsman() {
                window.location.href =
                    "mailto:" + (this.props.craftsmen.craftsmenInfo[this.props.craftsmanId] as CraftsmanFacility).email;
            }

            @autobind
            async getComments() {
                await this.props.getComments(this.props.craftsmanId);
            }

            @autobind
            async getBewertungen(params) {
                this.params.bewertungen = params;

                this.resetFormMessage();
                try {
                    await this.props.getBewertungen(this.props.craftsmanId, params);
                } catch (err) {
                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "getBewertungen", "bewertungen");
                    }
                    return Promise.reject(err);
                }
            }

            @autobind
            async addBewertung(data) {
                this.resetFormMessage();
                try {
                    await craftsmenService.addBewertung(this.props.craftsmanId, data);

                    this.viewCraftsman();
                } catch (err) {
                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "addBewertung", "addBewertung");
                    }
                    return Promise.reject(err);
                }
            }

            @autobind
            async deleteBewertung(bewertungId: string, formMessageRelation) {
                this.resetFormMessage();

                try {
                    return await craftsmenService.deleteBewertung(this.props.craftsmanId, bewertungId);
                } catch (err) {
                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "deleteBewertung", formMessageRelation || bewertungId);
                    }
                    return Promise.reject(err);
                }
            }

            @autobind
            async addComment(comment: Object) {
                this.resetFormMessage();

                try {
                    await this.props.addComment(this.props.craftsmanId, comment);
                } catch (err) {
                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "addComment", "addComment");
                    }
                    return Promise.reject(err);
                }
            }

            @autobind
            async deleteComment(commentId: string) {
                return await this.props.deleteComment(this.props.craftsmanId, commentId);
            }

            @autobind
            openCraftsmanDocument(documentId: string) {
                const win = window.open(
                    craftsmenService.getCraftsmanDocumentUri(this.props.craftsmanId, documentId, false),
                    "_blank"
                );

                if (win) {
                    win.focus();
                }
            }

            @autobind
            openCraftsmanContractDraft() {
                const win = window.open(
                    craftsmenService.getCraftsmanContractDocumentDraftUri(this.props.craftsmanId),
                    "_blank"
                );

                if (win) {
                    win.focus();
                }
            }

            @autobind
            async createCraftsmanContractDocument() {
                try {
                    const { data } = await craftsmenService.createCraftsmanContractDocument(this.props.craftsmanId);
                    this.openCraftsmanDocument(data.documentId);
                } catch (err) {
                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "createCraftsmanContractDocument", "createCraftsmanContractDocument");
                    }
                    return Promise.reject(err);
                }
            }

            @autobind
            async uploadCraftsmanDocument(formData: FormData) {
                try {
                    return await craftsmenService.uploadCraftsmanDocument(this.props.craftsmanId, formData);
                } catch (err) {
                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "uploadCraftsmanDocument", "uploadCraftsmanDocument");
                    }
                    return Promise.reject(err);
                }
            }

            @autobind
            async updateCraftsmanDocument(documentId: string, data: DocumentPatchRequest) {
                try {
                    return await craftsmenService.updateCraftsmanDocument(this.props.craftsmanId, documentId, data);
                } catch (err) {
                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "updateCraftsmanDocument", "updateCraftsmanDocument");
                    }
                    return Promise.reject(err);
                }
            }

            @autobind
            async deleteCraftsmanDocument(documentId: string) {
                try {
                    return await craftsmenService.updateCraftsmanDocument(this.props.craftsmanId, documentId, {
                        deleted: true
                    });
                } catch (err) {
                    if (isRestErrorResponse(err)) {
                        this.setErrorMessage(err, "deleteCraftsmanDocument", "deleteCraftsmanDocument");
                    }
                    return Promise.reject(err);
                }
            }

            render() {
                const { craftsmen, ...rest }: { craftsmen: ICraftsmenState } = this.props;
                const { craftsmenInfo, craftsmenStats, craftsmenComments, craftsmenBewertungen } = craftsmen;

                // Todo: Ggfs. aufräumen
                let data: ICurrentCraftsman = {
                    profile: null,
                    stats: null,
                    comments: null,
                    meta: {
                        profile: {},
                        stats: {},
                        comments: {},
                        bewertungen: {}
                    },
                    actions: {},
                    bewertungen: null
                } as ICurrentCraftsman;

                if (namespaces.includes(WithCraftsmanNamespaces.Profile)) {
                    data.profile =
                        this.props.craftsmanId && craftsmenInfo[this.props.craftsmanId]
                            ? (craftsmenInfo[this.props.craftsmanId] as CraftsmanFacility)
                            : null;
                    data.meta.profile.fetching = craftsmenInfo.isFetching;
                    data.meta.profile.failed = craftsmenInfo.failed;
                    data.meta.profile.status = craftsmenInfo.status;
                }

                if (namespaces.includes(WithCraftsmanNamespaces.Stats)) {
                    data.stats =
                        this.props.craftsmanId && craftsmenStats[this.props.craftsmanId]
                            ? (craftsmenStats[this.props.craftsmanId] as CraftsmanFacilityMetricsDTO)
                            : null;
                    data.meta.stats.fetching = craftsmenStats.isFetching;
                    data.meta.stats.failed = craftsmenStats.failed;
                    data.meta.stats.status = craftsmenStats.status;
                }

                if (namespaces.includes(WithCraftsmanNamespaces.Comments)) {
                    data.comments =
                        this.props.craftsmanId && craftsmenComments[this.props.craftsmanId]
                            ? (craftsmenComments[this.props.craftsmanId] as IComment[])
                            : null;
                    data.meta.comments.fetching = craftsmenComments.isFetching;
                    data.meta.comments.failed = craftsmenComments.failed;
                    data.meta.comments.status = craftsmenComments.status;
                }

                if (namespaces.includes(WithCraftsmanNamespaces.Actions)) {
                    data.actions = {
                        viewCraftsman: this.viewCraftsman,
                        contactCraftsman: this.contactCraftsman,
                        updateCraftsmanProfile: this.updateCraftsmanProfile,
                        updateCraftsmanProfileFormik: this.updateCraftsmanProfileFormik,

                        // Utility functions
                        resetFormMessage: this.resetFormMessage,

                        // comment functions
                        getComments: this.getComments,
                        addComment: this.addComment,
                        deleteComment: this.deleteComment,

                        // bewerten
                        getBewertungen: this.getBewertungen,
                        addBewertung: this.addBewertung,
                        deleteBewertung: this.deleteBewertung,

                        // document functions
                        openCraftsmanDocument: this.openCraftsmanDocument,
                        openCraftsmanContractDraft: this.openCraftsmanContractDraft,
                        createCraftsmanContractDocument: this.createCraftsmanContractDocument,
                        uploadCraftsmanDocument: this.uploadCraftsmanDocument,
                        updateCraftsmanDocument: this.updateCraftsmanDocument,
                        deleteCraftsmanDocument: this.deleteCraftsmanDocument
                    };
                }

                if (namespaces.includes(WithCraftsmanNamespaces.Bewertungen)) {
                    data.bewertungen =
                        this.props.craftsmanId && craftsmenBewertungen[this.props.craftsmanId]
                            ? (craftsmenBewertungen[this.props.craftsmanId] as ICraftsmenBewertungenState)
                            : null;
                    data.meta.bewertungen.fetching =
                        craftsmenBewertungen.isFetching &&
                        craftsmenBewertungen.fetchingItems.includes(this.props.craftsmanId);
                    data.meta.bewertungen.failed = craftsmenBewertungen.failed;
                    data.meta.bewertungen.status = craftsmenBewertungen.status;
                }

                return (
                    <WrappedComponent
                        {...rest}
                        currentCraftsman={data}
                        formMessage={this.state.formMessage}
                        formMessageRelation={this.state.formMessageRelation}
                        refreshCraftsman={this.fetchData}
                    />
                );
            }
        }

        WithCraftsman.displayName = `WithCraftsman(${WrappedComponent.displayName || WrappedComponent.name})`;

        return WithCraftsman;
    };
};
