import React, { PureComponent } from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import SingleInput from "./SingleInput";

export default class TextEditForm extends PureComponent {
    static propTypes = {
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                label: PropTypes.string,
                validate: PropTypes.func
            })
        ).isRequired
    };

    render() {
        const { fields, children, form, ...props } = this.props;

        return fields.map(
            (
                {
                    component: Component = SingleInput,
                    fieldComponent: FieldComponent = Field,
                    type = "text",
                    name,
                    ...field
                },
                index
            ) =>
                name ? (
                    <FieldComponent {...field} name={name} key={index} component={Component} type={type} {...props} />
                ) : (
                    <Component {...field} key={index} type={type} {...props} />
                )
        );
    }
}
