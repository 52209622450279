import React from "react";
import { getIn } from "formik";

export function FormikLayer({ formComponent: FormComponent, field, form, useSetFieldValue, onChange, ...props }) {
    const error = form.errors ? getIn(form.errors, field.name) : false;
    const hasError = !!error;

    return (
        <FormComponent
            {...props}
            {...field}
            hasError={hasError}
            onChange={
                onChange ? onChange : useSetFieldValue ? stuff => form.setFieldValue(field.name, stuff) : field.onChange
            }
        />
    );
}
