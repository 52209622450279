import React, { ReactNode } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import styles from "./Table.css";

export const Table = ({ children, renderBody = false, fixedRowHeight }) => {
    return (
        <table
            className={classNames(styles.table, {
                [styles[`fixedRowHeight${fixedRowHeight}`]]: fixedRowHeight
            })}
        >
            {renderBody ? <tbody className={styles.tbody}>{children}</tbody> : children}
        </table>
    );
};

export const TableHeader = ({ children }) => {
    return <thead className={styles.thead}>{children}</thead>;
};

export const TableBody = ({ children }) => {
    return <tbody className={styles.tbody}>{children}</tbody>;
};

export const TableRow = ({
    children,
    isHighlighted,
    isSemiHighlighted,
    isHeader,
    isFaded,
    isDraft,
    hasHover = true
}) => {
    return (
        <tr
            className={classNames(styles.row, {
                [styles.isHighlighted]: isHighlighted,
                [styles.isSemiHighlighted]: isSemiHighlighted,
                [styles.hasHover]: hasHover,
                [styles.isHeader]: isHeader,
                [styles.isDraft]: isDraft,
                [styles.isFaded]: isFaded
            })}
        >
            {children}
        </tr>
    );
};

export const TableConfirmationRow = ({ children, message, onAgree, onCancel, columnCount, actionReverseIndex = 0 }) => {
    const positionRight = (actionReverseIndex + 1) * 24 + actionReverseIndex * 12 + 12; // (itemCount(1) * iconSize(24)) + (index * spaceBetweenItems(12)) + tableCellPaddingRight(12)
    const arrowOffset = 17; // arrowSize(10) + ((iconSize(24) - arrowSize(10)) / 2)

    return (
        <tr className={classNames(styles.row, styles.isConfirmationRow)}>
            <td className={classNames(styles.td)} colSpan={columnCount}>
                <span className={styles.arrow} style={{ right: positionRight - arrowOffset }} />
                <div className={styles.confirmation}>
                    {message ? <span className={styles.confirmationMessage}>{message}</span> : children}

                    <span className={styles.confirmationActions}>
                        <div className={styles.actionItem}>
                            <Button variant={"blank"} square onClick={onAgree} icon={"IconCheck"} />
                        </div>
                        <div className={styles.actionItem}>
                            <Button variant={"blank"} square onClick={onCancel} icon={"IconClose"} />
                        </div>
                    </span>
                </div>
            </td>
        </tr>
    );
};

export const TableConfirmationBody = ({ children }) => <div className={styles.confirmationBody}>{children}</div>;

interface TableCellProps {
    children?: ReactNode;
    component?: "td" | "th";
    align?: string;
    colSpan?: number;
    hasSelection?: boolean;
    isBold?: boolean;
    isHidden?: boolean;
    actionColumn?: boolean;
}

export const TableCell: React.FC<TableCellProps> = ({
    children,
    component: Component = "td",
    align = "left",
    isHidden,
    isBold = false,
    colSpan,
    hasSelection,
    actionColumn
}) => {
    const cellClasses = classNames({
        [styles[align]]: align,
        [styles[Component]]: typeof Component === "string",
        [styles.hasSelection]: hasSelection,
        [styles.isBold]: isBold,
        [styles.isHidden]: isHidden,
        [styles.actionColumn]: actionColumn
    });

    return (
        <Component className={cellClasses} colSpan={colSpan}>
            {actionColumn
                ? React.Children.map(children, actionItem =>
                      actionItem ? <div className={styles.actionItem}>{actionItem}</div> : null
                  )
                : children}
        </Component>
    );
};

Table.propTypes = {
    children: PropTypes.any.isRequired,
    renderBody: PropTypes.bool
};

TableRow.propTypes = {
    children: PropTypes.any.isRequired,
    isHighlighted: PropTypes.bool,
    isSemiHighlighted: PropTypes.bool,
    hasHover: PropTypes.bool,
    isHeader: PropTypes.bool,
    isFaded: PropTypes.bool,
    isDraft: PropTypes.bool
};

TableConfirmationRow.propTypes = {
    children: PropTypes.any,
    message: PropTypes.string,
    onAgree: PropTypes.func,
    onCancel: PropTypes.func,
    columnCount: PropTypes.number,
    actionReverseIndex: PropTypes.number
};
