import React, { ReactElement, ReactNode, useState } from "react";
import { Media } from "react-breakpoints";
import { isPast, isToday } from "../../utils/helpers/date";
import LinkIcon from "../LinkIcon/LinkIcon";
import { Text } from "../Typo/Typo";
import { UIErinnerung } from "./Erinnerung";
import styles from "./Erinnerungen.css";
import ErinnerungenGruppe from "./ErinnerungenGruppe";

interface UIErinnerungen {
    children?: ReactNode;
    onClickNeu?: Function;
    prerenderedChildren?: UIErinnerungenListe[];
    maxEntriesVisible?: number;
    editorView?: boolean;
}

interface UIErinnerungenListe extends UIErinnerung {
    component: ReactElement;
}

const Erinnerungen: React.FC<UIErinnerungen> = ({
    children,
    onClickNeu,
    prerenderedChildren: erinnerungen,
    maxEntriesVisible = 7,
    editorView
}) => {
    const [visibleItems, setVisibleItems] = useState(maxEntriesVisible);

    const createChunksByPeriod = erinnerungen =>
        erinnerungen.reduce(
            (acc, curr) => acc[isPast(curr.datum) ? 0 : isToday(curr.datum) ? 1 : 2].push(curr) && acc,
            [[], [], []]
        );

    const newButton = (short?: boolean) =>
        onClickNeu ? (
            <LinkIcon className={styles.neuInteraktion} iconName="IconAlarmAdd" onClick={onClickNeu} type={"button"}>
                <Text variant={"bold"}>Neu{!short ? "e Erinnerung einstellen" : ""}</Text>
            </LinkIcon>
        ) : null;

    return (
        <div className={styles.liste}>
            {editorView && <div className={styles.actions}>{newButton()}</div>}

            <div className={styles.eintraege}>
                {erinnerungen
                    ? createChunksByPeriod(erinnerungen.slice(0, visibleItems)).map((chunk, chunkIndex) => (
                          <ErinnerungenGruppe key={chunkIndex} bordered={chunkIndex === 1}>
                              {chunk.map(({ component }, index) => component)}
                          </ErinnerungenGruppe>
                      ))
                    : children}
            </div>
            <div className={styles.actions}>
                {/* div needed for flexbox layout */}
                <div>
                    {erinnerungen && erinnerungen.length && onClickNeu ? (
                        <Media>
                            {({ currentBreakpoint, breakpoints }) =>
                                breakpoints[currentBreakpoint] < breakpoints.tablet ? newButton(true) : null
                            }
                        </Media>
                    ) : null}
                </div>

                {!erinnerungen || erinnerungen.length <= maxEntriesVisible ? null : (
                    <LinkIcon
                        className={styles.klapper}
                        customClass={"inlineLink"}
                        onClick={() =>
                            setVisibleItems(
                                erinnerungen.length > visibleItems ? erinnerungen.length : maxEntriesVisible
                            )
                        }
                        type={"button"}
                    >
                        {erinnerungen.length > visibleItems
                            ? `Alle ${erinnerungen.length} Erinnerungen anzeigen`
                            : "Weniger anzeigen"}
                    </LinkIcon>
                )}
            </div>
        </div>
    );
};

export default Erinnerungen;
