import React from "react";
import styles from "./FormLabel.css";

interface FormLabelProps extends React.HTMLProps<HTMLLabelElement> {
    labelHint?: string;
}

const FormLabel: React.FC<FormLabelProps> = React.memo(({ children, labelHint, ...labelProps }) => (
    <label className={styles.formLabel} {...labelProps}>
        {children}
        {labelHint ? <span className={styles.formLabelHint}> {labelHint}</span> : null}
    </label>
));

FormLabel.displayName = "Form.Label";

export default FormLabel;
