import React, { Component, ReactNode } from "react";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { IPaginationState } from "@lucify/types";
import { scrollTo } from "../../utils/helpers/ui";
import { ShortCut } from "../../utils/keymapManager";
import ContainerHeadline from "../Container/ContainerHeadline";
import Select from "../FormInput/Select";
import Icon from "../Icon/Icon";
import styles from "./Pagination.css";

interface PaginationProps {
    children?: ReactNode;
    headline?: string;
    onChange(pagination: PaginationObject): void;
    pagination: IPaginationState;
    pageSizeOptions: { label: string; value: number }[];
    pageSize: number;
    pageSizePosition?: "top" | "none";
}

interface PaginationObject {
    pageSize: number;
    page?: number;
}

export class Pagination extends Component<PaginationProps> {
    wrapper = React.createRef<HTMLDivElement>();

    state = {
        pageSize: this.props.pageSize,
        page: this.props.pagination.pageNumber
    };

    static defaultProps = {
        pageSizeOptions: [
            { label: "15", value: 15 },
            { label: "25", value: 25 },
            { label: "50", value: 50 },
            { label: "100", value: 100 }
        ],
        pageSizePosition: "top"
    };

    static propTypes = {
        children: PropTypes.any,
        headline: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        pagination: PropTypes.shape({
            totalCount: PropTypes.number,
            totalPages: PropTypes.number,
            pageNumber: PropTypes.number, // +/- 1
            firstPage: PropTypes.bool,
            lastPage: PropTypes.bool
        }).isRequired,
        pageSizeOptions: PropTypes.arrayOf(PropTypes.object),
        pageSize: PropTypes.number.isRequired
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            page: nextProps.pagination.pageNumber,
            pageSize: nextProps.pageSize
        };
    }

    @autobind
    handleChange(params) {
        this.setState(params);

        if (typeof params.page !== "number") {
            return;
        }

        this.props.onChange({
            ...this.state,
            ...params
        });
    }

    @autobind
    nextPage() {
        if (this.state.page < this.props.pagination.totalPages) {
            this.handleChange({ page: Number(this.state.page) + 1 });
            scrollTo(this.wrapper.current);
        }
    }

    @autobind
    prevPage() {
        if (this.state.page > 1) {
            this.handleChange({
                page: Math.max(1, Number(this.state.page) - 1)
            });
            scrollTo(this.wrapper.current);
        }
    }

    render() {
        const { pagination } = this.props;

        return (
            <div className={styles.wrapper} ref={this.wrapper}>
                <ShortCut name="pagination.nextPage" fn={this.nextPage} />
                <ShortCut name="pagination.prevPage" fn={this.prevPage} />

                {this.props.headline || this.props.pageSizePosition === "top" ? (
                    <div className={styles.header}>
                        {this.props.headline ? (
                            <ContainerHeadline
                                headline={this.props.headline}
                                count={pagination.totalCount}
                                standalone={false}
                            />
                        ) : (
                            <span />
                        )}

                        {this.props.pageSizePosition === "top" ? (
                            <div className={styles.pageSizeActions}>
                                <label className={styles.pageSizeActionsLabel} htmlFor="pageSize">
                                    Einträge pro Seite
                                </label>

                                <div className={styles.pageSizeActionsFormGroup}>
                                    <Select
                                        className={styles.pageSizeActionsSelect}
                                        onChange={({ value }) =>
                                            this.handleChange({
                                                pageSize: value,
                                                page: 1
                                            })
                                        }
                                        defaultValue={this.props.pageSizeOptions.find(
                                            option => option.value === this.props.pageSize
                                        )}
                                        options={this.props.pageSizeOptions}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {/* child used to be ResultsView */}
                {this.props.children}

                {pagination.totalCount ? (
                    <div className={styles.footer}>
                        <div className={styles.pageNumberLabel}>
                            Seite {pagination.pageNumber} von {pagination.totalPages}
                        </div>

                        {/* pagination, prev, page, next */}
                        <button
                            disabled={this.state.page === 1}
                            className={styles.pageNumberButtonPrev}
                            type={"button"}
                            onClick={this.prevPage}
                        >
                            <Icon className={styles.pageNumberButtonIcon} name={"IconArrowLeft"} />
                        </button>

                        <input
                            type="number"
                            className={styles.pageNumber}
                            value={this.state.page}
                            onChange={event =>
                                this.handleChange({
                                    page:
                                        event.currentTarget.value === ""
                                            ? event.currentTarget.value
                                            : Math.min(pagination.totalPages, Number(event.currentTarget.value))
                                })
                            }
                        />
                        <button
                            disabled={this.state.page === pagination.totalPages}
                            className={styles.pageNumberButtonNext}
                            type={"button"}
                            onClick={this.nextPage}
                        >
                            <Icon className={styles.pageNumberButtonIcon} name={"IconArrowRight"} />
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }
}
