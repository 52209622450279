import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./StatsItem.css";
import * as statsItemTypes from "./StatsItemTypes";

export default class StatsItem extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf(["StatCount", "StatFloatRating", "StatTextRating", "StatDate"]),
        value: PropTypes.any,
        label: PropTypes.string
    };

    static defaultProps = {
        type: "StatCount"
    };

    render() {
        const { type, className, size, variant, label, value } = this.props;
        const StatTypeComponent = statsItemTypes[type] || statsItemTypes["StatCount"];

        return (
            <div
                className={classNames(styles.statsItem, {
                    [className]: className,
                    [styles[size || "default"]]: size,
                    [styles[variant]]: variant
                })}
            >
                <div className={classNames(styles.statsType)}>
                    <StatTypeComponent value={value} />
                </div>

                {label ? <div className={styles.statsLabel}>{label}</div> : null}
            </div>
        );
    }
}
